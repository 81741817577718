import Sprite from '../../assets/sprite.svg';
/**
 *
 * @param {{name: string, className?: string, stroke: string, fill: string}} props
 * @returns
 */
export default function Icon({ name = '', className = '', stroke = '', fill = '' }) {
  return (
    <span className={`icon ${className}`}>
      <svg stroke={stroke} fill={fill}>
        <use href={`${Sprite}#icon-${name}`} />
      </svg>
    </span>
  );
}
