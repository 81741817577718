/* eslint-disable camelcase */
import ImageData from './Image';

class ExploreTheCampusData {
  constructor({ attributes: { explore_the_campus } }) {
    this.exploreTheCampus = explore_the_campus?.data?.map((item) => ({
      id: item.id,
      image: new ImageData(item)
    }));
  }
}

export default ExploreTheCampusData;
