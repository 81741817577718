import { ENDPOINTS } from './endpoints';
import { fetchData } from './network';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';

export function getMaximizingLeadership() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getHomepage.path}`);

  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate[section_2_side_image]', '*');

  return fetchData(url, {
    method: ENDPOINTS.getHomepage.method
  }).then((response) => response);
}
