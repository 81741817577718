import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import LocaleContext from '../contexts/LocaleContext';
import { getApplicationDetails } from '../js/api/application-detail';
import { formatDateShort } from '../js/utils/format-date';

import MainTitle from './common/MainTitle';
import Icon from './common/Icon';
import StudentsFillingFormJpg from '../assets/images/home/Home-Apply-O2.jpeg';
import StudentsFillingFormWebp from '../assets/images/home/Home-Apply-O2.webp';

export default function ApplicationDetails() {
  const { t } = useTranslation(['HomePage']);
  const { locale } = useContext(LocaleContext);
  const [applicationDetails, setApplicationDetails] = useState(null);

  /**
   * Handle fetching application details data.
   */
  useEffect(() => {
    getApplicationDetails()
      .then((res) => {
        setApplicationDetails(res?.data?.attributes);
      })
      .catch((e) => {
        toast.error(t('contactInfoError'));
        throw e;
      });
  }, [locale]);

  return (
    <section className="application-details" id="application-details">
      <MainTitle type="dark" className="text--2xl container hidden--desktop">
        <span>{applicationDetails?.section_title_line_1}</span>
        <span className="decorated-line decorated-line--dark decorated-line--left">
          {applicationDetails?.section_title_line_2}
        </span>
      </MainTitle>

      <div className="application-details__container container">
        <div className="application-details__content">
          <header className="application-details__header">
            <MainTitle type="dark" className="text--2xl hidden--mobile">
              <span>{applicationDetails?.section_title_line_1}</span>
              <span className="decorated-line decorated-line--dark decorated-line--left">
                {applicationDetails?.section_title_line_2}
              </span>
            </MainTitle>
          </header>
          <div className="application-details__description">
            <p className="description">{applicationDetails?.description}</p>

            <div className="application-details__timeline">
              <div className="timeline applications-open">
                <div className="timeline__content">
                  <h3>{t('applicationsOpen')}</h3>
                  <p>
                    {formatDateShort(
                      applicationDetails?.application_open_date,
                      locale === 'en' ? 'en-US' : 'ar-EG'
                    )}
                  </p>
                </div>
                <div className="timeline__media">
                  <Icon name="application" />
                </div>
              </div>

              <div className="timeline cohort-starts">
                <div className="timeline__content">
                  <h3>{t('academicYearStarts')}</h3>
                  <p>
                    {formatDateShort(
                      applicationDetails?.first_cohort_starts_date,
                      locale === 'en' ? 'en-US' : 'ar-EG'
                    )}
                  </p>
                </div>
                <div className="timeline__media">
                  <Icon name="calendar-outline" stroke="#941730" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="application-details__media">
          <picture>
            <source srcSet={StudentsFillingFormWebp} type="image/webp" />
            <img src={StudentsFillingFormJpg} alt="students-filling-form" />
          </picture>
          <Icon name="success" className="decorative-icon decorative-icon--dark success-icon" />
          <Icon
            name="student-certificate"
            className="decorative-icon decorative-icon--light student-certificate-icon"
          />
        </div>
      </div>
    </section>
  );
}
