import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import TextInput from './Inputs/TextInput';
import { getDonatationToken } from '../../js/api/donate-foundations';
import Icon from './Icon';

export default function Donate({ closeModal }) {
  const { t } = useTranslation(['DonatePage']);
  const [iframeData, setIframeData] = useState(null);

  const DonateFormSchema = Yup.object().shape({
    amount: Yup.string().required(t('donationAmountRequired'))
  });

  const onConfirm = async (data) => {
    await getDonatationToken({
      ...data
    })
      .then((response) => {
        setIframeData(response);
      })
      .catch((error) => {
        closeModal();
        toast.error(t('genericError'));
        throw error;
      });
  };

  return ReactDOM.createPortal(
    <div className="donate-modal">
      <div className={`donate-modal__container ${iframeData && 'iframeContainer'}`}>
        <div className="donate-modal__header">
          <h2>{t('pageTitle')}</h2>
          <button
            type="button"
            className="button close-button"
            title="close popup"
            onClick={() => closeModal()}>
            <Icon name="close" />
          </button>
        </div>
        {iframeData?.payment_link ? (
          <iframe
            id="iframe-payment"
            srcDoc={`
              <html xmlns="http://www.w3.org/1999/xhtml">
              <head>
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta charset="utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0">
                <script src="scripts/jquery.min.js"></script>
                <script src="scripts/bootstrap.min.js"></script>
                <script type="text/javascript">
                  $(function () {
                    $("#btnContinue").click(function () {
                      $("#redirectToken").val($("#inputtoken").val());
                      });
                    });
                </script>
              </head>
              <body>
                <input type="text" id="inputtoken" value="" />
                <br />
                <form method="post" action="https://accept.authorize.net/payment/payment" id="formAuthorizeNetTestPage" name="formAuthorizeNetTestPage">
                  <input type="hidden" id="redirectToken" name="token" value="${iframeData?.token}" />
                  Redirect-Continue to Authorize.net to Payment Page
                  <button id="btnContinue" onclick="">Continue to next page</button>
                </form>
              </body>
              </html>
            `}
          />
        ) : (
          <Fragment>
            <Formik
              initialValues={{
                amount: ''
              }}
              validationSchema={DonateFormSchema}
              onSubmit={async (values, { errors, resetForm }) => {
                await onConfirm(values);
                if (!errors) resetForm();
              }}>
              {({ errors, touched }) => (
                <Form className="donate-modal__amount__form">
                  <TextInput
                    label={t('donationAmount')}
                    name="amount"
                    type="number"
                    id="amount"
                    icon={null}
                    placeholder="$10,000"
                    hasError={touched.amount && errors.amount}
                    iconClassName="amount__icon"
                    theme="dark">
                    {errors.amount && touched.amount ? (
                      <span className="error-msg">{errors.amount}</span>
                    ) : null}
                  </TextInput>

                  <button type="submit" className="button submit-button">
                    {t('donateConfirm')}
                  </button>
                </Form>
              )}
            </Formik>
          </Fragment>
        )}
      </div>
    </div>,
    document.getElementById('donate-modal')
  );
}
