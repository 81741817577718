import { useTranslation } from 'react-i18next';

import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';
import StudentGirlJpg from '../../assets/images/admissions/annual-fees-media.jpg';
import StudentGirlWebp from '../../assets/images/admissions/annual-fees-media.webp';

export default function AnnualFees({ fees }) {
  const { t } = useTranslation(['AdmissionsPage']);

  return (
    <section className="annual-fees" id="annual-fees">
      <MainTitle type="dark" className="text--2xl container hidden--desktop">
        <span>{t('annual')}</span>
        <span className="decorated-line decorated-line--dark decorated-line--left">
          {t('studentFees')}
        </span>
      </MainTitle>

      <div className="annual-fees__container container">
        <div className="annual-fees__content">
          <header className="annual-fees__header">
            <MainTitle type="dark" className="text--2xl hidden--mobile">
              <span>{t('annual')}</span>
              <span className="decorated-line decorated-line--dark decorated-line--left">
                {t('studentFees')}
              </span>
            </MainTitle>
          </header>
          <div className="annual-fees__description">
            <p className="description">{t('annualFeesDescription')}</p>

            <div className="annual-fees__details">
              <div className="fees">
                <h3>{t('feesInclude')}</h3>
                <p>{fees?.includes}</p>
              </div>

              <div className="fees">
                <h3>{t('feesExclude')}</h3>
                <p>{fees?.excludes}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="annual-fees__media">
          <picture>
            <source srcSet={StudentGirlWebp} type="image/webp" />
            <img src={StudentGirlJpg} alt="student holding her notebook and smiling" />
          </picture>
          <Icon name="list" className="decorative-icon decorative-icon--dark list-icon" />
          <Icon
            name="bank-cards"
            className="decorative-icon decorative-icon--light bank-cards-icon"
          />
        </div>
      </div>
      <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />
    </section>
  );
}
