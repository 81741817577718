import { ENDPOINTS } from './endpoints';
import { fetchData } from './network';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';

export function getHeader() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getHeader.path}`);
  url.searchParams.append('type', 'TREE');
  bindLocaleIntoHeaders(url);

  return fetchData(url, {
    method: ENDPOINTS.getHeader.method
  }).then((response) => response);
}

export function getFooter() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getFooter.path}`);
  url.searchParams.append('type', 'TREE');
  bindLocaleIntoHeaders(url);

  return fetchData(url, {
    method: ENDPOINTS.getFooter.method
  }).then((response) => response);
}
