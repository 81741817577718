import { useTranslation } from 'react-i18next';
import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';
import ImageLink from '../../js/utils/ImageLink';

export default function HeroImage({ title1, title2, title3, subtitle, image }) {
  const { t } = useTranslation(['HomePage']);
  return (
    <section className="hero-image">
      <div className="hero-image__container">
        <div className="overlay" />
        <img src={ImageLink(image[0]?.url)} alt={image.alt} />
        <div className="hero-image__container-header">
          <MainTitle type="light" className="text--2xl desktop">
            <span>
              {title1}
              <span className="subtitle">{subtitle}</span>
            </span>
            <span className="decorated-line decorated-line--light decorated-line--left">
              {title2}
            </span>
            <span>{title3}</span>
          </MainTitle>

          <MainTitle type="light" className="text--lg mobile">
            <span>{title1}</span>
            <span className="decorated-line decorated-line--light decorated-line--left">
              {title2}
            </span>
            <span>{title3}</span>
            <span className="subtitle">{subtitle}</span>
          </MainTitle>
        </div>
      </div>
      <div className="scroll">
        <div className="scroll-icon-container">
          <Icon name="scroll-down" />
        </div>
        <p>{t('scrollDown')}</p>
      </div>
    </section>
  );
}
