import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import LocaleContext from '../../contexts/LocaleContext';

import { getDonate } from '../../js/api/donate-foundations';
import DonateFoundationsData from '../../models/DonateFoundationsData';
import ImageLink from '../../js/utils/ImageLink';

// import Icon from './Icon';

export default function DonorsFoundations({ children }) {
  const { t } = useTranslation(['DonatePage']);
  const { locale } = useContext(LocaleContext);
  const [foundationsData, setFoundationsData] = useState(null);

  useEffect(() => {
    getDonate()
      .then((res) => {
        setFoundationsData(res?.data?.map((item) => new DonateFoundationsData(item)));
      })
      .catch((e) => {
        toast.error(t('donationsError'));
        throw e;
      });
  }, [locale]);
  return (
    <section className="donors-foundations container">
      <div className="donors-foundations__content">{children}</div>

      <div className="foundations-container">
        {foundationsData?.map((foundation) => (
          <div className="foundation" key={foundation?.id}>
            <div className="foundation__info">
              <h3 className="foundation__title">{foundation?.name}</h3>
              <p className="foundation__description">{foundation?.description}</p>
              {/* TODO: Enable this when client requests */}

              {/* <a
                href={foundation?.url}
                className="foundation__link"
                target="_blank"
                rel="noreferrer">
                <Icon name="open-dark" />
                {t('donateNow')}
              </a> */}
            </div>

            <div className="foundation__media">
              <img src={ImageLink(foundation?.image.url)} alt={foundation?.image.alt} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
