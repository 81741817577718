import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import LocaleContext from '../../contexts/LocaleContext';

import AppButton from '../common/AppButton';
import MainTitle from '../common/MainTitle';
import SliderNavigation from '../common/SliderNavigation';
import Slide from './Slide';

export default function CollegePrepSlider({ slides }) {
  const [allowPrev, setAllowPrev] = useState(true);
  const [allowNext, setAllowNext] = useState(true);
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['HomePage']);

  /**
   * Updates the slider status on init and on change.
   * @param {SwiperProps} swiper
   */
  function updateSliderStatus(swiper) {
    setAllowNext(!swiper.isEnd);
    setAllowPrev(!swiper.isBeginning);
  }

  const SwiperSlidesJSX = slides?.map((slide) => (
    <SwiperSlide key={`college-pre__slide-${slide?.id}`}>
      <Slide allowPrev={allowPrev} allowNext={allowNext} text={slide?.text} image={slide?.image} />
    </SwiperSlide>
  ));

  return (
    <div className="college-prep">
      <div className="college-prep__slider-wrapper">
        <header className="college-prep__header">
          <MainTitle type="dark" className="text--2xl">
            <span>{t('collegePrep')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('curriculumWith')}
            </span>
            <span>{t('roomForExploration')}</span>
          </MainTitle>

          <p className="main-description">{t('holisticProgram')}</p>
        </header>

        <Swiper
          className="college-prep__swiper"
          dir={locale === 'en' ? 'ltr' : 'rtl'}
          slidesPerView={1}
          breakpoints={{
            320: { slidesPerView: 1.3, spaceBetween: 26 },
            1280: { slidesPerView: 1.4, spaceBetween: 80 },
            1920: { slidesPerView: 1.6 }
          }}
          centeredSlides
          loop
          onSlideChange={(swiper) => updateSliderStatus(swiper)}
          onSwiper={(swiper) => updateSliderStatus(swiper)}
          modules={[Navigation]}>
          {SwiperSlidesJSX}

          <SliderNavigation
            allowPrev={allowPrev}
            allowNext={allowNext}
            className="college-prep__nav-buttons"
          />
        </Swiper>

        <AppButton tag="a" linkPath={`/${locale}/academics`}>
          {t('academicStructure')}
        </AppButton>
      </div>
    </div>
  );
}
