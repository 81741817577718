import ImageLink from '../../../js/utils/ImageLink';

export default function Slide({ index, image, title, description }) {
  return (
    <div className="housing-and-accommodation__slide" key={index}>
      <div className="slide__content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>

      <div className="slide__media">
        <img src={ImageLink(image.url)} alt={image.alt} />
        <div className="backdrop" />
      </div>
    </div>
  );
}
