/**
 * @author Heba ElGalad
 * @date 2022-11-8
 * @description implementation of network calls.
 * @filename network.js
 */

/**
 * handle various types of errors from network request based on response status.
 */
export function handleResponseBasedOnStatus(response) {
  let promise;
  switch (response.status) {
    case 200:
    case 201:
      promise = response.json();
      break;
    case 204:
      promise = Promise.resolve();
      break;
    case 409:
      // trying to create a resource that some of it's dependencies aren't available.
      promise = response.json().then((data) => Promise.reject(data));
      break;
    case 422:
      // eslint-disable-next-line max-len
      promise = response
        .json()
        .then((data) => Promise.reject(data && (data.errors || data.message)));
      break;
    case 401:
      promise = response
        .json()
        .then((data) => Promise.reject(data?.data ? data?.data : data?.message));
      break;
    case 400:
      promise = response.json().then((data) => Promise.reject(data.data));
      break;
    default:
      promise = response.json().then((data) => Promise.reject(data));
  }
  return promise.catch((error) => Promise.reject(error));
}

/**
 * functions responsible for executing network requests.
 */
export function fetchData(url, init) {
  const headers = {
    'content-type': 'application/json',
    accept: 'application/json'
  };

  headers.Authorization = `Bearer ${process.env.BEARER_TOKEN}`;
  headers['Accept-Language'] = `${localStorage.getItem('locale')}`;

  return fetch(url, {
    ...init,
    headers
  })
    .then((response) => handleResponseBasedOnStatus(response))
    .catch((error) => Promise.reject(error));
}
