/* eslint-disable import/prefer-default-export */
import { ENDPOINTS } from './endpoints';
import { fetchData } from './network';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';

export function getCampusPage() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getCampusDetails.path}`);

  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate[campus_and_community][populate]', '*');
  url.searchParams.append('populate[residential_experience][populate]', '*');
  url.searchParams.append('populate[co_curricular_programs][populate]', '*');
  url.searchParams.append('populate[amenity_slide][populate]', '*');
  url.searchParams.append('populate[housing_and_accommodation][populate]', '*');

  // TODO: Hide it as per client request. Enable it later.
  // url.searchParams.append('populate[student_dormitories_and_dining_hall][populate]', '*');

  return fetchData(url, {
    method: ENDPOINTS.getCampusDetails.method
  }).then((response) => response);
}
