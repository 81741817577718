import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';
import Scholarship from '../../assets/images/home/Home - Scholarship.jpg';
import ScholarshipWebp from '../../assets/images/home/Home-Scholarship.webp';
// import AppButton from '../common/AppButton';

export default function ScholarshipProgram() {
  const { t, i18n } = useTranslation(['HomePage']);
  const [isArabic, setIsArabic] = useState(false);

  useEffect(() => {
    if (i18n.language === 'ar') {
      setIsArabic(true);
    } else {
      setIsArabic(false);
    }
  }, [isArabic]);

  return (
    <section className="scholarship">
      <div className="scholarship__header">
        <MainTitle type="dark" className="text--2xl">
          <span>{t('scholarshipTitle1')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('scholarshipTitle2')}
          </span>
        </MainTitle>
      </div>
      <div className="scholarship__container">
        <div className="scholarship__container-media">
          <picture>
            <source srcSet={ScholarshipWebp} type="image/webp" />
            <img src={Scholarship} alt="Student coloring" />
          </picture>
        </div>

        <div className="scholarship__container-content">
          <div className="text">
            <p>{t('scholarshipDesc1')}</p>
            <p>{t('scholarshipDesc2')}</p>
          </div>

          {/* TODO: Enable this when the page is created */}
          {/* <AppButton>{t('scholarshipButton')}</AppButton> */}
        </div>
      </div>
      <Icon name={`spiral-arrow${isArabic ? '-down' : ''}`} className="spiral-arrow-icon" />
    </section>
  );
}
