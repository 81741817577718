import { useState, useEffect } from 'react';

import AppModal from '../../common/AppModal';
import MainTitle from '../../common/MainTitle';
import ImageData from '../../../models/Image';
import ImageLink from '../../../js/utils/ImageLink';

export default function CourseDetails({ course, closeModal }) {
  const [hasLongTitle, seHasLongTitle] = useState(null);

  useEffect(() => {
    if (course?.title?.length > 20) {
      seHasLongTitle(true);
    } else {
      seHasLongTitle(false);
    }
  }, [course.title]);

  function resolveImageData(image) {
    return new ImageData(image);
  }

  return (
    <AppModal
      close={(e) => closeModal(e)}
      className={`course-details__modal ${hasLongTitle ? 'custom-container' : ''}`}>
      <MainTitle type="dark" className="text--2xl">
        <span className="decorated-line decorated-line--dark decorated-line--left">
          {course?.title}
        </span>
      </MainTitle>
      <div className="course-details__content">
        <div className="course-details__description">
          <p>{course?.description}</p>
          {!!course?.notes && <p className="notes">{course?.notes}</p>}
        </div>

        <div className="course-details__media">
          <img
            src={ImageLink(resolveImageData(course?.image.data).url)}
            alt={resolveImageData(course?.image.data).alt}
          />
        </div>
      </div>
    </AppModal>
  );
}
