/* eslint-disable camelcase */
import ImageData from './Image';

class MaximizingLeadershipData {
  constructor({
    attributes: {
      section_2_side_image,
      section_2_text_line_1,
      section_2_text_line_2,
      section_2_text_line_3,
      section_2_text_paragraph_1,
      section_2_text_paragraph_2
    }
  }) {
    this.maximizingLeadership = {
      title1: section_2_text_line_1,
      title2: section_2_text_line_2,
      title3: section_2_text_line_3,
      image: new ImageData(section_2_side_image?.data),
      text1: section_2_text_paragraph_1,
      text2: section_2_text_paragraph_2
    };
  }
}

export default MaximizingLeadershipData;
