import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';
import Fanar from '../../assets/images/home/Home-Fanar.jpg';
import FanarWebp from '../../assets/images/home/Home-Fanar.webp';
import FanarLogo from '../../assets/images/home/FanarLogo.png';

export default function Experiences() {
  const { t, i18n } = useTranslation(['HomePage']);
  const [isArabic, setIsArabic] = useState(false);

  useEffect(() => {
    if (i18n.language === 'ar') {
      setIsArabic(true);
    } else {
      setIsArabic(false);
    }
  }, [isArabic]);

  return (
    <section className="experiences">
      <div className="experiences__header container mobile">
        <MainTitle type="dark" className="text--2xl">
          <span>{t('experiencesTitle1')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('experiencesTitle2')}
          </span>
        </MainTitle>
      </div>

      <div className="experiences__media">
        <Icon name={`spiral-arrow${isArabic ? '-down' : ''}`} className="spiral-arrow-icon" />
        <picture>
          <source srcSet={FanarWebp} type="image/webp" />
          <img src={Fanar} alt={t('experiencesDesc')} />
        </picture>
      </div>

      <div className="experiences__content container">
        <div className="experiences__header desktop">
          <MainTitle type="dark" className="text--2xl">
            <span>{t('experiencesTitle1')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('experiencesTitle2')}
            </span>
          </MainTitle>
        </div>

        <div className="content-desc">
          <img className="desktop" src={FanarLogo} alt={t('experiencesDesc')} />
          <p>{t('experiencesDesc')}</p>
        </div>
        <a className="desktop" href="https://www.fanarprograms.org/">
          <Icon name="open-dark" />
          {t('experiencesLink')}
        </a>
        <div className="mobile-logo-link mobile">
          <img src={FanarLogo} alt={t('experiencesDesc')} />
          <a href="https://fanarprograms.org/">
            <Icon name="open-dark" />
            {t('experiencesLink')}
          </a>
        </div>
      </div>
    </section>
  );
}
