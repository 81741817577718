import { ENDPOINTS } from './endpoints';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';
import { fetchData } from './network';

export function getCoursesCatalog() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getCourses.path}`);
  url.searchParams.append('populate', '*');
  bindLocaleIntoHeaders(url);

  return fetchData(url, {
    method: ENDPOINTS.getCourses.method
  }).then((response) => response);
}
