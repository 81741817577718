/* eslint-disable camelcase */
class OpportunitiesData {
  /**
   * @param {{
   *   id: string,
   *   attributes: {
   *    team_name: string,
   *    Description: string,
   *    Title: string,
   *   }
   * }}
   */
  constructor(data) {
    if (!data?.attributes) {
      return;
    }

    const {
      id,
      attributes: { team_name, Description, Title }
    } = data;

    this.id = id;
    this.teamName = team_name;
    this.description = Description;
    this.title = Title;
  }
}

export default OpportunitiesData;
