import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import HeroSection from '../components/Admissions/HeroSection';
import ApplicationTimeline from '../components/Admissions/ApplicationTimeline';
import ApplicationSteps from '../components/Admissions/ApplicationSteps';
import AnnualFees from '../components/Admissions/AnnualFees';
import SupportAndInquiries from '../components/Admissions/SupportAndInquiries';
import ApplicationDetails from '../components/ApplicationDetails';
import MetaTags from '../components/common/MetaTags';
import LocaleContext from '../contexts/LocaleContext';
import { getAdmissionsPage } from '../js/api/admission-page';
import AdmissionsPageData from '../models/AdmissionsPageData';
// import PaymentInstructions from '../components/Admissions/PaymentInstructions';

export default function AdmissionsPage() {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['AdmissionsPage']);

  const [admissionsPageData, setAdmissionsPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    getAdmissionsPage()
      .then((res) => {
        setAdmissionsPageData(new AdmissionsPageData(res.data));
      })
      .catch((e) => {
        toast.error('An error occurred, try again later');
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [locale]);

  return (
    <main className="admissions-page">
      {!isLoading && admissionsPageData ? (
        <>
          <MetaTags title={t('pageTitle')} seo={admissionsPageData?.metaTags} />
          <HeroSection />
          <ApplicationTimeline data={admissionsPageData?.applicationTimeline} />
          <ApplicationSteps
            rounds={admissionsPageData?.applyRounds}
            steps={admissionsPageData?.applySteps}
          />
          <AnnualFees fees={admissionsPageData?.annualFees} />
          {/* TODO: ENABLE THE SECTION AFTER THE CLIENT APPROVE ITS */}
          {/* <PaymentInstructions /> */}
          <SupportAndInquiries />
          <ApplicationDetails />
        </>
      ) : null}
    </main>
  );
}
