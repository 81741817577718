import { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ArticleCard from '../Articles/ArticleCard';
import LocaleContext from '../../contexts/LocaleContext';
import { getPaginatedArticles } from '../../js/api/articles';
import ArticlesData from '../../models/ArticlesData';
import Icon from '../common/Icon';

export default function ArticleList() {
  const { t } = useTranslation(['NewsPage']);
  const { locale } = useContext(LocaleContext);
  const [newsData, setNewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [articleTotal, setTotal] = useState(0);

  function formatArticles(data) {
    return data.map((item) => new ArticlesData(item));
  }

  function getNews() {
    getPaginatedArticles(pageIndex)
      .then((res) => {
        setTotal(res.meta.pagination.total);
        const tempResData = formatArticles(res.data);
        if (newsData.length > 0 && !isLoading) {
          const tempData = [...newsData, ...tempResData];
          setNewsData(tempData);
        } else {
          setNewsData(tempResData);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(t('articlesError'));
        throw e;
      });
  }

  useEffect(() => {
    getNews();
  }, [locale, pageIndex]);

  function handleButton() {
    setPageIndex(pageIndex + 1);
    getNews();
  }

  return (
    <div className="article-list">
      {!isLoading && newsData && (
        <>
          <div className="article-list__container container">
            {newsData?.map((item) => (
              <ArticleCard key={item.title} {...item} />
            ))}
          </div>

          {newsData.length > articleTotal && (
            <button
              type="button"
              className="button load-more"
              onClick={() => {
                handleButton();
              }}>
              <Icon name="article" />
              {t('loadMore')}
            </button>
          )}
        </>
      )}
    </div>
  );
}
