import { useTranslation } from 'react-i18next';
import Icon from '../common/Icon';

export default function LeadershipSkills() {
  const { t } = useTranslation(['HomePage']);

  return (
    <div className="leadership-skills container">
      <div className="skill exceptional">
        <div className="skill__content">
          <h3>{t('exceptionalTitle')}</h3>
          <p>{t('exceptionalDescription')}</p>
        </div>
        <div className="skill__media">
          <Icon name="holding-cup" />
        </div>
      </div>

      <div className="skill diverse-backgrounds">
        <div className="skill__content">
          <h3>{t('diverseBackgroundsTitle')}</h3>
          <p>{t('diverseBackgroundsDescription')}</p>
        </div>
        <div className="skill__media">
          <Icon name="globe" />
        </div>
      </div>

      <div className="skill leadership-skill">
        <div className="skill__content">
          <h3>{t('leadershipTitle')}</h3>
          <p>{t('leadershipDescription')}</p>
        </div>
        <div className="skill__media">
          <Icon name="leadership" />
        </div>
      </div>
    </div>
  );
}
