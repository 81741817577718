/* eslint-disable import/prefer-default-export */
import { ENDPOINTS } from './endpoints';
import { fetchData } from './network';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';

export function getThankYouPage() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getThankYouPage.path}`);

  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate[section_image_left][populate]', '*');
  url.searchParams.append('populate[section_image_right][populate]', '*');

  return fetchData(url, {
    method: ENDPOINTS.getThankYouPage.method
  }).then((response) => response);
}
