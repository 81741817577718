import ImageData from '../../../models/Image';
import ImageLink from '../../../js/utils/ImageLink';
import CourseDetails from './CourseDetails';

export default function CourseCard({ course, index, openModal, modalOpenIndex, closeModal }) {
  function resolveImageData(image) {
    return new ImageData(image);
  }

  return (
    <div className="course-card" role="button" tabIndex={0} onClick={(e) => openModal(index, e)}>
      <h3 className="course-card__title">
        <button type="button" className="button">
          {course?.title}
        </button>
      </h3>

      <div className="course-card__media">
        <img
          src={ImageLink(resolveImageData(course?.image.data).url)}
          alt={resolveImageData(course?.image.data).alt}
        />
      </div>

      {modalOpenIndex === index && (
        <CourseDetails course={course} closeModal={(e) => closeModal(e)} />
      )}
    </div>
  );
}
