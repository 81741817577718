{
  "manaraSchool": "مدرسة منارة",
  "pageTitle": "الصفحة الرئيسية",
  "menu": "القائمة",
  "changeLang": "تغيير اللغة",
  "applyNow": "قدم طلبك الآن",
  "getInTouch": "تواصل معنا",
  "maximizingLeadership": "تعزيز إمكانات",
  "potential": "القيادة",
  "developOur": "لتطوير قادة",
  "futureLeaders": "المستقبل في مجتمعنا",
  "leadershipContent1": "ستوفر مدرسة منارة للطلاب المتفوقين تعليماً على مستوى عالمي يعتمد على إطار من الأخلاق والأكاديميين والتنوع. نتوقع أن",
  "leadershipContent2": "يصبح طلابنا القادة المتحمسين المطلوبين لتشكيل عالم أكثر ازدهارًا وإنصافًا.",
  "helpingStudents": "تمكين الطلاب الاستثنائيين",
  "reachTheirFull": "من الوصول",
  "leadershipPotential": "إلى إمكاناتهم بالكامل",
  "helpingStudentsContent": "مدرسة منارة هي مدرسة مختلطة للطلاب المتميزين من الصف ٨ حتى الصف ١٢. إنها مركز للطلاب الموهوبين، والمهرة، و المتحمسين الذين يتشاركون في نفس الرغبة لتحويل أحلامهم إلى حقيقة. يجمع برنامجنا الأكاديمي والسكني ذو المستوى العالمي، والذي تم تصميمه وتقديمه من قبل فريق من الخبراء، طلابًا استثنائيين من خلفيات متنوعة، وبدعم من مؤسستين غير ربحيتين، تقدم فرصة فريدة للطلاب الذين يشتركون في نفس الرؤى للنمو معًا، وتحقيق إمكانياتهم القيادية بالكامل.",
  "ourVision": "المزيد عن رؤية المدرسة",
  "exceptionalTitle": "استثنائي",
  "exceptionalDescription": "طلاب متفوقين أكاديمياً، ويظهرون إمكانيات قيادية.",
  "diverseBackgroundsTitle": "خلفيات مختلفة",
  "diverseBackgroundsDescription": "طلاب من خلفيات متنوعة من جميع أنحاء مصر والعالم.",
  "leadershipTitle": "القيادة",
  "leadershipDescription": "تنمية الشغف، وإيجاد الهدف، واكتساب المهارات اللازمة لإحداث تأثيرات إيجابية.",
  "experiencesTitle1": "تجارب",
  "experiencesTitle2": "تغير حياة الطلاب",
  "experiencesDesc": " تتيح برامج فنار للطلاب الحصول على خبرات تعليمية وثقافية غير مسبوقة في مصر ستثري حياتهم.",
  "experiencesLink": "تعرف على برامج فنار",
  "donationsTitle1": "دعم",
  "donationsTitle2": "قادة المستقبل ",
  "donationsDesc1": "نوفر للهيئات والشخصيات المانحة فرصة تغيير حياة الأطفال الذين يُظهرون إمكانات أكاديمية وقيادية كبيرة من خلال منحهم فرصة الحصول على تعليم ذو مستوى عالمي.",
  "donationsDesc2": " الجهات المانحة للمنظمتين غير الربحيتين اللتين تدعمان منارة يمكنهم المساعدة في تحقيق تكافؤ الفرص للأطفال الأقل حظًا في مصر من خلال توفير فرصة الالتحاق بالبرنامج التحضيري للجامعة الذي سيؤهل الطلبة للنجاح في الدراسة الجامعية وما بعدها.",
  "donateNow": "تبرع الآن",
  "scholarshipTitle1": "برنامج قوي يأخذ ",
  "scholarshipTitle2": "الاحتياجات المالية بالاعتبار",
  "scholarshipDesc1": "نؤمن في منارة إيمانًا راسخًا بأن الإمكانات المتميزة لا يجب أن يتم تجاهلها، ولهذا السبب نلتزم بتوفير الفرص التعليمية ذات المستوى العالمي للشباب الاستثنائي من جميع الخلفيات. تم تصميم برنامج المساعدات المالية الخاص بنا لضمان عدم تخلف أي طالب موهوب عن الركب",
  "scholarshipDesc2": "بسبب العوائق المالية. سنقوم بنشر تفاصيل حول كيفية التقدم بطلب للحصول على المساعدة المالية إلى جانب معلومات القبول، لضمان أن لدى كل طالب فرصة متساوية للاستفادة من النظم التعليمية الفريدة التي تأتي مرة واحدة في العمر.",
  "scholarshipButton": "برنامج المنح الدراسية",
  "scrollDown": "تصفح لأسفل",
  "stayInTouch": "ابق على اطلاع", 
  "subscribeToNewsletter": "اشترك في نشرتنا الإخبارية لتتلقى آخر التحديثات، وإشعار فتح باب القبول بالمدرسة",
  "email": "البريد الإلكتروني",
  "subscribe": "اشترك",
  "subscribePlaceholder": "example@example.com",
  "copyrights": "{{date}} مدرسة منارة. كل الحقوق محفوظة.",
  "contactInfoError": "فشل الحصول على معلومات جهة الاتصال",
  "collegePrep": "منهج إعداد",
  "holisticProgram": "تقدم مدرسة منارة للطلاب مناهج شاملة تتضمن مكونات أمريكية ومصرية وعالمية تساعدهم على النمو والتعلم طوال حياتهم.",
  "curriculumWith": "للالتحاق بالجامعة مع",
  "roomForExploration": "مساحة للاستكشاف",
  "academicStructure": "النظام الأكاديمي",
  "manaraEducationPart1": "نحن نقدم مؤسسة لتطوير معرفة واسعة النطاق، ومهارات التفكير النقدى، وسمات",
  "manaraEducationPart2": "شخصية أساسية ، ومشاركة هادفة مع المجتمع والعالم.",
  "applicationsOpen": "فتح التقديم",
  "academicYearStarts": "بداية العام الدراسي",
  "getNotifiedWhen": "احصل على إشعار عند",
  "admissionsOpen": "فتح باب التقديم",
  "or": "أو",
  "contactUs": "اتصل بنا",
  "forMoreInfo": "لمزيد من المعلومات",
  "getHeaderError": "فشل في الحصول على بيانات روابط القائمة",
  "getFooterLinksError": "فشل في الحصول على بيانات روابط التذييل",
  "manaraLatest": "أحدث أخبار",
  "updates": "منارة",
  "allArticles": "كافة الموضوعات",
  "by": "بقلم",
  "articlesError": "فشل في الحصول على بيانات أخبار مدرسة منارة",
  "donationsError": "فشل في الحصول على بيانات التبرعات"
}
