import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainTitle from '../common/MainTitle';
import TeamCard from './TeamCard';

export default function Team({ data }) {
  const { t } = useTranslation(['AboutPage']);
  const [modalOpenIndex, setModalOpenIndex] = useState(null);

  function openModal(i) {
    setModalOpenIndex(i);
    document.body.classList.add('overflow-hidden');
  }
  function closeModal(event) {
    event.stopPropagation();
    setModalOpenIndex(null);
    document.body.classList.remove('overflow-hidden');
  }

  return (
    <section className="team container">
      <div className="team__header">
        <MainTitle type="dark" className="text--2xl">
          <span>{t('teamTitle1')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('teamTitle2')}
          </span>
        </MainTitle>
        <p className="team__header-description">{t('teamDescription')}</p>
      </div>

      <div className="team__content">
        {data.map((item, index) => (
          <TeamCard
            {...item}
            index={index}
            key={item.name}
            openModal={() => openModal(index)}
            closeModal={(e) => closeModal(e)}
            modalOpenIndex={modalOpenIndex}
          />
        ))}
      </div>
    </section>
  );
}
