{
  "pageTitle": "عن المنارة",
  "heroTitle1": "نصنع من",
  "heroTitle2": "الطلبة قادة",
  "heroTitle3": "سيلهمون العالم",
  "theStory": "القصة وراء",
  "manaraSchool": "مدرسة منارة",
  "leadershipTitle1": "الالتزام بـ",
  "leadershipTitle2": "تعزيز",
  "leadershipTitle3": "الإمكانات القيادية",
  "leadershipText1": "نحن نؤمن بأن العيش في الحرم المدرسي يعد جزءًا أساسيًا من رحلة وتجربة التعليم، ويوفر للطلاب مجتمعًا داعمًا يعزز نموهم الشخصي و استقلاليتهم. برنامج السكن الخاص بنا مصمم لخلق جو البيت الدافئ بعيدًا عن المنزل لطلابنا المتميزين، حيث يمكنهم الازدهار أكاديميًا، واجتماعيًا، وعاطفيًا.",
  "leadershipText2": "كما أن برنامج السكن لدينا يقدم مجموعة من المزايا، بما في ذلك بيئة معيشية آمنة ومأمونة، وفرص يومية للدعم الأكاديمي، وممارسة الأنشطة المتعددة خارج الأوقات الدراسية، وبناء صداقات تدوم مدى الحياة، وسيكون لدى الطلاب الفرصة للعيش مع أشخاص يتشابهون معهم فكرياً، ويشتركون معهم في شغفهم بالعلم، ودافعهم نحو تحقيق النجاح، ورغبتهم في ترك أثر إيجابي على العالم.",
  "leadershipButton": "البرامج الأكاديمية",
  "inclusivityTitle1": "الدمج الكامل والشمولية",
  "inclusivityTitle2": "جزء من",
  "inclusivityTitle3": "قيمنا ورسالتنا",
  "inclusivityText": "نلتزم في منارة بإنشاء مجتمع يرحب بالجميع ويشمل الكل حيث يتمكن جميع الطلاب من الازدهار. نحن نؤمن إيمانًا راسخًا بأن كل طالب له الحق في التعليم عالي الجودة، ونعمل بكل جد لضمان دمج قيمنا المتمثلة في الشمولية والمساواة في كل جانب من جوانب مهمة مدرستنا.",
  "inclusivityButton": "برنامج المنح الدراسية",
  "campusAnd": "الحرم المدرسي",
  "studentLife": "الحياة اليومية للطلاب",
  "teamTitle1": "أعضاء فريق",
  "teamTitle2": "مدرسة منارة",
  "teamDescription": "يلتزم فريق العمل ذو الخبرة الواسعة في منارة، والذي يتمتع بالخلفيات الأكاديمية المتنوعة بتقديم الدعم والتوجيه اللازمين للطلاب من أجل نجاحهم في جميع جوانب حياتهم. بفضل سنوات من الخبرة، والخلفية القوية في المدارس الداخلية، يعطي فريق العمل الأولوية القصوى لسلامة ورفاهية الطلاب.",
  "meetOurBoard": "تعرف على",
  "ofTrustees": "مجلس الأمناء",
  "boardOfTrusteesContent": "تُدار مدرسة منارة من قبل مجلس أمناء يشرف على الشؤون التنظيمية والمالية للمدرسة، بما في ذلك جمع التبرعات، مع تفويض مهام الإدارة اليومية وتحديد البرامج الدراسية والإجراءات المتبعة إلى مدير المدرسة.",
  "readMore": "قراءة المزيد"
}
