import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo-en.svg';
import LocaleContext from '../../contexts/LocaleContext';

export default function EnglishLogo() {
  const { locale } = useContext(LocaleContext);
  return (
    <h1 className="logo-en">
      <Link to={`/${locale}`} title="Homepage">
        <img src={Logo} alt="Manara School" />
      </Link>
    </h1>
  );
}
