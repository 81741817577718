import { useTranslation } from 'react-i18next';
import MainTitle from '../common/MainTitle';

import StudentGirlJpg from '../../assets/images/campus/student-life-at-manara.jpg';
import StudentGirlWebp from '../../assets/images/campus/student-life-at-manara.webp';

export default function StudentLifeAtManara() {
  const { t } = useTranslation(['CampusPage']);

  return (
    <section className="student-life">
      <div className="student-life__container">
        <MainTitle type="dark" className="text--2xl">
          <span>{t('student')}</span>
          <span className="decorated-line decorated-line--light decorated-line--left">
            {t('atManara')}
          </span>
        </MainTitle>

        <div className="student-life__content">
          <div className="student-life__description">
            <p>{t('studentLifeDescription1')}</p>
            <p className="decorated-line decorated-line--light decorated-line--right">
              {t('studentLifeDescription2')}
            </p>
          </div>

          <div className="student-life__media">
            <picture>
              <source srcSet={StudentGirlWebp} type="image/webp" />
              <img src={StudentGirlJpg} alt="student using VR goggles" />
            </picture>
            <span className="backdrop" />
          </div>
        </div>
      </div>
    </section>
  );
}
