import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import IntroSection from '../components/ContactUs/IntroSection';
import ConnectWithUs from '../components/ContactUs/ConnectWithUs';
import ContactUsForm from '../components/ContactUs/ContactUsForm';
import ApplicationDetails from '../components/ApplicationDetails';
import MetaTags from '../components/common/MetaTags';
import LocaleContext from '../contexts/LocaleContext';
import { getContactUsPage } from '../js/api/contact-page';
import ContactPageData from '../models/ContactPageData';
import scrollToTarget from '../js/utils/scroll';

export default function ContactPage() {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['ContactPage']);
  const location = useLocation();

  const [contactPageData, setContactPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getContactUsPage()
      .then((res) => {
        setContactPageData(new ContactPageData(res.data));
      })
      .catch((e) => {
        toast.error('An error occurred, try again later');
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [locale]);

  useEffect(() => {
    if (location?.hash === '#contact-form') {
      const element = document?.getElementById('contact-form');
      scrollToTarget(element);
    }
  }, [isLoading]);

  return (
    <main className="contact-us">
      {!isLoading && contactPageData ? (
        <>
          <MetaTags title={t('pageTitle')} seo={contactPageData?.metaTags} />
          <IntroSection data={contactPageData.connectWithUs} />
          <ConnectWithUs />
          <ContactUsForm />
          <ApplicationDetails />
        </>
      ) : null}
    </main>
  );
}
