import { useTranslation } from 'react-i18next';
import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';

import AcademicsStartJpg from '../../assets/images/academics/academics-top-left.jpeg';
import AcademicsStartWebp from '../../assets/images/academics/academics-top-left.webp';
import AcademicsEndJpg from '../../assets/images/academics/academics-top-right.jpeg';
import AcademicsEndWebp from '../../assets/images/academics/academics-top-right.webp';

export default function HeroSection() {
  const { t } = useTranslation(['AcademicsPage']);

  return (
    <section className="academics-hero-section container">
      <div className="academics-hero-section__content">
        <MainTitle type="dark" className="text--2xl hidden--lg">
          <span>{t('leadershipFocused')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('WorldClassEducation')}
          </span>
        </MainTitle>

        <div className="academics-hero-section__main-media hidden--md">
          <picture>
            <source srcSet={AcademicsStartWebp} type="image/webp" />
            <img src={AcademicsStartJpg} alt="manara-students" />
          </picture>
        </div>

        <div className="academics-hero-section__content-container">
          <MainTitle type="dark" className="text--2xl hidden--md">
            <span>{t('leadershipFocused')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('WorldClassEducation')}
            </span>
          </MainTitle>

          <p>{t('heroSectionDescription')}</p>
        </div>

        <div className="academics-hero-section__media">
          <div className="academics-hero-section__main-media hidden--lg">
            <picture>
              <source srcSet={AcademicsStartWebp} type="image/webp" />
              <img src={AcademicsStartJpg} alt="manara-students" />
            </picture>
          </div>

          <div className="academics-hero-section__secondary-media">
            <picture>
              <source srcSet={AcademicsEndWebp} type="image/webp" />
              <img src={AcademicsEndJpg} alt="manara-students" />
            </picture>
          </div>
        </div>
      </div>

      <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />
    </section>
  );
}
