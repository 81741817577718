import { useTranslation } from 'react-i18next';
import MainTitle from '../common/MainTitle';

import BoyStudentJpg from '../../assets/images/campus/campus-life-left.jpeg';
import BoyStudentWebp from '../../assets/images/campus/campus-life-left.webp';
import GirlStudentJpg from '../../assets/images/campus/campus-life-right.jpeg';
import GirlStudentWebp from '../../assets/images/campus/campus-life-right.webp';

export default function HeroSection() {
  const { t } = useTranslation(['CampusPage']);

  return (
    <section className="campus-hero-section container">
      <div className="campus-hero-section__secondary-media">
        <picture>
          <source srcSet={BoyStudentWebp} type="image/webp" />
          <img src={BoyStudentJpg} alt="manara-students" />
        </picture>
      </div>

      <div className="campus-hero-section__content">
        <MainTitle type="dark" className="text--2xl hidden--lg">
          <span>{t('campus')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('studentLife')}
          </span>
        </MainTitle>

        <div className="campus-hero-section__content-container">
          <MainTitle type="dark" className="text--2xl hidden--md">
            <span>{t('campus')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('studentLife')}
            </span>
          </MainTitle>

          <p>{t('heroSectionDescription')}</p>
        </div>

        <div className="campus-hero-section__main-media">
          <picture>
            <source srcSet={GirlStudentWebp} type="image/webp" />
            <img src={GirlStudentJpg} alt="manara-students" />
          </picture>
        </div>
      </div>
    </section>
  );
}
