import { Field } from 'formik';
import Icon from '../Icon';

/**
 *
 * @param {{
 * icon: string',
 * iconClassName: string',
 * name: string',
 * type: string',
 * id: string',
 * label: string',
 * placeholder: string',
 * className: string',
 * autocomplete: boolean,
 * hasError: boolean,
 * theme: 'dark' | 'light',
 * children
 * }} props
 * @returns
 */
export default function TextInput({
  icon = '',
  iconClassName = '',
  name = '',
  type = '',
  id = '',
  label = '',
  placeholder = '',
  className = '',
  hasError = false,
  required = false,
  theme = 'dark',
  children,
  validate = () => {}
}) {
  return (
    <div
      className={`text-input-wrapper text-input-wrapper--${theme} ${className} ${
        hasError ? 'error' : ''
      }`}>
      <label htmlFor={id}>
        <span>{label}</span>
        {required && <span className="asterisk"> *</span>}
      </label>
      <Field type={type} name={name} id={id} placeholder={placeholder} validate={validate} />

      <Icon
        name={icon}
        className={`${iconClassName} input-icon`}
        stroke={theme === 'light' ? '#fff' : '#262452'}
      />
      {hasError && <Icon name="error" className="error-icon" />}
      {children}
    </div>
  );
}
