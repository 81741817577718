/* eslint-disable import/prefer-default-export */
import { ENDPOINTS } from './endpoints';
import { fetchData } from './network';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';

export function getCareersPage() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getCareers.path}`);

  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate[benefits_and_polices][populate]', '*');
  url.searchParams.append('populate[portrait_of_an_educator][populate]', '*');
  url.searchParams.append('populate[key_team_slider][populate]', '*');

  return fetchData(url, {
    method: ENDPOINTS.getCareers.method
  }).then((response) => response);
}
