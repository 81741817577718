import { useState } from 'react';

export default function AppAccordion({
  header,
  content,
  headerClassName,
  contentClassName,
  handelExpandedView
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  function handleToggling() {
    setIsExpanded(!isExpanded);
    handelExpandedView();
  }

  return (
    <div className="app-accordion">
      <header className="header-container">
        <div className={`accordion__header ${headerClassName || ''}`}>{header}</div>

        <button type="button" className="button toggle-button" onClick={handleToggling}>
          <svg className="toggle-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
              <path
                d="M9.5 1h1v18h-1V1zm1 0v18h-1V1h1z"
                stroke="currentColor"
                className={`${isExpanded && 'is-expanded'}`}
              />
              <path
                d="M9.5 1h1v18h-1V1zm1 0v18h-1V1h1z"
                stroke="currentColor"
                className={`vertical-path ${isExpanded && 'is-expanded'}`}
              />
            </g>
          </svg>
        </button>
      </header>

      {isExpanded && (
        <div className={`accordion__content ${contentClassName || ''}`}>{content}</div>
      )}
    </div>
  );
}
