/* eslint-disable indent */
import { useEffect, useState } from 'react';
import ImageLink from '../../js/utils/ImageLink';

import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';

export default function MaximizingLeadership({ data }) {
  const [isMobile, setIsMobile] = useState(false);
  const [isArabic, setIsArabic] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (document.dir === 'rtl') {
      setIsArabic(true);
    } else {
      setIsArabic(false);
    }
  }, [isArabic]);

  return (
    <div className="maximizing-leadership-wrapper">
      <section className="leadership-section">
        <div className="leadership-section__header">
          <MainTitle type="dark" className="text--2xl">
            <span
              className={`${
                !isMobile ? 'decorated-line decorated-line--dark decorated-line--right' : ''
              } `}>
              {data?.title1}
            </span>
            <span
              className={`${
                isMobile ? 'decorated-line decorated-line--dark decorated-line--left' : ''
              } `}>
              {data?.title2}
            </span>
            <span>{data?.title3}</span>
          </MainTitle>
        </div>

        <div className="leadership-section__content">
          <p>{data?.text1}</p>
          <p>{data?.text2}</p>
        </div>

        <div className="leadership-section__media">
          <img src={ImageLink(data?.image?.url)} alt="child-making-robot" />
          <Icon name="light-bulb" className="light-bulb-icon" />
          <Icon name="ribbon" className="ribbon-icon" />
        </div>
        <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />
      </section>
    </div>
  );
}
