{
  "pageTitle": "Academics",
  "leadershipFocused": "A Leadership Focused",
  "WorldClassEducation": "World-Class Education",
  "heroSectionDescription": "At Manara, our students are not just leaders, they are game-changers. From active participation in class to extracurricular activities, community service, and our unique jobs program, each student takes charge and contributes to the school, the community, and the nation. To facilitate their growth, we offer a project-based seminar that emphasizes ethical leadership for every new student. Our curriculum is specifically designed to hone the executive skills and character traits that are essential for engaged citizenship, and our faculty deliberately guides our students to excel and become the leaders of tomorrow.",
  "manaraPortrait": "Manara's Portrait",
  "ofGraduate": "of a graduate",
  "whatDoesOur": "What Does Our",
  "studentCentered": "What does our student-centered",
  "curriculum": "Curriculum offer?",
  "pursueYourPassionTitle1": "Explore Your",
  "pursueYourPassionTitle2": "Passion With",
  "pursueYourPassionTitle3": "The X-Term",
  "coursesCatalogError": "Failed to get courses catalog",
  "quarter": "Q",
  "upcomingEvents": "Upcoming Events",
  "pastEvents": "Past Events"
}
