import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { submitContactRequests } from '../../js/api/contact-us-form';
import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';
import TextInput from '../common/Inputs/TextInput';
import SelectInput from '../common/Inputs/SelectInput';
// import LocaleContext from '../../contexts/LocaleContext';
import {
  schoolOptionsEn,
  hearAboutUsOptionsEn,
  lookingForOptionsEn,
  gradeOptionsEn,
  schoolOptionsAr,
  hearAboutUsOptionsAr,
  lookingForOptionsAr,
  gradeOptionsAr
} from '../../js/utils/consts';
import { formatDate } from '../../js/utils/format-date';

const initialValues = {
  student_first_name: '',
  student_last_name: '',
  primary_email: '',
  primary_phone_number: '',
  student_date_of_birth: '',
  student_current_grade: '',
  type_of_school: '',
  what_are_you_looking_for: '',
  how_did_you_hear_about_us: '',
  subscribe_to_newsletter: false
};

export default function ContactUsForm() {
  const { t, i18n } = useTranslation(['ContactPage']);
  const navigate = useNavigate();

  //  The current path
  const fullPath = window.location.href;

  function resolveCurrentDate() {
    return formatDate(new Date(), i18n.language === 'en' ? 'en-US' : 'ar-EG');
  }

  const ContactUsFormSchema = Yup.object().shape({
    student_first_name: Yup.string().required(t('firstNameError')),
    student_last_name: Yup.string().required(t('lastNameError')),
    primary_email: Yup.string().email(t('emailError')).required(t('emailRequired')),
    primary_phone_number: Yup.string().required(t('phoneNumberRequired')),
    student_date_of_birth: Yup.date()
      .default(() => new Date())
      .max(new Date(), t('dateOfBirthError', { date: resolveCurrentDate() }))
      .required(t('dateOfBirthRequired')),
    student_current_grade: Yup.string().required(t('studentGradeRequired')),
    type_of_school: Yup.string().required(t('typeOfSchoolRequired')),
    what_are_you_looking_for: Yup.string().required(t('fieldIsRequired')),
    how_did_you_hear_about_us: Yup.string().required(t('fieldIsRequired')),
    subscribe_to_newsletter: Yup.boolean(),
    referral: Yup.string()
  });

  const handleSubmit = async (data) => {
    await submitContactRequests({
      ...data,
      referral: fullPath
    })
      .then(() => {
        toast.success(t('successMessage'));
        // Redirect to thank you page after the form is submitted successfully
        navigate('/thank-you', { replace: true });
      })
      .catch((error) => {
        toast.error(t('genericError'));
        throw error;
      });
  };

  return (
    <div className="contact-form__container" id="contact-form">
      <MainTitle type="dark" className="text--2xl">
        <span>{t('reachOutToUs')}</span>
        <span className="decorated-line decorated-line--dark decorated-line--left">
          {t('happyToConverse')}
        </span>
      </MainTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={ContactUsFormSchema}
        onSubmit={async (values, { errors, resetForm }) => {
          await handleSubmit(values);
          if (!errors) {
            resetForm();
          }
        }}>
        {({ errors, touched, isValid, dirty }) => (
          <Form className="contact-form__form">
            <fieldset className="student-info">
              <legend className="sr-only">{t('studentInfo')}</legend>
              <TextInput
                label={t('firstNameLabel')}
                type="text"
                name="student_first_name"
                id="student_first_name"
                placeholder={t('firstNamePlaceholder')}
                icon="profile-icon"
                required
                hasError={touched.student_first_name && errors.student_first_name}
                theme="dark">
                {errors.student_first_name && touched.student_first_name ? (
                  <span className="error-msg">{errors.student_first_name}</span>
                ) : null}
              </TextInput>

              <TextInput
                label={t('lastNameLabel')}
                type="text"
                name="student_last_name"
                id="student_last_name"
                placeholder={t('lastNamePlaceholder')}
                icon="profile-icon"
                required
                hasError={touched.student_last_name && errors.student_last_name}
                theme="dark">
                {errors.student_last_name && touched.student_last_name ? (
                  <span className="error-msg">{errors.student_last_name}</span>
                ) : null}
              </TextInput>

              <TextInput
                label={t('emailLabel')}
                type="email"
                name="primary_email"
                id="primary_email"
                placeholder={t('emailPlaceholder')}
                icon="open-envelope"
                required
                className="col-span-2--desktop"
                hasError={touched.primary_email && errors.primary_email}
                theme="dark">
                {errors.primary_email && touched.primary_email ? (
                  <span className="error-msg">{errors.primary_email}</span>
                ) : null}
              </TextInput>

              <TextInput
                label={t('phoneNumberLabel')}
                type="tel"
                name="primary_phone_number"
                id="primary_phone_number"
                placeholder={t('phoneNumberPlaceholder')}
                icon="phone"
                required
                className="col-span-2--desktop"
                hasError={touched.primary_phone_number && errors.primary_phone_number}
                theme="dark">
                {errors.primary_phone_number && touched.primary_phone_number ? (
                  <span className="error-msg">{errors.primary_phone_number}</span>
                ) : null}
              </TextInput>

              <TextInput
                label={t('dateOfBirthLabel')}
                type="date"
                name="student_date_of_birth"
                id="student_date_of_birth"
                placeholder={t('dateOfBirthPlaceholder')}
                icon="calendar-outline"
                required
                className="col-span-2--desktop"
                hasError={touched.student_date_of_birth && errors.student_date_of_birth}
                theme="dark">
                {errors.student_date_of_birth && touched.student_date_of_birth ? (
                  <span className="error-msg">{errors.student_date_of_birth}</span>
                ) : null}
              </TextInput>

              <SelectInput
                label={t('studentGradeLabel')}
                name="student_current_grade"
                id="student_current_grade"
                icon="grade"
                required
                options={i18n.language === 'en' ? gradeOptionsEn : gradeOptionsAr}
                hasError={touched.student_current_grade && errors.student_current_grade}
                theme="dark">
                {errors.student_current_grade && touched.student_current_grade ? (
                  <span className="error-msg">{errors.student_current_grade}</span>
                ) : null}
              </SelectInput>

              <SelectInput
                label={t('typeOfSchoolLabel')}
                name="type_of_school"
                id="type_of_school"
                icon="curriculum-icon"
                required
                options={i18n.language === 'en' ? schoolOptionsEn : schoolOptionsAr}
                hasError={touched.type_of_school && errors.type_of_school}
                theme="dark">
                {errors.type_of_school && touched.type_of_school ? (
                  <span className="error-msg">{errors.type_of_school}</span>
                ) : null}
              </SelectInput>
            </fieldset>

            <fieldset className="general-info">
              <legend>{t('generalInfo')}</legend>
              <SelectInput
                label={t('youAreLookingForLabel')}
                name="what_are_you_looking_for"
                id="what_are_you_looking_for"
                icon="question"
                className="col-span-2--desktop"
                required
                options={i18n.language === 'en' ? lookingForOptionsEn : lookingForOptionsAr}
                hasError={touched.what_are_you_looking_for && errors.what_are_you_looking_for}
                theme="dark">
                {errors.what_are_you_looking_for && touched.what_are_you_looking_for ? (
                  <span className="error-msg">{errors.what_are_you_looking_for}</span>
                ) : null}
              </SelectInput>

              <SelectInput
                label={t('hearAboutUsLabel')}
                name="how_did_you_hear_about_us"
                id="how_did_you_hear_about_us"
                icon="info"
                className="col-span-2--desktop"
                required
                options={i18n.language === 'en' ? hearAboutUsOptionsEn : hearAboutUsOptionsAr}
                hasError={touched.how_did_you_hear_about_us && errors.how_did_you_hear_about_us}
                theme="dark">
                {errors.how_did_you_hear_about_us && touched.how_did_you_hear_about_us ? (
                  <span className="error-msg">{errors.how_did_you_hear_about_us}</span>
                ) : null}
              </SelectInput>
            </fieldset>
            <label>
              <Field type="checkbox" name="subscribe_to_newsletter" />
              {t('subscribeToNewsletter')}
            </label>

            <input type="hidden" id="referral" name="referral" value={fullPath} />

            <button type="submit" className="button submit-button" disabled={!isValid || !dirty}>
              {t('submitRequest')}
            </button>
          </Form>
        )}
      </Formik>
      <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />
    </div>
  );
}
