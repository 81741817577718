/* eslint-disable camelcase */
import ImageData from './Image';

class CampusData {
  constructor({
    attributes: {
      SEO_meta_tags,
      campus_and_community,
      residential_experience,
      co_curricular_programs,
      amenity_slide,
      housing_and_accommodation
      // student_dormitories_and_dining_hall
    }
  }) {
    const campusAndCommunity = campus_and_community.map((step) => {
      const { id, description, image } = step;
      return {
        id,
        description,
        image: new ImageData(image?.data)
      };
    });
    this.campusAndCommunity = campusAndCommunity;

    this.residentialExperience = {
      id: residential_experience?.id,
      description: residential_experience?.description,
      video: new ImageData(residential_experience?.video?.data),
      videoCover: new ImageData(residential_experience?.video_cover?.data)
    };

    this.coCurricularPrograms = co_curricular_programs.map((program) => {
      const { id, title, description, image, icon_name } = program;
      return {
        id,
        title,
        description,
        image: new ImageData(image?.data),
        icon: icon_name
      };
    });

    this.amenitySlide = amenity_slide.map((item) => {
      const {
        id,
        slide_main_title,
        slide_center_image,
        slide_title_line_1,
        slide_title_line_2,
        slide_right_paragraph,
        slide_right_image,
        slide_left_paragraph,
        slide_left_image
      } = item;

      return {
        id,
        mainTitle: slide_main_title,
        title1: slide_title_line_1,
        title2: slide_title_line_2,
        centerImage: new ImageData(slide_center_image?.data),
        startText: slide_left_paragraph,
        startImage: new ImageData(slide_left_image?.data),
        endText: slide_right_paragraph,
        endImage: new ImageData(slide_right_image?.data)
      };
    });

    this.housingAndAccommodation = housing_and_accommodation.map((item) => {
      const { id, title, description, image } = item;
      return {
        id,
        title,
        description,
        image: new ImageData(image?.data)
      };
    });

    // TODO: Hide it as per client request. Enable it later.
    // this.studentDormitories = student_dormitories_and_dining_hall?.data?.map((item) => ({
    //   id: item.id,
    //   image: new ImageData(item)
    // }));

    this.metaTags = SEO_meta_tags?.map(
      ({ id, meta_tag_name: name, meta_tag_content: content }) => ({
        id,
        name,
        content
      })
    );
  }
}

export default CampusData;
