// import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';
// import AppModal from '../common/AppModal';
import ImageLink from '../../js/utils/ImageLink';

// TODO: Enable Video player. It's hidden as per the client's request
export default function ResidentialExperience({ data }) {
  const { t } = useTranslation(['CampusPage']);
  // const [modalOpen, setModalOpen] = useState(false);

  // function openModal() {
  //   setModalOpen(true);
  //   document.body.classList.add('overflow-hidden');
  // }
  // function closeModal(event) {
  //   event.stopPropagation();
  //   setModalOpen(false);
  //   document.body.classList.remove('overflow-hidden');
  // }

  return (
    <section className="residential-experience">
      <MainTitle type="dark" className="text--2xl">
        <span>{t('theResidential')}</span>
        <span className="decorated-line decorated-line--dark decorated-line--left">
          {t('experience')}
        </span>
      </MainTitle>

      <div className="residential-experience__container">
        <p className="residential-experience__description">{data?.description}</p>

        <div className="residential-experience__media">
          <img src={ImageLink(data?.videoCover?.url)} alt={data?.videoCover?.alt} />
          {/* <span className="backdrop" />
          <button type="button" className="button play-button" onClick={openModal}>
            <Icon name="play-icon" className="play-icon" />
          </button> */}
        </div>
      </div>

      <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />

      {/* {modalOpen && (
        <AppModal close={(e) => closeModal(e)} className="residential-experience__modal">
          <MainTitle type="dark" className="text--2xl">
            <span>{t('theResidential')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('experience')}
            </span>
          </MainTitle>
          <div className="residential-experience__video">
            <video controls width="250" title={data?.video?.alt}>
              <track default kind="captions" srcLang="en" />
              <source src={ImageLink(data?.video?.url)} type="video/mp4" />
            </video>
          </div>
        </AppModal>
      )} */}
    </section>
  );
}
