import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import * as HomePageEn from './public/locales/en/home-page.json';
import * as HomePageAr from './public/locales/ar/home-page.json';
import * as AboutPageEn from './public/locales/en/about-page.json';
import * as AboutPageAr from './public/locales/ar/about-page.json';
import * as ContactPageEn from './public/locales/en/contact-page.json';
import * as ContactPageAr from './public/locales/ar/contact-page.json';
import * as CareersPageEn from './public/locales/en/careers-page.json';
import * as CareersPageAr from './public/locales/ar/careers-page.json';
import * as AdmissionsPageEn from './public/locales/en/admissions-page.json';
import * as AdmissionsPageAr from './public/locales/ar/admissions-page.json';
import * as CampusPageEn from './public/locales/en/campus-page.json';
import * as CampusPageAr from './public/locales/ar/campus-page.json';
import * as NewsPageEn from './public/locales/en/news-page.json';
import * as NewsPageAr from './public/locales/ar/news-page.json';
import * as AcademicsPageEn from './public/locales/en/academics-page.json';
import * as AcademicsPageAr from './public/locales/ar/academics-page.json';
import * as DonatePageEn from './public/locales/en/donate-page.json';
import * as DonatePageAr from './public/locales/ar/donate-page.json';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar'],
    // Allows "en-US" and "en-UK" to be implicitly supported when "en"
    // is supported
    nonExplicitSupportedLngs: true,
    resources: {
      en: {
        HomePage: HomePageEn,
        AboutPage: AboutPageEn,
        ContactPage: ContactPageEn,
        CareersPage: CareersPageEn,
        AdmissionsPage: AdmissionsPageEn,
        CampusPage: CampusPageEn,
        NewsPage: NewsPageEn,
        AcademicsPage: AcademicsPageEn,
        DonatePage: DonatePageEn
      },
      ar: {
        HomePage: HomePageAr,
        AboutPage: AboutPageAr,
        ContactPage: ContactPageAr,
        CareersPage: CareersPageAr,
        AdmissionsPage: AdmissionsPageAr,
        CampusPage: CampusPageAr,
        NewsPage: NewsPageAr,
        AcademicsPage: AcademicsPageAr,
        DonatePage: DonatePageAr
      }
    },
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
