import MainTitle from './MainTitle';

export default function PageCTA({
  title1,
  title2,
  children,
  image,
  imageWebp,
  alt,
  decoratedLine = 'left'
}) {
  return (
    <section className="page-cta">
      <div className="page-cta__container">
        <div className="page-cta__media">
          <picture>
            <source srcSet={imageWebp || image} type="image/webp" />
            <img src={image} alt={alt} />
          </picture>
          <div className="overlay" />
        </div>

        <div className="page-cta__container-header">
          <MainTitle type="light" className="text--2xl">
            <span>{title1}</span>
            <span
              className={`decorated-line decorated-line--light decorated-line--${decoratedLine}`}>
              {title2}
            </span>
          </MainTitle>

          <div className="page-cta__link">{children}</div>
        </div>
      </div>
    </section>
  );
}
