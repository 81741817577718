import ImageData from './Image';

/* eslint-disable camelcase */
class CareersData {
  constructor({
    attributes: {
      SEO_meta_tags,
      benefits_and_polices,
      explore_opportunities_link,
      explore_opportunities_text,
      key_team_slider,
      portrait_of_an_educator
    }
  }) {
    this.explore = {
      text: explore_opportunities_text,
      link: explore_opportunities_link
    };

    const items = benefits_and_polices.map((item) => {
      const { id, image, title, description } = item;

      return {
        id,
        title,
        image: new ImageData(image?.data),
        text: description
      };
    });
    this.benefits = items;

    const keyTeamItems = key_team_slider.map((teamItem) => {
      const { id, title, description, image, slide_link_text, slide_link_url } = teamItem;

      return {
        id,
        title,
        text: description,
        linkText: slide_link_text,
        linkUrl: slide_link_url,
        image: new ImageData(image.data)
      };
    });
    this.keyTeam = keyTeamItems;

    this.portraitOfEducator = new ImageData(portrait_of_an_educator?.data);

    this.metaTags = SEO_meta_tags?.map(
      ({ id, meta_tag_name: name, meta_tag_content: content }) => ({
        id,
        name,
        content
      })
    );
  }
}

export default CareersData;
