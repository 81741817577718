import { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import MainTitle from '../common/MainTitle';
import AppButton from '../common/AppButton';
import ArticleCard from './ArticleCard';
import LocaleContext from '../../contexts/LocaleContext';
import { getArticles } from '../../js/api/articles';
import ArticlesData from '../../models/ArticlesData';

export default function ArticleGrid() {
  const { t } = useTranslation(['HomePage']);
  const { locale } = useContext(LocaleContext);
  const [articles, setArticles] = useState(null);

  useEffect(() => {
    getArticles()
      .then((res) => {
        const tempResData = res.data.map((item) => new ArticlesData(item));
        setArticles(tempResData?.splice(0, 3));
      })
      .catch((e) => {
        toast.error(t('articlesError'));
        throw e;
      });
  }, [locale]);

  return (
    <div className="article-grid container">
      <MainTitle type="dark" tag="h2" className="text--2xl article-grid__title">
        <span>{t('manaraLatest')}</span>
        <span className="decorated-line decorated-line--dark decorated-line--left">
          {t('updates')}
        </span>
      </MainTitle>
      <div className="article-grid__container">
        {articles?.map((item) => (
          <ArticleCard key={item.title} {...item} />
        ))}
      </div>

      <AppButton tag="a" linkPath={`/${locale}/news`}>
        {t('allArticles')}
      </AppButton>
    </div>
  );
}
