/**
 *
 * @param {{type: 'light' | 'dark', tag?: string,  className?: string}} props
 * @returns
 */
export default function MainTitle({ children, type = 'dark', tag = 'h2', className = '' }) {
  const Element = tag;

  return <Element className={`main-title main-title--${type} ${className}`}>{children}</Element>;
}
