import { Field } from 'formik';
import Icon from '../Icon';

/**
 *
 * @param {{
 * icon: string',
 * iconClassName: string',
 * name: string',
 * type: string',
 * id: string',
 * label: string',
 * placeholder: string',
 * className: string',
 * autocomplete: boolean,
 * hasError: boolean,
 * theme: 'dark' | 'light',
 * children
 * }} props
 * @returns
 */
export default function SelectInput({
  icon = '',
  iconClassName = '',
  name = '',
  type = '',
  id = '',
  label = '',
  placeholder = '',
  className = '',
  hasError = false,
  required = false,
  theme = 'dark',
  options = [],
  children,
  validate = () => {}
}) {
  return (
    <div
      className={`select-input-wrapper select-input-wrapper--${theme} ${className} ${
        hasError ? 'error' : ''
      }`}>
      <label htmlFor={id}>
        <span>{label}</span>
        {required && <span className="asterisk"> *</span>}
      </label>
      <Field
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        validate={validate}
        as="select">
        {options.map((opt) => (
          <option
            value={opt.value}
            key={`${opt.value}-option`}
            defaultValue={!opt.value}
            disabled={!opt.value}>
            {opt.label}
          </option>
        ))}
      </Field>

      <Icon
        name={icon}
        className={`${iconClassName} input-icon`}
        stroke={theme === 'light' ? '#fff' : '#262452'}
      />

      <Icon
        name="arrow-down"
        className={`${iconClassName} arrow-down`}
        stroke={theme === 'light' ? '#fff' : '#262452'}
      />
      {hasError && <Icon name="error" className="error-icon" />}
      {children}
    </div>
  );
}
