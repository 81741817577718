export default function scrollToTarget(element) {
  const headerOffset = 81;
  const elementPosition = element?.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  });
}
