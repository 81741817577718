import { useEffect, useState } from 'react';
import ImageLink from '../../../js/utils/ImageLink';

export default function Slide({ id, image, description }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isMobile]);

  return (
    <div className="campus-and-community__slide" key={id}>
      <div className="slide__content">
        <p className="slide__description">{description}</p>
      </div>

      <div className="slide__media">
        <img src={ImageLink(image.url)} alt={image.alt} />
        <div className="backdrop" />
      </div>
    </div>
  );
}
