{
  "pageTitle": "اتصل بنا",
  "weWouldLoveTo": "نحب أن",
  "hearFromYou": "نسمع منك",
  "followUs": "تابعنا على",
  "sendONWhatsapp": "أرسل على الواتس اب",
  "reachOutToUs": "تواصل معنا ، نحن",
  "happyToConverse": "يسعدنا التحدث معك",
  "firstNameLabel": "الاسم الأول للطالب",
  "firstNamePlaceholder": "محمد",
  "firstNameError": "مطلوب الاسم الأول للطالب!",
  "lastNameLabel": "الاسم الأخير للطالب",
  "lastNamePlaceholder": "أحمد",
  "lastNameError": "مطلوب الاسم الأخير للطالب!",
  "emailLabel": "البريد الإلكتروني الأساسي",
  "emailPlaceholder": "example@example.com",
  "emailError": "يجب ادخال بريد إلكتروني يوافق الصيغة التالية ex@example.com",
  "emailRequired": "مطلوب البريد الإلكتروني!",
  "phoneNumberLabel": "رقم الهاتف الأساسي",
  "phoneNumberPlaceholder": "010 000 000 00",
  "phoneNumberRequired": "مطلوب رقم الهاتف!",
  "studentInfo": "بيانات الطالب",
  "dateOfBirthLabel": "تاريخ ميلاد الطالب",
  "dateOfBirthPlaceholder": "يوم/شهر/سنة",
  "dateOfBirthRequired": "مطلوب تاريخ ميلاد الطالب",
  "dateOfBirthError": "يجب أن يكون تاريخ الميلاد أقدم من {{date}}",
  "studentGradeLabel": "الصف الحالي للطالب",
  "studentGradeRequired": "مطلوب الصف الحالي للطالب",
  "schoolCurriculumLabel": "منهج المدرسة الحالي",
  "schoolCurriculumRequired": "مطلوب منهج المدرسة الحالي الطالب",
  "generalInfo": "معلومات عامة",
  "youAreLookingForLabel": "عما تبحث؟",
  "hearAboutUsLabel": "كيف سمعت عنا؟",
  "subscribeToNewsletter": "أرغب في الاشتراك في نشرة منارة الإخبارية",
  "fieldIsRequired": "هذه الخانة مطلوبه!",
  "submitRequest": "إرسال الطلب",
  "successMessage": "شكرًا لك ، تم إرسال طلبك بنجاح ، وسنعاود الاتصال بك.",
  "genericError": "حدث خطأ. يرجى إعادة المحاولة في وقت لاحق!"
}
