{
  "manaraSchool": "Manara School",
  "pageTitle": "Homepage",
  "menu": "MENU",
  "changeLang": "Change Language",
  "applyNow": "apply now",
  "getInTouch": "Get In Touch",
  "maximizingLeadership": "Maximizing leadership",
  "potential": "potential to",
  "developOur": "develop our",
  "futureLeaders": "society’s future leaders",
  "leadershipContent1": "Manara School will provide exceptional students with a world-class education based on a framework of ethics, academics, and diversity.",
  "leadershipContent2": "We expect our students to become the passionate leaders required to shape a more prosperous and equitable world.",
  "helpingStudents": "Empowering Exceptional",
  "reachTheirFull": "Students to reach",
  "leadershipPotential": "their full potential",
  "helpingStudentsContent": "Manara School is a co-ed school for exceptional students in Grades 8 - 12. It is a hub for talented, skilled, and passionate students who share the same drive to turn their dreams into reality. Our world-class academic and residential program, designed and delivered by a team of experts, brings together exceptional students from diverse backgrounds. With the support of two non-profit foundations, we provide a unique opportunity for like-minded individuals to grow together and reach their full leadership potential.",
  "ourVision": "More About Our Vision",
  "exceptionalTitle": "Exceptional",
  "exceptionalDescription": "Academically accomplished students who demonstrate leadership potential",
  "diverseBackgroundsTitle": "Diverse Backgrounds",
  "diverseBackgroundsDescription": "Students from diverse backgrounds across Egypt and around the globe",
  "leadershipTitle": "Leadership",
  "leadershipDescription": "Developing a passion, finding a purpose, and acquiring the skills for a positive impact",
  "experiencesTitle1": "Experiences",
  "experiencesTitle2": "That Transform",
  "experiencesDesc": "Fanar Programs provides students with unprecedented cultural learning experiences in Egypt that will enrich their lives.",
  "experiencesLink": "Explore Fanar",
  "donationsTitle1": "Support",
  "donationsTitle2": "Future Leaders",
  "donationsDesc1": "We are providing an opportunity for donors to transform children's lives who show great academic and leadership potential by giving them access to a first-rate education.",
  "donationsDesc2": "Donors to the two non-profits supporting Manara can help level the playing field for less privileged kids in Egypt by providing the advantage of a college preparatory program that will set them up for success in university and beyond.",
  "donateNow": "Donate now",
  "scholarshipTitle1": "a robust program",
  "scholarshipTitle2": "based on financial need",
  "scholarshipDesc1": "At Manara, we firmly believe that outstanding potential should not go unrecognized, and this is why we are dedicated to providing access to our world-class education to exceptional young people from all backgrounds. Our financial-aid program is designed to ensure that no talented student",
  "scholarshipDesc2": "is left behind due to financial barriers. We will be publishing details on how to apply for financial aid alongside admissions information to ensure that every student has an equal chance to receive this once-in-a-lifetime opportunity.",
  "scholarshipButton": "scholarship program",
  "scrollDown": "Scroll Down",
  "stayInTouch": "Stay in The Know",
  "subscribeToNewsletter": "Subscribe to our newsletter and get the latest updates and get notified when the admissions open",
  "email": "E-mail",
  "subscribe": "Subscribe",
  "subscribePlaceholder": "example@example.com",
  "copyrights": "{{date}} Manara School. All rights reserved.",
  "contactInfoError": "Failed to get contact info",
  "collegePrep": "A College-Prep",
  "curriculumWith": "Curriculum with",
  "roomForExploration": "Room for Exploration",
  "holisticProgram": "With a rigorous program that incorporates American, Egyptian, and international elements, Manara will prepare students for lifelong growth and learning.",
  "academicStructure": "Academic Program",
  "manaraEducationPart1": "A Manara education offers a foundation of wide-ranging knowledge, incisive intellectual skills,",
  "manaraEducationPart2": "essential character traits, and meaningful engagement with the community and the world.",
  "applicationsOpen": "Applications Open",
  "academicYearStarts": "Academic Year Starts",
  "getNotifiedWhen": "Get Notified When",
  "admissionsOpen": "Admissions Open",
  "or": "or",
  "contactUs": "contact us",
  "forMoreInfo": "for more info",
  "getHeaderError": "Failed to get menu links data",
  "getFooterLinksError": "Failed to get footer links data",
  "manaraLatest": "Latest Updates",
  "updates": "from Manara",
  "allArticles": "All articles",
  "by": "by",
  "articlesError": "Failed to get articles data",
  "donationsError": "Failed to get donations data"
}
