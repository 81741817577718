import { useTranslation } from 'react-i18next';
// import ImageLink from '../../js/utils/ImageLink';

import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';
import Culture from '../../assets/images/careers/culture-and-environment.jpeg';
import CultureWebp from '../../assets/images/careers/culture-and-environment.webp';

/**
 *
 * @param {{ file: File }} props
 * @returns
 */

export default function CultureEnvironment() {
  const { t } = useTranslation(['CareersPage']);

  return (
    <div className="culture">
      <section className="culture-section container">
        <div className="culture-section__header mobile">
          <MainTitle type="dark" className="text--2xl">
            <span>{t('cultureTitle1')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('cultureTitle2')}
            </span>
          </MainTitle>
        </div>

        <div className="culture-section__content">
          <div className="culture-section__header desktop">
            <MainTitle type="dark" className="text--2xl">
              <span>{t('cultureTitle1')}</span>
              <span className="decorated-line decorated-line--dark decorated-line--left">
                {t('cultureTitle2')}
              </span>
            </MainTitle>
          </div>
          <p>{t('cultureText')}</p>

          {/* TODO: Enable it later. Hide Download Link as per client request */}
          {/* <a
            className="download"
            href={ImageLink(file.url)}
            target="_blank"
            rel="noopener noreferrer"
            download>
            <Icon name="download" />
            <span>{t('cultureButton')}</span>
          </a> */}
        </div>

        <div className="culture-section__media">
          <picture>
            <source srcSet={CultureWebp} type="image/webp" />
            <img src={Culture} alt="Teachers having a meeting" />
          </picture>
          <Icon name="collaboration" className="collaboration-icon" />
          <Icon name="growth" className="growth-icon" />
        </div>
      </section>
    </div>
  );
}
