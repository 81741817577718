import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getOpportunities } from '../../../js/api/current-opportunities';
import LocaleContext from '../../../contexts/LocaleContext';
import OpportunitiesData from '../../../models/OpportunitiesData';
import MainTitle from '../../common/MainTitle';
import OpportunitiesItem from './OpportunitiesItem';
import Icon from '../../common/Icon';

export default function Opportunities() {
  const [accordionOpenId, setAccordionOpenId] = useState(null);

  const [accordionData, setAccordionData] = useState();
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['CareersPage']);

  useEffect(() => {
    getOpportunities()
      .then((res) => {
        const tempResData = res.data.map((item) => new OpportunitiesData(item));
        setAccordionData(tempResData);
      })
      .catch((e) => {
        toast.error(t('opportunitiesError'));
        throw e;
      });
  }, [locale]);

  /**
   * Toggle accordion items.
   */
  const toggleAccordionItems = (id) => {
    setAccordionOpenId(id);
  };

  return (
    <div className="opportunity container">
      <div className="opportunity__title">
        <MainTitle type="dark" className="text--2xl">
          <span>{t('manaraCurrent')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('opportunities')}
          </span>
        </MainTitle>
      </div>

      <div className="opportunity__container">
        {accordionData?.map((item) => (
          <OpportunitiesItem
            {...item}
            id={item.id}
            key={item.id}
            toggleAccordionItems={() => toggleAccordionItems(item.id)}
            accordionOpenId={accordionOpenId}
          />
        ))}
      </div>

      <Icon name="spiral-arrow" className="spiral-arrow-icon" />
    </div>
  );
}
