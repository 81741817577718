export function formatDate(date, locale) {
  const newDate = new Date(date);

  return newDate.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
}

export function formatDateShort(date, locale) {
  const newDate = new Date(date);

  return newDate.toLocaleDateString(locale, {
    month: 'short',
    year: 'numeric'
  });
}

export function formatDateMonth(date, locale) {
  const newDate = new Date(date);

  return newDate.toLocaleDateString(locale, {
    month: 'short'
  });
}

export function formatDateMonthAndDay(date, locale) {
  const newDate = new Date(date);

  return newDate.toLocaleDateString(locale, {
    month: 'short',
    day: 'numeric'
  });
}
