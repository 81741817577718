import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import LocaleContext from '../../../contexts/LocaleContext';

import MainTitle from '../../common/MainTitle';
import SliderNavigation from '../../common/SliderNavigation';
import Slide from './Slide';

export default function BoardOfTrustees({ slides }) {
  const [allowPrev, setAllowPrev] = useState(true);
  const [allowNext, setAllowNext] = useState(true);
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['AboutPage']);
  const [modalOpenIndex, setModalOpenIndex] = useState(null);

  function openModal(i) {
    setModalOpenIndex(i);
    document.body.classList.add('overflow-hidden');
  }
  function closeModal(event) {
    event.stopPropagation();
    setModalOpenIndex(null);
    document.body.classList.remove('overflow-hidden');
  }

  /**
   * Updates the slider status on init and on change.
   * @param {SwiperProps} swiper
   */
  function updateSliderStatus(swiper) {
    setAllowNext(!swiper.isEnd);
    setAllowPrev(!swiper.isBeginning);
  }

  const SwiperSlidesJSX = slides?.map((slide, idx) => (
    <SwiperSlide key={`college-pre__slide-${slide?.id}`}>
      <Slide
        allowPrev={allowPrev}
        allowNext={allowNext}
        name={slide?.name}
        jobTitle={slide?.jobTitle}
        biography={slide?.biography}
        image={slide?.image}
        index={idx}
        openModal={() => openModal(idx)}
        closeModal={(e) => closeModal(e)}
        modalOpenIndex={modalOpenIndex}
      />
    </SwiperSlide>
  ));

  return (
    <section className="board-of-trustees">
      <div className="board-of-trustees__slider-wrapper">
        <header className="board-of-trustees__header">
          <MainTitle type="dark" className="text--2xl">
            <span>{t('meetOurBoard')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('ofTrustees')}
            </span>
          </MainTitle>

          <p>{t('boardOfTrusteesContent')}</p>
        </header>

        <Swiper
          className="board-of-trustees__swiper"
          dir={locale === 'en' ? 'ltr' : 'rtl'}
          slidesPerView={1}
          breakpoints={{
            320: { slidesPerView: 1.3, spaceBetween: 26 },
            600: { slidesPerView: 1.8, spaceBetween: 80 },
            1200: { slidesPerView: 1.4, spaceBetween: 100 },
            1366: { slidesPerView: 1.4, spaceBetween: 80 },
            1920: { slidesPerView: 1.5 }
          }}
          loop
          centeredSlides
          onSlideChange={(swiper) => updateSliderStatus(swiper)}
          onSwiper={(swiper) => updateSliderStatus(swiper)}
          modules={[Navigation]}>
          {SwiperSlidesJSX}

          <SliderNavigation
            allowPrev={allowPrev}
            allowNext={allowNext}
            className="board-of-trustees__nav-buttons"
          />
        </Swiper>
      </div>
    </section>
  );
}
