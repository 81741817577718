{
  "pageTitle": "تبرع الآن",
  "weBelieve": "نؤمن بأن",
  "everyTalented": "كل طالب موهوب",
  "anOpportunity": "يستحق الحصول على فرصة",
  "foundationsTitle1": "تقدم لنا الدعم",
  "foundationsTitle2": "هيئات دولية",
  "foundationsTitle3": "غير هادفة للربح",
  "foundationsDescription1": "نوفر للهيئات والشخصيات المانحة فرصة تغيير حياة الأطفال الذين يُظهرون إمكانات أكاديمية وقيادية كبيرة من خلال منحهم إمكانية الحصول على تعليم ذو مستوى عالمي.",
  "foundationsDescription2": "الجهات المانحة للمنارة يمكنهم المساعدة في تحقيق تكافؤ الفرص للأطفال الأقل حظًا في مصر من خلال توفير فرصة الالتحاق بالبرنامج التحضيري للجامعة الذي سيؤهل الطلبة للنجاح في الدراسة الجامعية وما بعدها.",
  "donateNow": "تبرع الآن",
  "donationsError": "فشل في الحصول على بيانات التبرعات",
  "ourMissionTitle1": "تعزيز القدرات القيادية لدى الطلاب لصنع",
  "ourMissionTitle2": "قادة مجتمعنا المستقبليين",
  "ourMissionLink": "نبذة عن رسالتنا",
  "academicStructureTitle1": "نقدم منهج دراسي دولي يعد الطلاب للالتحاق",
  "academicStructureTitle2": "بالجامعة ويتضمن مساحة للاستكشاف",
  "academicStructureLink": "الهيكل الأكاديمي",
  "donateConfirm": "تبرع",
  "donationAmount": "المبلغ",
  "donationAmountRequired":"المبلغ مطلوب"
}
