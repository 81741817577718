import ImageData from './Image';
import { getImagesData } from '../js/utils/Utils';

/* eslint-disable camelcase */
class HomeData {
  constructor({
    attributes: {
      SEO_meta_tags,
      hero_image,
      hero_text_line_1,
      hero_text_line_2,
      hero_text_line_3,
      hero_text_subtitle,
      section_2_side_image,
      section_2_text_line_1,
      section_2_text_line_2,
      section_2_text_line_3,
      section_2_text_paragraph_1,
      section_2_text_paragraph_2,
      amenity_slide,
      college_prep_slider
    }
  }) {
    this.heroSection = {
      title1: hero_text_line_1,
      title2: hero_text_line_2,
      title3: hero_text_line_3,
      subtitle: hero_text_subtitle,
      image: getImagesData(hero_image)
    };
    this.maximizingLeadership = {
      title1: section_2_text_line_1,
      title2: section_2_text_line_2,
      title3: section_2_text_line_3,
      image: new ImageData(section_2_side_image?.data),
      text1: section_2_text_paragraph_1,
      text2: section_2_text_paragraph_2
    };
    const items = amenity_slide.map((item) => {
      const {
        id,
        slide_main_title,
        slide_center_image,
        slide_title_line_1,
        slide_title_line_2,
        slide_right_paragraph,
        slide_right_image,
        slide_left_paragraph,
        slide_left_image
      } = item;

      return {
        id,
        mainTitle: slide_main_title,
        title1: slide_title_line_1,
        title2: slide_title_line_2,
        centerImage: new ImageData(slide_center_image?.data),
        startText: slide_left_paragraph,
        startImage: new ImageData(slide_left_image?.data),
        endText: slide_right_paragraph,
        endImage: new ImageData(slide_right_image?.data)
      };
    });
    this.accordionData = items;
    const prepSliderItems = college_prep_slider.map((prepSliderItem) => {
      const { id, slide_image, slide_text } = prepSliderItem;
      return {
        id,
        image: new ImageData(slide_image?.data),
        text: slide_text
      };
    });
    this.prepSliderItems = prepSliderItems;
    this.metaTags = SEO_meta_tags?.map(
      ({ id, meta_tag_name: name, meta_tag_content: content }) => ({
        id,
        name,
        content
      })
    );
  }
}

export default HomeData;
