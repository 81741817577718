import ImageLink from '../../js/utils/ImageLink';
import BioPopUp from '../common/BioPopUp';

export default function TeamCard({
  name,
  jobTitle,
  image,
  bio,
  index,
  openModal,
  modalOpenIndex,
  closeModal
}) {
  return (
    <section className="team__card" onClick={(e) => openModal(index, e)} role="presentation">
      {modalOpenIndex === index && (
        <BioPopUp data={{ name, jobTitle, image, bio }} closeModal={(e) => closeModal(e)} />
      )}
      <div className="team__card-media">
        <img src={ImageLink(image.url)} alt={image.alt} />
      </div>
      <div className="team__card-content">
        <p className="name">{name}</p>
        <p>{jobTitle}</p>
      </div>
    </section>
  );
}
