import { Link } from 'react-router-dom';

/**
 *
 * @param {{theme: 'light' | 'dark', isSubmitButton: boolean, disabled: boolean}} props
 * @returns
 */

export default function AppButton({
  children,
  theme = 'dark',
  isSubmitButton = false,
  className = '',
  disabled,
  handleClick,
  tag = 'button',
  linkPath = ''
}) {
  return (
    <>
      {tag === 'button' && (
        <button
          type={isSubmitButton ? 'submit' : 'button'}
          disabled={disabled}
          className={`button app-button app-button--${theme} ${className}`}
          onClick={handleClick}>
          <span />
          <span>{children}</span>
        </button>
      )}

      {tag === 'a' && (
        <Link to={linkPath} className={`button app-button app-button--${theme} ${className}`}>
          <span />
          <span>{children}</span>
        </Link>
      )}
    </>
  );
}
