import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';

import ManaraStudentsJpg from '../../assets/images/contact-us/connect-with-manara-right.jpeg';
import ManaraStudentsWebp from '../../assets/images/contact-us/connect-with-manara-right.webp';
import ManaraBuildingJpg from '../../assets/images/contact-us/manara-school-building.jpg';
import ManaraBuildingWebp from '../../assets/images/contact-us/manara-school-building.webp';

export default function IntroSection({ data }) {
  return (
    <section className="intro-section container">
      <div className="intro-section__secondary-media">
        <picture>
          <source srcSet={ManaraBuildingWebp} type="image/webp" />
          <img src={ManaraBuildingJpg} alt="manara-school-building" />
        </picture>
      </div>

      <div className="intro-section__content">
        <MainTitle type="dark" className="text--2xl hidden--lg">
          <span>{data.title1}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {data.title2}
          </span>
        </MainTitle>

        <div className="intro-section__content-container">
          <MainTitle type="dark" className="text--2xl hidden--md">
            <span>{data.title1}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {data.title2}
            </span>
          </MainTitle>
          <p>{data.description}</p>
        </div>

        <div className="intro-section__main-media">
          <picture>
            <source srcSet={ManaraStudentsWebp} type="image/webp" />
            <img src={ManaraStudentsJpg} alt="manara-students" />
          </picture>
        </div>
      </div>
      <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />
    </section>
  );
}
