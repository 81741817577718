import { ENDPOINTS } from './endpoints';
import { fetchData } from './network';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';

export function getContactInfo() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getContactInfo.path}`);
  bindLocaleIntoHeaders(url);

  return fetchData(url, {
    method: ENDPOINTS.getContactInfo.method
  }).then((response) => response);
}
