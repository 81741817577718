/* eslint-disable import/prefer-default-export */
import { ENDPOINTS } from './endpoints';
import { fetchData } from './network';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';

export function getAdmissionsPage() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getAdmissionsPage.path}`);

  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate[application_timeline][populate]', '*');
  url.searchParams.append('populate[apply_steps][populate]', '*');
  url.searchParams.append('populate[apply_rounds][populate]', '*');

  return fetchData(url, {
    method: ENDPOINTS.getAdmissionsPage.method
  }).then((response) => response);
}
