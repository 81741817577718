import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import LocaleContext from '../../contexts/LocaleContext';
import MainTitle from '../common/MainTitle';
import AppButton from '../common/AppButton';
import StudentGirlJpg from '../../assets/images/admissions/support-and-inquiries-media.jpg';
import StudentGirlWebp from '../../assets/images/admissions/support-and-inquiries-media.webp';

export default function SupportAndInquiries() {
  const { t } = useTranslation(['AdmissionsPage']);
  const { locale } = useContext(LocaleContext);

  return (
    <section className="support-and-inquiries">
      <MainTitle type="dark" className="text--2xl container hidden--desktop">
        <span>{t('needSupport')}</span>
        <span className="decorated-line decorated-line--dark decorated-line--left">
          {t('haveQuestion')}
        </span>
      </MainTitle>

      <div className="support-and-inquiries__container">
        <div className="support-and-inquiries__content">
          <MainTitle type="dark" className="text--2xl container hidden--mobile">
            <span className="decorated-line decorated-line--dark decorated-line--right">
              {t('needSupport')}
            </span>
            <span>{t('haveQuestion')}</span>
          </MainTitle>

          <div className="support-and-inquiries__description">
            <p className="description">{t('supportDescription')}</p>

            <AppButton tag="a" linkPath={`/${locale}/contact#contact-form`}>
              {t('contactUs')}
            </AppButton>
          </div>
        </div>
        <div className="support-and-inquiries__media">
          <picture>
            <source srcSet={StudentGirlWebp} type="image/webp" />
            <img src={StudentGirlJpg} alt="student holding her notebook and smiling" />
          </picture>
        </div>
      </div>
    </section>
  );
}
