/* eslint-disable import/prefer-default-export */
import { ENDPOINTS } from './endpoints';
import { fetchData } from './network';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';

export function getContactUsPage() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getContactUsPage.path}`);

  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate[explore_the_campus][populate]', '*');

  return fetchData(url, {
    method: ENDPOINTS.getContactUsPage.method
  }).then((response) => response);
}
