import { useTranslation } from 'react-i18next';
import ImageLink from '../../js/utils/ImageLink';

import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';

export default function PortraitOfGraduate({ data }) {
  const { t } = useTranslation(['AcademicsPage']);

  return (
    <section className="portrait-of-graduate">
      <MainTitle type="dark" className="text--2xl container hidden--lg">
        <span>{t('manaraPortrait')}</span>
        <span className="decorated-line decorated-line--dark decorated-line--left">
          {t('ofGraduate')}
        </span>
      </MainTitle>

      <div className="portrait-of-graduate__container">
        <div className="portrait-of-graduate__content">
          <MainTitle type="dark" className="text--2xl container hidden--md">
            <span>{t('manaraPortrait')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('ofGraduate')}
            </span>
          </MainTitle>

          <div className="portrait-of-graduate__description">
            <p className="description">{data?.description}</p>

            {!!data?.downloadFile && (
              <a
                className="portrait-of-graduate__download"
                href={ImageLink(data?.downloadFile.url)}
                target="_blank"
                rel="noopener noreferrer"
                download>
                <Icon name="download" />
                <span>{data?.linkText}</span>
              </a>
            )}
          </div>
        </div>
        <div className="portrait-of-graduate__media">
          <img src={ImageLink(data?.image.url)} alt={data?.image.alt} />
        </div>
      </div>
    </section>
  );
}
