import { useSwiper } from 'swiper/react';
import Icon from './Icon';

export default function SliderNavigation({ className }) {
  const swiper = useSwiper();

  return (
    <div className={`slider-navigation ${className}`}>
      <button
        onClick={() => {
          swiper.slidePrev();
        }}
        type="button"
        className="button slider-navigation__control slider-navigation__control--prev"
        title="previous">
        <Icon name="arrow-left" />
      </button>

      <button
        onClick={() => {
          swiper.slideNext();
        }}
        type="button"
        className="button slider-navigation__control slider-navigation__control--next"
        title="next">
        <Icon name="arrow-right" />
      </button>
    </div>
  );
}
