import ImageData from './Image';

/* eslint-disable camelcase */
class ArticlesData {
  /**
   * @param {{
   *   id: string,
   *   attributes: {
   * article_link:string,
   * author_name:string,
   * date: string,
   * title:string,
   * image: Image
   *   }
   * }}
   */
  constructor(data) {
    if (!data?.attributes) {
      return;
    }

    const {
      id,
      attributes: { article_link, author_name, date, title, image }
    } = data;

    this.id = id;
    this.url = article_link;
    this.title = title;
    this.author = author_name;
    this.date = date;
    this.image = new ImageData(image?.data);
  }
}

export default ArticlesData;
