import ImageData from './Image';

/* eslint-disable camelcase */
class AcademicsData {
  constructor({
    attributes: {
      SEO_meta_tags,
      portrait_of_graduate,
      learning_experience,
      scholarly_inquiry,
      course_catalog_title_1,
      course_catalog_title_2,
      academic_calendar_title_1,
      academic_calendar_title_2
    }
  }) {
    this.portraitOfGraduate = {
      id: portrait_of_graduate?.id,
      description: portrait_of_graduate?.description,
      image: new ImageData(portrait_of_graduate?.image?.data),
      linkText: portrait_of_graduate?.portrait_of_graduate_text,
      downloadFile: new ImageData(portrait_of_graduate?.portrait_of_graduate?.data)
    };

    this.learningExperience = {
      id: learning_experience?.id,
      description: learning_experience?.description,
      image: new ImageData(learning_experience?.image?.data)
    };

    this.scholarlyInquiry = {
      id: scholarly_inquiry?.id,
      description1: scholarly_inquiry?.description_1,
      description2: scholarly_inquiry?.description_2,
      image: new ImageData(scholarly_inquiry?.image?.data)
    };

    this.courseCatalogTitles = {
      title1: course_catalog_title_1,
      title2: course_catalog_title_2
    };

    this.academicCalendarTitles = {
      title1: academic_calendar_title_1,
      title2: academic_calendar_title_2
    };

    this.metaTags = SEO_meta_tags?.map(
      ({ id, meta_tag_name: name, meta_tag_content: content }) => ({
        id,
        name,
        content
      })
    );
  }
}

export default AcademicsData;
