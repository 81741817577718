{
  "pageTitle": "Campus And Student Life",
  "campus": "Campus and",
  "studentLife": "Student Life",
  "heroSectionDescription": "We take care of all aspects of student life beyond the classroom, from health and safety to spiritual practice. We provide our students with excellent housing, athletic and co-curricular facilities",
  "elgouna": "El Gouna, A Modern",
  "redSea": "Red Sea Town",
  "elgounaDescription": "The Manara Campus will initially be located at the former TU Berlin campus in the beautiful seaside town of El Gouna, Red Sea. Five hours south of Cairo and 30 minutes north of Hurghada, El Gouna is located on the Red Sea coast and consists of 20 connected islands surrounded by lagoons and canals. Deriving its name from the ancient Egyptian words for “protected,” El Gouna is an ideal location for our school.",
  "community": "Community",
  "theResidential": "The Residential",
  "experience": "Experience",
  "student": "Student Life",
  "atManara": "at Manara",
  "studentLifeDescription1": "Manara is not just a school, it is a second home for all its students. Student life beyond",
  "studentLifeDescription2": "the classroom is an integral part of the Manara School experience.",
  "explore": "Explore",
  "theCampus": "The Campus",
  "coCurricular": "Co-Curricular",
  "programs": "Programs",
  "coCurricularDescription": "Learning at Manara School happens beyond the classroom as well.",
  "constructionUpdates": "Construction updates on student",
  "dormitories": "dormitories & dining hall",
  "facilities": "Facilities,",
  "housing": "Housing and",
  "accommodation": "accommodation"
}
