import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import FooterNav from './FooterNav';
// import SubscribeSection from '../common/SubscribeSection';
import LocaleContext from '../../contexts/LocaleContext';
import ContactInfo from '../common/ContactInfo';

export default function Footer() {
  const { t } = useTranslation(['HomePage']);
  const { locale } = useContext(LocaleContext);

  return (
    <footer className="footer">
      {window.location.pathname !== `/${locale}/admission-campaign` && <FooterNav />}

      {/* TODO: Enable the section when E-mail subscription service is ready */}
      {/* <SubscribeSection className="container">
        <div className="subscribe-content">
          <h3>{t('stayInTouch')}</h3>
          <p>{t('subscribeToNewsletter')}</p>
        </div>
      </SubscribeSection> */}

      <ContactInfo theme="dark" className="container" />

      <p className="copyrights container">{t('copyrights', { date: new Date().getFullYear() })}</p>
    </footer>
  );
}
