{
  "pageTitle": "About Us",
  "heroTitle1": "We Are Shaping",
  "heroTitle2": "Learners Who",
  "heroTitle3": "Inspire The World",
  "theStory": "The Story Behind",
  "manaraSchool": "Manara School",
  "leadershipTitle1": "Committed To",
  "leadershipTitle2": "Maximizing",
  "leadershipTitle3": "Leadership Potential",
  "leadershipText1": "We believe that living on campus is an essential part of the learning experience and provides students with a supportive community that fosters personal growth and independence. Our residential program is designed to create a home away from home for our exceptional students, where they can thrive academically, socially, and emotionally.",
  "leadershipText2": "Our residential program offers a range of benefits, including a safe and secure living environment, daily opportunities for academic support, access to extracurricular activities, and a chance to build lifelong friendships. Students will have the opportunity to live with like-minded individuals who share their passion for learning, their drive for success, and their desire to make a positive impact on the world.",
  "leadershipButton": "Academic Programs",
  "inclusivityTitle1": "How Inclusivity",
  "inclusivityTitle2": "Is Part of Our",
  "inclusivityTitle3": "Values & Mission",
  "inclusivityText": "At Manara School, we are committed to creating a welcoming and inclusive community where all students can thrive. We strongly believe that every student deserves access to a high-quality education and we work tirelessly to ensure that our values of inclusivity and equity are integrated into every aspect of our school's mission.",
  "inclusivityButton": "scholarship program",
  "campusAnd": "Campus And",
  "studentLife": "Student Life",
  "teamTitle1": "Meet The Manara",
  "teamTitle2": "Team",
  "teamDescription": "Our diverse and experienced staff at Manara are committed to providing students with the support and guidance they need to succeed in all aspects of their lives. With years of expertise and a strong background in residential schools, our staff prioritizes the safety and wellness of students.",
  "meetOurBoard": "Meet Our Board",
  "ofTrustees": "Of Trustees",
  "boardOfTrusteesContent": "Manara School is governed by the Board of Trustees, which oversees the school’s institutional affairs, governance and finances, including fundraising, but delegates responsibility for the day-to-day administration, programming, and policies to the Head of School.",
  "readMore": "Read more"
}
