import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import MetaTags from '../components/common/MetaTags';
import HeroSection from '../components/News/HeroSection';
import ArticleList from '../components/News/ArticleList';

import scrollToTarget from '../js/utils/scroll';

export default function NewsPage() {
  const { t } = useTranslation(['NewsPage']);

  // Scroll to the top after the page is loaded
  // This is mainly added to fix page view after redirect, the user views the page bottom onload
  useEffect(() => {
    const element = document?.getElementById('news-page');
    scrollToTarget(element);
  }, []);

  return (
    <main className="news-page" id="news-page">
      <MetaTags title={t('pageTitle')} />
      <HeroSection />
      <ArticleList />
    </main>
  );
}
