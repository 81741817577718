import { ENDPOINTS } from './endpoints';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';
import { fetchData } from './network';

export function getAcademicCalendar() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getAcademicCalendar.path}`);

  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate', '*');

  return fetchData(url, {
    method: ENDPOINTS.getAcademicCalendar.method
  }).then((response) => response);
}
