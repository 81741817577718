import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import LocaleContext from '../contexts/LocaleContext';
import MetaTags from '../components/common/MetaTags';
import MainTitle from '../components/common/MainTitle';
import Icon from '../components/common/Icon';

import { getThankYouPage } from '../js/api/thank-you-page';
import ThankYouPageData from '../models/ThankYouPageData';
import ImageLink from '../js/utils/ImageLink';

export default function ThankYouPage() {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['HomePage']);
  const [thankYouPageData, setThankYouPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Handle fetching thank you page data.
  useEffect(() => {
    setIsLoading(true);
    getThankYouPage()
      .then((res) => {
        setThankYouPageData(new ThankYouPageData(res.data));
      })
      .catch((e) => {
        toast.error('An error occurred, try again later');
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [locale]);

  return (
    <main className="thank-you-page" id="thank-you-page">
      {!isLoading && thankYouPageData ? (
        <>
          <MetaTags title={t('pageTitle')} />

          <section className="hero-section container">
            <MainTitle type="dark" className="text--2xl hidden--lg">
              <span>{thankYouPageData?.heroSection?.title1}</span>
              <span className="decorated-line decorated-line--dark decorated-line--left">
                {thankYouPageData?.heroSection?.title2}
              </span>
              <span>{thankYouPageData?.heroSection?.title3}</span>
            </MainTitle>

            <div className="hero-section__media">
              <div className="hero-section__main-media">
                <img
                  src={ImageLink(thankYouPageData?.heroSection?.imageLeft?.url)}
                  alt={thankYouPageData?.heroSection?.imageLeft.alt || ''}
                />
              </div>

              <div className="hero-section__secondary-media hidden--lg">
                <img
                  src={ImageLink(thankYouPageData?.heroSection?.imageRight?.url)}
                  alt={thankYouPageData?.heroSection?.imageRight.alt || ''}
                />
              </div>
            </div>

            <div className="hero-section__content">
              <div className="hero-section__content-container">
                <MainTitle type="dark" className="text--2xl hidden--md">
                  <span>{thankYouPageData?.heroSection?.title1}</span>
                  <span className="decorated-line decorated-line--dark decorated-line--left">
                    {thankYouPageData?.heroSection?.title2}
                  </span>
                  <span>{thankYouPageData?.heroSection?.title3}</span>
                </MainTitle>
                <p>{thankYouPageData?.heroSection?.description}</p>

                <a
                  href="https://manaraschool.heiapply.com/application"
                  target="_blank"
                  className="button apply-now"
                  title="apply now"
                  rel="noreferrer">
                  <Icon name="open-dark" />
                  <span>{t('applyNow')}</span>
                </a>
              </div>
            </div>

            <div className="hero-section__secondary-media hidden--md">
              <img
                src={ImageLink(thankYouPageData?.heroSection?.imageRight?.url)}
                alt={thankYouPageData?.heroSection?.imageRight.alt || ''}
              />
            </div>
            <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />
          </section>
        </>
      ) : null}
    </main>
  );
}
