{
  "pageTitle": "الجوانب الأكاديمية",
  "leadershipFocused": "تعليم ذو مستوى عالمي",
  "WorldClassEducation": "يركز على القيادة",
  "heroSectionDescription": "طلابنا في منارة ليسوا مجرد قادة، بل هم مُغيرون لقواعد اللعبة. بدءًا من المشاركة الفعّالة في الفصل ووصولاً إلى الأنشطة الخارجية، والخدمة المجتمعية، وبرنامج الوظائف الفريد لدينا، يتولى كل طالب المسؤولية، ويساهم بطرق بناءة في المدرسة، والمجتمع، والوطن. لتيسير نموهم، نقدم ندوة قائمة على المشاريع العملية تؤكد على القيادة الأخلاقية لكل طالب جديد. تم تصميم مناهجنا الدراسية خصيصًا لصقل المهارات التنفيذية، وصفات الشخصية الأساسية التي تعتبر ضرورية للمواطنة الفعالة، ويقوم أعضاء هيئة التدريس لدينا عمدًا بتوجيه طلابنا إلى التفوق ولأن يصبحوا قادة الغد.",
  "manaraPortrait": "بورتريه",
  "ofGraduate": "الخريج",
  "whatDoesOur": "ماذا تقدم",
  "studentCentered": "ماذا تقدم مناهجنا المتمركزة",
  "curriculum": "حول الطالب؟",
  "pursueYourPassionTitle1": "اكتشف",
  "pursueYourPassionTitle2": "شغفك مع",
  "pursueYourPassionTitle3": "X-Term",
  "coursesCatalogError": "فشل في الحصول على كتالوج الدورات التدريبية",
  "quarter": "الربع",
  "upcomingEvents": "الأحداث القادمة",
  "pastEvents": "الأحداث الماضية"
}
