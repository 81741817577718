import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageLink from '../../../js/utils/ImageLink';
import BioPopUp from '../../common/BioPopUp';

export default function Slide({
  index,
  image,
  name,
  jobTitle,
  biography,
  openModal,
  modalOpenIndex,
  closeModal
}) {
  const [isMobile, setIsMobile] = useState(false);
  const { t } = useTranslation(['AboutPage']);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isMobile]);

  return (
    <div className="board-of-trustees__slide" key={index}>
      {modalOpenIndex === index && (
        <BioPopUp
          data={{ name, jobTitle, image, bio: biography }}
          closeModal={(e) => closeModal(e)}
        />
      )}
      <div className="slide__content">
        <h3>{name}</h3>
        <p>{jobTitle}</p>
        <p
          className={`truncate-multiline ${
            isMobile ? 'truncate-multiline--3' : 'truncate-multiline--2'
          }`}>
          {biography}
        </p>

        <button type="button" className="button read-more" onClick={(e) => openModal(index, e)}>
          {t('readMore')}
        </button>
      </div>

      <div className="slide__media">
        <img src={ImageLink(image.url)} alt={image.alt} />
        <div className="backdrop" />
      </div>
    </div>
  );
}
