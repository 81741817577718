export const schoolOptionsEn = [
  {
    id: 0,
    value: '',
    label: 'Select an answer'
  },
  {
    id: 1,
    value: 'Public/Government',
    label: 'Public/Government (Studying in Arabic)'
  },
  {
    id: 2,
    value: 'Private/Egyptian',
    label: 'Private/Egyptian National System (Studying in a foreign language)'
  },
  {
    id: 3,
    value: 'International',
    label: 'International (IGCSE, American, BAC, AP,...etc.)'
  }
];

export const schoolOptionsAr = [
  {
    id: 0,
    value: '',
    label: 'اختر إجابة'
  },
  {
    id: 1,
    value: 'Public/Government',
    label: 'حكومي (عربي)'
  },
  {
    id: 2,
    value: 'Private/Egyptian',
    label: 'خاص لغات (ناشونال/النظام المصري)'
  },
  {
    id: 3,
    value: 'International',
    label: 'دولي (النظام البريطاني/امريكي/BAC...)'
  }
];

export const hearAboutUsOptionsEn = [
  {
    id: 0,
    value: '',
    label: 'Select an answer'
  },
  {
    id: 1,
    value: 'Facebook',
    label: 'Facebook'
  },
  {
    id: 2,
    value: 'Instagram',
    label: 'Instagram'
  },
  {
    id: 3,
    value: 'LinkedIn',
    label: 'LinkedIn'
  },
  {
    id: 4,
    value: 'TikTok',
    label: 'TikTok'
  },
  {
    id: 5,
    value: 'Google',
    label: 'Google'
  },
  {
    id: 6,
    value: 'YouTube',
    label: 'YouTube'
  },
  {
    id: 7,
    value: 'Referred by a friend',
    label: 'Referred by a friend'
  },
  {
    id: 8,
    value: 'Manara Newsletter',
    label: 'Manara Newsletter'
  },
  {
    id: 9,
    value: 'Event',
    label: 'Event'
  },
  {
    id: 10,
    value: 'News',
    label: 'News'
  }
];

export const hearAboutUsOptionsAr = [
  {
    id: 0,
    value: '',
    label: 'اختر إجابة'
  },
  {
    id: 1,
    value: 'Facebook',
    label: 'فيسبوك'
  },
  {
    id: 2,
    value: 'Instagram',
    label: 'انستجرام'
  },
  {
    id: 3,
    value: 'LinkedIn',
    label: 'لينكد إن'
  },
  {
    id: 4,
    value: 'TikTok',
    label: 'تيك توك'
  },
  {
    id: 5,
    value: 'Google',
    label: 'جوجل'
  },
  {
    id: 6,
    value: 'YouTube',
    label: 'يوتيوب'
  },
  {
    id: 7,
    value: 'Referred by a friend',
    label: 'أشار من قبل صديق'
  },
  {
    id: 8,
    value: 'Manara Newsletter',
    label: 'نشرة منارة الاخباريه'
  },
  {
    id: 9,
    value: 'Event',
    label: 'حدث'
  },
  {
    id: 10,
    value: 'News',
    label: 'الاخبار'
  }
];

export const lookingForOptionsEn = [
  {
    id: 0,
    value: '',
    label: 'Select an answer'
  },
  {
    id: 1,
    value: 'Admission requirements',
    label: 'Admission requirements'
  },
  {
    id: 2,
    value: 'Tuition fees',
    label: 'Tuition fees'
  },
  {
    id: 3,
    value: 'Athletic program',
    label: 'Athletic program'
  },
  {
    id: 4,
    value: 'Residential and co-curricular',
    label: 'Residential and co-curricular'
  },
  {
    id: 5,
    value: 'Job vacancies',
    label: 'Job vacancies'
  }
];

export const lookingForOptionsAr = [
  {
    id: 0,
    value: '',
    label: 'اختر إجابة'
  },
  {
    id: 1,
    value: 'Admission requirements',
    label: 'متطلبات القبول'
  },
  {
    id: 2,
    value: 'Tuition fees',
    label: 'رسوم دراسية'
  },
  {
    id: 3,
    value: 'Athletic program',
    label: 'برنامج رياضي'
  },
  {
    id: 4,
    value: 'Residential and co-curricular',
    label: 'برنامج سكني (مهاجع وقاعة طعام)'
  },
  {
    id: 5,
    value: 'Job vacancies',
    label: 'وظائف شاغرة'
  }
];

export const gradeOptionsEn = [
  {
    id: 0,
    value: '',
    label: 'Select an answer'
  },
  {
    id: 1,
    value: 'PreK/Nursery',
    label: 'PreK/Nursery'
  },
  {
    id: 2,
    value: 'KG1',
    label: 'KG1'
  },
  {
    id: 3,
    value: 'KG2',
    label: 'KG2'
  },
  {
    id: 4,
    value: 'G1',
    label: 'G1'
  },
  {
    id: 5,
    value: 'G2',
    label: 'G2'
  },
  {
    id: 6,
    value: 'G3',
    label: 'G3'
  },
  {
    id: 7,
    value: 'G4',
    label: 'G4'
  },
  {
    id: 8,
    value: 'G5',
    label: 'G5'
  },
  {
    id: 9,
    value: 'G6',
    label: 'G6'
  },
  {
    id: 10,
    value: 'G7',
    label: 'G7'
  },
  {
    id: 11,
    value: 'G8',
    label: 'G8'
  },
  {
    id: 12,
    value: 'G9',
    label: 'G9'
  },
  {
    id: 13,
    value: 'G10',
    label: 'G10'
  },
  {
    id: 14,
    value: 'G11',
    label: 'G11'
  },
  {
    id: 15,
    value: 'G12',
    label: 'G12'
  }
];

export const gradeOptionsAr = [
  {
    id: 0,
    value: '',
    label: 'اختر إجابة'
  },
  {
    id: 1,
    value: 'PreK/Nursery',
    label: 'الحضانة'
  },
  {
    id: 2,
    value: 'KG1',
    label: 'KG1'
  },
  {
    id: 3,
    value: 'KG2',
    label: 'KG2'
  },
  {
    id: 4,
    value: 'G1',
    label: 'G1'
  },
  {
    id: 5,
    value: 'G2',
    label: 'G2'
  },
  {
    id: 6,
    value: 'G3',
    label: 'G3'
  },
  {
    id: 7,
    value: 'G4',
    label: 'G4'
  },
  {
    id: 8,
    value: 'G5',
    label: 'G5'
  },
  {
    id: 9,
    value: 'G6',
    label: 'G6'
  },
  {
    id: 10,
    value: 'G7',
    label: 'G7'
  },
  {
    id: 11,
    value: 'G8',
    label: 'G8'
  },
  {
    id: 12,
    value: 'G9',
    label: 'G9'
  },
  {
    id: 13,
    value: 'G10',
    label: 'G10'
  },
  {
    id: 14,
    value: 'G11',
    label: 'G11'
  },
  {
    id: 15,
    value: 'G12',
    label: 'G12'
  }
];
