import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import LocaleContext from '../../../contexts/LocaleContext';
import { getCoursesCatalog } from '../../../js/api/courses-catalog';

import SliderNavigation from '../../common/SliderNavigation';
import MainTitle from '../../common/MainTitle';
import CourseCard from './CourseCard';

export default function CoursesCatalog({ titles }) {
  const { t } = useTranslation(['AcademicsPage']);
  const { locale } = useContext(LocaleContext);
  const [coursesCatalog, setCoursesCatalog] = useState(null);
  const [allowPrev, setAllowPrev] = useState(true);
  const [allowNext, setAllowNext] = useState(true);
  const [modalOpenIndex, setModalOpenIndex] = useState(null);
  /**
   * Handle fetching courses catalog data.
   */
  useEffect(() => {
    getCoursesCatalog()
      .then((res) => {
        setCoursesCatalog(res?.data.map((course) => ({ id: course.id, ...course.attributes })));
      })
      .catch((e) => {
        toast.error(t('coursesCatalogError'));
        throw e;
      });
  }, [locale]);

  /**
   * Updates the slider status on init and on change.
   * @param {SwiperProps} swiper
   */
  function updateSliderStatus(swiper) {
    setAllowNext(!swiper.isEnd);
    setAllowPrev(!swiper.isBeginning);
  }

  function openModal(i) {
    setModalOpenIndex(i);
    document.body.classList.add('overflow-hidden');
  }

  function closeModal(event) {
    event.stopPropagation();
    setModalOpenIndex(null);
    document.body.classList.remove('overflow-hidden');
  }

  return (
    <section className="courses-catalog">
      <MainTitle type="dark" className="text--2xl">
        <span>{titles?.title1}</span>
        <span className="decorated-line decorated-line--dark decorated-line--left">
          {titles?.title2}
        </span>
      </MainTitle>
      <Swiper
        className="courses-catalog__swiper"
        dir={locale === 'en' ? 'ltr' : 'rtl'}
        slidesPerView={1.1}
        spaceBetween={10}
        breakpoints={{
          400: { slidesPerView: 1.2, spaceBetween: 30 },
          640: { slidesPerView: 2.5 },
          1280: { slidesPerView: 4, spaceBetween: 40 },
          1920: { slidesPerView: 5 }
        }}
        loop
        onSlideChange={(swiper) => updateSliderStatus(swiper)}
        onSwiper={(swiper) => updateSliderStatus(swiper)}
        modules={[Navigation]}>
        {coursesCatalog?.map((course, idx) => (
          <SwiperSlide key={`course-card__slide-${course?.id}`}>
            <CourseCard
              course={course}
              key={course?.id}
              index={idx}
              openModal={() => openModal(idx)}
              closeModal={(e) => closeModal(e)}
              modalOpenIndex={modalOpenIndex}
            />
          </SwiperSlide>
        ))}

        <SliderNavigation
          allowPrev={allowPrev}
          allowNext={allowNext}
          className="courses-catalog__nav-buttons"
        />
      </Swiper>
    </section>
  );
}
