import { useTranslation } from 'react-i18next';
import MainTitle from '../common/MainTitle';

import CareerIntroStart from '../../assets/images/careers/careers-top-left.jpeg';
import CareerIntroStartWebp from '../../assets/images/careers/careers-top-left.webp';
import CareerIntroEnd from '../../assets/images/careers/careers-top-right.jpeg';
import CareerIntroEndWebp from '../../assets/images/careers/careers-top-right.webp';

export default function HeroSection() {
  const { t } = useTranslation(['CareersPage']);

  return (
    <section className="careers-hero-section container">
      <div className="careers-hero-section__content">
        <MainTitle type="dark" className="text--2xl hidden--lg">
          <span>{t('heroTitle1')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('heroTitle2')}
          </span>
          <span>{t('heroTitle3')}</span>
        </MainTitle>

        <div className="careers-hero-section__main-media hidden--md">
          <picture>
            <source srcSet={CareerIntroStartWebp} type="image/webp" />
            <img src={CareerIntroStart} alt="manara-students" />
          </picture>
        </div>

        <div className="careers-hero-section__content-container">
          <MainTitle type="dark" className="text--2xl hidden--md">
            <span>{t('heroTitle1')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('heroTitle2')}
            </span>
            <span>{t('heroTitle3')}</span>
          </MainTitle>

          <p>{t('heroDescription')}</p>
        </div>

        <div className="careers-hero-section__media">
          <div className="careers-hero-section__main-media hidden--lg">
            <picture>
              <source srcSet={CareerIntroStartWebp} type="image/webp" />
              <img src={CareerIntroStart} alt="manara-students" />
            </picture>
          </div>

          <div className="careers-hero-section__secondary-media">
            <picture>
              <source srcSet={CareerIntroEndWebp} type="image/webp" />
              <img src={CareerIntroEnd} alt="manara-students" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
}
