import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import LocaleContext from '../../../contexts/LocaleContext';

import MainTitle from '../../common/MainTitle';
import SliderNavigation from '../../common/SliderNavigation';
import Slide from './Slide';

export default function CampusAndCommunitySlider({ slides }) {
  const { t } = useTranslation(['CampusPage']);
  const [allowPrev, setAllowPrev] = useState(true);
  const [allowNext, setAllowNext] = useState(true);
  const { locale } = useContext(LocaleContext);

  /**
   * Updates the slider status on init and on change.
   * @param {SwiperProps} swiper
   */
  function updateSliderStatus(swiper) {
    setAllowNext(!swiper.isEnd);
    setAllowPrev(!swiper.isBeginning);
  }

  const SwiperSlidesJSX = slides?.map((slide) => (
    <SwiperSlide key={`campus-and-community__slide-${slide?.id}`}>
      <Slide
        allowPrev={allowPrev}
        allowNext={allowNext}
        description={slide?.description}
        image={slide?.image}
        id={slide?.id}
      />
    </SwiperSlide>
  ));

  return (
    <section className="campus-and-community">
      <div className="campus-and-community__slider-wrapper">
        <header className="campus-and-community__header">
          <MainTitle type="dark" className="text--2xl">
            <span>{t('campus')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('community')}
            </span>
          </MainTitle>
        </header>

        <Swiper
          className="campus-and-community__swiper"
          dir={locale === 'en' ? 'ltr' : 'rtl'}
          slidesPerView={1}
          breakpoints={{
            320: { slidesPerView: 1.3, spaceBetween: 26 },
            600: { slidesPerView: 2, spaceBetween: 80 },
            1200: { slidesPerView: 1.3, spaceBetween: 80 },
            1920: { slidesPerView: 1.5, spaceBetween: 80 }
          }}
          loop
          centeredSlides
          onSlideChange={(swiper) => updateSliderStatus(swiper)}
          onSwiper={(swiper) => updateSliderStatus(swiper)}
          modules={[Navigation]}>
          {SwiperSlidesJSX}

          <SliderNavigation
            allowPrev={allowPrev}
            allowNext={allowNext}
            className="campus-and-community__nav-buttons"
          />
        </Swiper>
      </div>
    </section>
  );
}
