import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import JoinOurTeam from '../assets/images/careers/join-our-team.jpeg';
import JoinOurTeamWebp from '../assets/images/careers/join-our-team.webp';

import MetaTags from '../components/common/MetaTags';

import LocaleContext from '../contexts/LocaleContext';
import { getCareersPage } from '../js/api/careers-page';
import CareersData from '../models/CareersPageData';
import HeroSection from '../components/Careers/HeroSection';
import CultureEnvironment from '../components/Careers/CultureEnvironment';
import Tabs from '../components/common/Tabs';
import KeyTeamsSlider from '../components/Careers/KeyTeams/KeyTeamsSlider';
import Opportunities from '../components/Careers/OpportunitiesAccordion.jsx/Opportunities';
import PageCTA from '../components/common/PageCTA';
import Icon from '../components/common/Icon';

export default function CareersPage() {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['CareersPage']);
  const [careersPageData, setCareersPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getCareersPage()
      .then((res) => {
        setCareersPageData(new CareersData(res.data));
      })
      .catch((e) => {
        toast.error('An error occurred, try again later');
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [locale]);

  return (
    <main className="careers">
      {!isLoading && careersPageData ? (
        <>
          <MetaTags title={t('pageTitle')} seo={careersPageData?.metaTags} />
          <HeroSection />
          <CultureEnvironment file={careersPageData?.portraitOfEducator} />
          <Tabs
            data={careersPageData?.benefits}
            addButton={false}
            title1={t('benefits')}
            title2={t('andPolicies')}
            className="benefits-tabs"
          />
          <KeyTeamsSlider slides={careersPageData?.keyTeam} />
          <Opportunities />

          <PageCTA
            title1={t('careersCTA1')}
            title2={t('careersCTA2')}
            image={JoinOurTeam}
            imageWebp={JoinOurTeamWebp}
            alt="Join Manara Team">
            <a href={careersPageData?.explore?.link}>
              <Icon name="open-light" />
              {careersPageData?.explore?.text}
            </a>
          </PageCTA>
        </>
      ) : null}
    </main>
  );
}
