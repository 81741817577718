/* eslint-disable import/prefer-default-export */
import { ENDPOINTS } from './endpoints';
import { fetchData } from './network';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';

export function getAboutPage() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getAboutUsPage.path}`);

  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate[story_behind_manara_image]', '*');
  url.searchParams.append('populate[campus_student_slide][populate]', '*');
  url.searchParams.append('populate[Manara_Team_cards][populate]', '*');
  url.searchParams.append('populate[Board_of_Trustees][populate]', '*');

  return fetchData(url, {
    method: ENDPOINTS.getAboutUsPage.method
  }).then((response) => response);
}
