{
  "pageTitle": "وظائف",
  "heroTitle1": "خلق تأثير حقيقي",
  "heroTitle2": "من خلال تنشئة",
  "heroTitle3": "قادة المستقبل",
  "heroDescription": "يمنحك الانضمام إلى فريق مدرسة منارة فرصة لترك بصمتك وإرثك من خلال الإسهام في صنع قادة المستقبل بدعم من الخبراء في هيئة التدريس ذوي الخبرة الواسعة في إدراك إمكانات الطلاب ذوي القدرات الاستثنائية.",
  "cultureTitle1": "ثقافة المدرسة",
  "cultureTitle2": "وبيئة العمل",
  "cultureText": "يضم مُجتمع منارة خبراء شغوفين ذوي خبرة والتزام في العمل مع المراهقين وتأهيلهم. يتميز فريق عمل المدرسة بالنزاهة والتعاون والتعاطف والحرص على الطلاب، ويعمل في إطار من التنوع والإنصاف والاحترام، ويقود بمرونة وإيجابية.",
  "cultureButton": "اقرأ بورتريه مُعلم",
  "benefits": "المزايا المالية والمعنوية",
  "andPolicies": "والسياسات",
  "exploreOur": "تعرف على أهم",
  "keyTeams": "فرق العمل في المدرسة",
  "manaraCurrent": "الفرص المتاحة",
  "opportunities": "حاليًا في مدرسة منارة",
  "opportunitiesError": "فشل في الحصول على بيانات الفرص المتاحة",
  "careersCTA1": "هل ترغب في التقديم؟",
  "careersCTA2": "انضم إلينا"
}
