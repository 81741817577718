import { ENDPOINTS } from './endpoints';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';
import { fetchData } from './network';

export function getApplicationDetails() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getApplicationDetails.path}`);
  bindLocaleIntoHeaders(url);

  return fetchData(url, {
    method: ENDPOINTS.getApplicationDetails.method
  }).then((response) => response);
}
