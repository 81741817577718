import { useTranslation } from 'react-i18next';
import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';

import HeroImageStartJpg from '../../assets/images/news/news-top-left.jpeg';
import HeroImageStartWebp from '../../assets/images/news/news-top-left.webp';
import HeroImageEndJpg from '../../assets/images/news/news-top-right.jpeg';
import HeroImageEndWebp from '../../assets/images/news/news-top-right.webp';

export default function HeroSection() {
  const { t } = useTranslation(['NewsPage']);

  return (
    <section className="news-hero-section container">
      <MainTitle type="dark" className="text--2xl hidden--lg">
        <span>{t('title1')}</span>
        <span className="decorated-line decorated-line--dark decorated-line--left">
          {t('title2')}
        </span>
      </MainTitle>
      <div className="news-hero-section__main-media">
        <picture>
          <source srcSet={HeroImageStartWebp} type="image/webp" />
          <img src={HeroImageStartJpg} alt="manara-students" />
        </picture>
      </div>

      <div className="news-hero-section__content">
        <div className="news-hero-section__content-container">
          <MainTitle type="dark" className="text--2xl hidden--md">
            <span>{t('title1')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('title2')}
            </span>
          </MainTitle>

          <p className="news-hero-section__description">{t('subtitle')}</p>
        </div>

        <div className="news-hero-section__secondary-media">
          <picture>
            <source srcSet={HeroImageEndWebp} type="image/webp" />
            <img src={HeroImageEndJpg} alt="manara-students" />
          </picture>
        </div>
      </div>
      <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />
    </section>
  );
}
