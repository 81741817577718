import { NavLink, useLocation } from 'react-router-dom';

export default function NavItem({ children, url, className = '', title = '', isExternal }) {
  const location = useLocation();

  function isActive() {
    return location.pathname.split('/').pop() === title.toLocaleLowerCase();
  }

  return (
    <li className={`navbar__item ${className}`}>
      {isExternal ? (
        <a href={url} title={title} className={isActive() ? 'active' : ''}>
          {children}
        </a>
      ) : (
        <NavLink to={url} title={title} className={(navData) => (navData.isActive ? 'active' : '')}>
          {children}
        </NavLink>
      )}
    </li>
  );
}
