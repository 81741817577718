{
  "pageTitle": "الحرم المدرسي وحياة الطالب",
  "campus": "الحرم المدرسي",
  "studentLife": "وحياة الطالب",
  "heroSectionDescription": "نحن نعتني بجميع جوانب الحياة الطلابية خارج الفصل الدراسي ، من الصحة والسلامة إلى الممارسة الروحية. نحن نوفر لطلابنا سكنًا ممتازًا ومرافق رياضية ومناهج مشتركة",
  "elgouna": "الجونة مدينة حديثة ",
  "redSea": "على البحر الأحمر",
  "elgounaDescription": "في البداية، سيحتل الحرم المدرسي لمنارة مقر الحرم الجامعي السابق لجامعة TU Berlin في مدينة الجونة الساحلية الجميلة، والتي تقع على بعد خمس ساعات جنوب القاهرة و30 دقيقة شمال الغردقة. تقع الجونة على ساحل البحر الأحمر وتتكون من 20 جزيرة متصلة تحيط بها البحيرات والقنوات. اشتق اسم الجونة من كلمة مصرية قديمة تعني 'محمية'، ونرى فيها موقعًا مثاليًا لمدرستنا.",
  "community": "ومجتمع المدرسة",
  "theResidential": "تجربة الحياة",
  "experience": "في السكن الطلابي",
  "student": "الحياة الطلابية",
  "atManara": "في منارة",
  "studentLifeDescription1": "منارة ليست مجرد مدرسة، بل هي البيت الثاني لجميع طلابها، تعد الحياة الطلابية",
  "studentLifeDescription2": "خارج الفصل الدراسي جزءًا لا يتجزأ من تجربة الحياة في مدرسة منارة.",
  "explore": "استكشف",
  "theCampus": "الحرم الجامعي",
  "coCurricular": "المناهج",
  "programs": "المشتركة",
  "coCurricularDescription": "لا تنحصر عملية التعلم في مدرسة منارة على قاعات الدراسة، بل تحدث خارجها كذلك",
  "constructionUpdates": "آخر تحديثات البناء في المدرسة",
  "dormitories": "مبنى سكن الطلبة وقاعة تناول الطعام",
  "facilities": " المرافق",
  "housing": "السكن الطلابي",
  "accommodation": "وترتيبات إقامة الطلاب"
}
