import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageLink from '../../js/utils/ImageLink';
import Icon from '../common/Icon';

export default function StepsAccordion({ steps }) {
  const { t } = useTranslation(['AdmissionsPage']);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandIndex, setExpandedIndex] = useState(null);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 1280) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [isDesktop]);

  function handleToggling(idx) {
    setIsExpanded(!isExpanded);
    setExpandedIndex(idx);
  }

  return (
    <div className="steps-accordion">
      {steps?.map((step, index) => (
        <div
          className={`step__wrapper  ${
            !isDesktop
              ? 'align--start'
              : isExpanded && expandIndex === index
              ? 'align--start'
              : 'align--center'
          }`}
          key={step.id}>
          <div
            className={`step__content ${
              isExpanded && expandIndex === index ? 'align--start' : 'align--center'
            }`}>
            <div
              className={`step__header  ${
                !isDesktop
                  ? 'align--start'
                  : isExpanded && expandIndex === index
                  ? 'align--start'
                  : 'align--center'
              }`}>
              <Icon name={step?.icon} className="step__icon" />
              <h3 className="step__title">{step?.title}</h3>
            </div>

            <div
              className={`step__description ${
                isExpanded && expandIndex === index
                  ? 'overflow-visible is-open'
                  : 'truncate-multiline truncate-multiline--1 overflow-hidden'
              }`}>
              <p>{step?.description}</p>

              {isExpanded && expandIndex === index && step?.link && (
                <a href={step?.link} target="_blank" rel="noreferrer">
                  <Icon name="download" className="download-icon" />
                  {step?.link && (step?.linkText ? step?.linkText : t('download'))}
                </a>
              )}

              {isExpanded && expandIndex === index && !isDesktop && (
                <div className="media-container">
                  <img src={ImageLink(step.image.url)} alt={step.image.alt} />
                </div>
              )}
            </div>
          </div>

          <div
            className={`step__media ${
              isExpanded && expandIndex === index && !isDesktop ? 'display--none' : ''
            }`}>
            {isExpanded && expandIndex === index ? (
              isDesktop && (
                <div className="media-container">
                  <img src={ImageLink(step.image.url)} alt={step.image.alt} />
                </div>
              )
            ) : (
              <span className="count">0{index + 1}</span>
            )}
          </div>

          <button
            type="button"
            className="button toggle-button"
            onClick={() => handleToggling(index)}>
            <svg className="toggle-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <path
                  d="M9.5 1h1v18h-1V1zm1 0v18h-1V1h1z"
                  stroke="currentColor"
                  className={`${isExpanded && expandIndex === index && 'is-expanded'}`}
                />
                <path
                  d="M9.5 1h1v18h-1V1zm1 0v18h-1V1h1z"
                  stroke="currentColor"
                  className={`vertical-path ${
                    isExpanded && expandIndex === index && 'is-expanded'
                  }`}
                />
              </g>
            </svg>
          </button>
        </div>
      ))}
    </div>
  );
}
