import { ENDPOINTS } from './endpoints';
import { fetchData } from './network';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';

export function getHomePage() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getHomepage.path}`);

  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate[hero_image]', '*');
  url.searchParams.append('populate[SEO_meta_tags]', '*');
  url.searchParams.append('populate[section_2_side_image]', '*');
  url.searchParams.append('populate[college_prep_slider][populate]', '*');
  url.searchParams.append('populate[amenity_slide][populate]', '*');

  return fetchData(url, {
    method: ENDPOINTS.getHomepage.method
  }).then((response) => response);
}
