import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LocaleContext from '../../contexts/LocaleContext';

import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';
import StepsAccordion from './StepsAccordion';
import { formatDateMonthAndDay } from '../../js/utils/format-date';

export default function ApplicationSteps({ rounds, steps }) {
  const { t } = useTranslation(['AdmissionsPage']);
  const { locale } = useContext(LocaleContext);

  function formattedDate(date) {
    return formatDateMonthAndDay(date, locale === 'en' ? 'en-US' : 'ar-EG');
  }

  return (
    <section className="application-steps">
      <div className="application-steps__content">
        <MainTitle type="dark" className="text--2xl">
          <span>{t('applyNowIn')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('fewSteps')}
          </span>
        </MainTitle>
        <p className="note">{t('applicationStepsNote')}</p>
        <div className="rounds__container">
          <ul className="rounds__list">
            {rounds?.map((round) => (
              <li className="round" key={round?.round_title}>
                <h3>{round?.round_title}</h3>
                <div className="round-dates">
                  <p className="deadline">
                    <span>{t('deadline')}</span>
                    <time>{formattedDate(round?.deadline_date)}</time>
                  </p>
                  <p className="decision-date">
                    <span>{t('decisionDate')}</span>
                    <time>{formattedDate(round?.decision_date)}</time>
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <StepsAccordion steps={steps} />
      <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />
    </section>
  );
}
