/* eslint-disable no-unused-vars */
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(err) {
    return { hasError: true };
  }

  componentDidCatch(err, errorInfo) {
    // eslint-disable-next-line no-console
    console.error({ err, errorInfo });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <main className="error-container container">
          <h1>Oops, something went wrong. Please try again later</h1>
        </main>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
