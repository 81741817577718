import ImageData from './Image';

/* eslint-disable camelcase */
class AboutData {
  constructor({
    attributes: {
      SEO_meta_tags,
      story_behind_manara,
      story_behind_manara_image,
      campus_student_slide,
      Manara_Team_cards,
      Board_of_Trustees
    }
  }) {
    this.story = {
      text: story_behind_manara,
      image: new ImageData(story_behind_manara_image?.data)
    };

    const items = campus_student_slide.map((item) => {
      const { id, slide_title, slide_description, slide_link_text, slide_link_url, slide_image } =
        item;

      return {
        id,
        title: slide_title,
        image: new ImageData(slide_image?.data),
        text: slide_description,
        url: slide_link_url,
        linkText: slide_link_text
      };
    });
    this.campusLife = items;

    const teamItems = Manara_Team_cards.map((teamItem) => {
      const { id, full_name, job_title, biography, profile_image } = teamItem;

      return {
        id,
        name: full_name,
        jobTitle: job_title,
        bio: biography,
        image: new ImageData(profile_image.data)
      };
    });
    this.team = teamItems;

    const boardOfTrustees = Board_of_Trustees.map((item) => {
      const { id, full_name, biography, job_title, profile_image } = item;

      return {
        id,
        name: full_name,
        image: new ImageData(profile_image?.data),
        biography,
        jobTitle: job_title
      };
    });
    this.boardOfTrustees = boardOfTrustees;
    this.metaTags = SEO_meta_tags?.map(
      ({ id, meta_tag_name: name, meta_tag_content: content }) => ({
        id,
        name,
        content
      })
    );
  }
}

export default AboutData;
