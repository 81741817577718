// import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// import LocaleContext from '../../../contexts/LocaleContext';
// import { getQuarterDates } from '../../../js/utils/quarterDates';

import EventCard from './EventCard';

export default function EventsGrid({ events }) {
  const { t } = useTranslation(['AcademicsPage']);
  // const { locale } = useContext(LocaleContext);
  // const [quarterDetails, setQuarterDetails] = useState(null);

  // useEffect(() => {
  //   setQuarterDetails(getQuarterDates(events, locale === 'en' ? 'en-US' : 'ar-EG'));
  // }, [events]);

  return (
    <div className="events__container">
      <div className="events__header">
        <div className="quarters-info">
          {/* {quarterDetails?.map((quarter) => (
            <p className="quarter">
              <span className="quarter__number">
                {t('quarter')}
                {quarter.quarter}
              </span>
              <span className="quarter__start-date">{quarter.quarterStartDate}</span>
              <span className="quarter__end-date">{quarter.quarterEndDate}</span>
            </p>
          ))} */}
        </div>
        <div className="events__identification hidden--md">
          <p className="upcoming-events">{t('upcomingEvents')}</p>
          <p className="past-events">{t('pastEvents')}</p>
        </div>
      </div>
      <div className="events-grid">
        {events?.map((event) => (
          <EventCard event={event} key={event?.id} />
        ))}
      </div>
    </div>
  );
}
