import { useState, useEffect } from 'react';
import AccordionItems from './AccordionItems';

/**
 *
 * @param {{type: 'light' | 'dark'}} props
 *
 */
export default function Accordion({ type = 'dark', data, className }) {
  const [accordionIndexOpen, setAccordionIndexOpen] = useState(0);
  const [accordionData, setAccordionData] = useState();

  useEffect(() => {
    setAccordionData(data?.splice(0, 4));
  }, [data]);

  /**
   * Toggle accordion items.
   */
  const toggleAccordionItems = (index) => {
    setAccordionIndexOpen(index);
  };

  const scrollbarWidth = `${window.innerWidth - document.body.clientWidth}px`;

  return (
    <div
      className={`accordion ${className}`}
      style={{
        '--item-width': `calc(100vw - ${scrollbarWidth} - calc(70px * ${
          accordionData?.length || 1
        }))`
      }}>
      {accordionData?.map((item, index) => (
        <AccordionItems
          {...item}
          index={index}
          toggleAccordionItems={toggleAccordionItems}
          accordionIndexOpen={accordionIndexOpen}
          key={item.id}
          type={type}
        />
      ))}
    </div>
  );
}
