{
  "pageTitle": "Admissions",
  "readyToJoin": "Ready to join the ranks",
  "futureLeadership": "of future leadership? ",
  "startYourApplication": "Start Your Application",
  "application": "Application",
  "timeline": "Timeline",
  "applicationOpens": "Applications Opens",
  "applicationDeadline": "Applications Deadline",
  "assessmentWeek": "Assessment Week",
  "registrationDeadline": "Registration Deadline",
  "firstDayOfSchool": "First Day of School",
  "applyNowIn": "Apply Now in",
  "fewSteps": "A Few Steps",
  "applicationStepsNote": "Please note that we cannot guarantee available spots beyond Round One. You will be informed if the available spots are full.",
  "deadline": "Deadline",
  "decisionDate": "Decision Date",
  "download": "Download",
  "annual": "Annual",
  "studentFees": "Student Fees",
  "annualFeesDescription": "Our annual fee per student is a comprehensive fee, covering all student expenses during the academic year, including academic and residential costs.",
  "feesInclude": "Annual fees include",
  "feesExclude": "Annual fees do not include",
  "needSupport": "Need support or",
  "haveQuestion": "have any questions?",
  "supportDescription": "We are here to help! Contact us with any inquiries or for more information.",
  "contactUs": "Contact us",
  "payment": "payment",
  "instructions": "instructions",
  "benificiaryName": "Benificiary Name",
  "accountNumber": "Benificiary Account Number",
  "cib": "Commercial International Bank",
  "manaraSchool": "Manara School",
  "payOnline": "You can also pay online",
  "makeOnline": "Make an online payment",
  "banksInfoError": "Failed to get banks info"
}
