import { ENDPOINTS } from './endpoints';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';
import { fetchData } from './network';

export function getOpportunities() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getOpportunities.path}`);
  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate', '*');

  return fetchData(url, {
    method: ENDPOINTS.getOpportunities.method
  }).then((response) => response);
}
