import { useContext, useEffect, useState } from 'react';
import compareAsc from 'date-fns/compareAsc';

import LocaleContext from '../../../contexts/LocaleContext';
import { formatDateMonthAndDay, formatDate } from '../../../js/utils/format-date';

export default function EventCard({ event }) {
  const { locale } = useContext(LocaleContext);
  const [isUpcomingEvent, setIsUpcomingEvent] = useState(null);
  const [isMultiDaysEvent, setIsMultiDaysEvent] = useState(null);

  /** Compare the two dates and return 1 if the first date is after the second,
   * -1 if the first date is before the second or 0 if dates are equal */
  function compareDates(endDate, startDate) {
    return compareAsc(new Date(endDate), new Date(startDate));
  }

  function formatShortDate(date) {
    return formatDateMonthAndDay(date, locale === 'en' ? 'en-US' : 'ar-EG');
  }
  function formatLongDate(date) {
    return formatDate(date, locale === 'en' ? 'en-US' : 'ar-EG');
  }

  useEffect(() => {
    setIsUpcomingEvent(compareDates(event?.end_date, new Date()));

    setIsMultiDaysEvent(compareDates(event?.end_date, event?.start_date));
  }, []);

  return (
    <div className={`event-card ${isUpcomingEvent === 1 ? 'upcoming' : 'past'}`}>
      <div className="event-card__dates">
        <time className="start-date">
          {isMultiDaysEvent !== 0
            ? formatShortDate(event?.start_date)
            : formatLongDate(event?.start_date)}
        </time>
        {isMultiDaysEvent !== 0 && (
          <time className="end-date">{formatLongDate(event?.end_date)}</time>
        )}
      </div>
      <h3 className="event-card__title">{event?.event_name}</h3>
      <p className="event-card__days">{event?.event_days}</p>
    </div>
  );
}
