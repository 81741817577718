import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from 'swiper';
import isAfter from 'date-fns/isAfter';

import LocaleContext from '../../../contexts/LocaleContext';
import { getAcademicCalendar } from '../../../js/api/academic-calendar';
// import SliderNavigation from '../../common/SliderNavigation';

import MainTitle from '../../common/MainTitle';
import EventsGrid from './EventsGrid';

export default function AcademicCalendar({ titles }) {
  const { t } = useTranslation(['AcademicsPage']);
  const { locale } = useContext(LocaleContext);
  const [academicCalendarData, setAcademicCalendarData] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  // const [calendarDates, setCalendarDates] = useState(null);
  // const [allowPrev, setAllowPrev] = useState(true);
  // const [allowNext, setAllowNext] = useState(true);

  useEffect(() => {
    // setIsLoading(true);
    getAcademicCalendar()
      .then((res) => {
        const dates = res.data?.map((event) => ({ id: event.id, ...event.attributes }));

        // Sort Events by Start Dates
        const sortByStartDate = dates?.sort((firstEvent, secondEvent) => {
          const firstDate = new Date(firstEvent?.start_date);
          const secondDate = new Date(secondEvent?.start_date);

          // Check if the first event date is after the second one
          const comparedDates = isAfter(firstDate, secondDate);
          if (comparedDates) {
            return 1;
          }
          if (!comparedDates) {
            return -1;
          }
          return 0;
        });

        setAcademicCalendarData(sortByStartDate);
      })
      .catch((e) => {
        toast.error('An error occurred, try again later');
        throw e;
      });
    // .finally(() => setIsLoading(false));
  }, [locale]);

  /** Split Academic Calendar into Even Chunks to easily navigate them on UI */
  // function sliceIntoChunks(datesArray, chunkSize) {
  //   const result = [];
  //   for (let i = 0; i < datesArray.length; i += chunkSize) {
  //     const chunk = datesArray.slice(i, i + chunkSize);
  //     result.push(chunk);
  //   }
  //   return result;
  // }

  // useEffect(() => {
  //   if (academicCalendarData?.length) {
  //     if (window.innerWidth < 600) {
  //       // Display 2 events per slide on mobile screen
  //       setCalendarDates(sliceIntoChunks(academicCalendarData, 2));
  //     } else if (window.innerWidth > 600 && window.innerWidth < 1023) {
  //       // Display 4 events per slide on tablet screen
  //       setCalendarDates(sliceIntoChunks(academicCalendarData, 4));
  //     } else {
  //       // Display 8 events per slide on desktop screen
  //       setCalendarDates(sliceIntoChunks(academicCalendarData, 8));
  //     }
  //   }
  // }, [!isLoading, academicCalendarData]);

  /**
   * Updates the slider status on init and on change.
   * @param {SwiperProps} swiper
   */
  // function updateSliderStatus(swiper) {
  //   setAllowNext(!swiper.isEnd);
  //   setAllowPrev(!swiper.isBeginning);
  // }

  return (
    <section className="academic-calendar">
      <MainTitle type="dark" className="text--2xl">
        <span>{titles?.title1}</span>
        <span className="decorated-line decorated-line--dark decorated-line--left">
          {titles?.title2}
        </span>
      </MainTitle>
      <div className="events__identification hidden--lg">
        <p className="upcoming-events">{t('upcomingEvents')}</p>
        <p className="past-events">{t('pastEvents')}</p>
      </div>

      {/* <Swiper
        className="academic-calendar__swiper"
        dir={locale === 'en' ? 'ltr' : 'rtl'}
        slidesPerView={1.4}
        spaceBetween={30}
        breakpoints={{
          600: { slidesPerView: 1.3 },
          1024: { slidesPerView: 1.1, spaceBetween: 30 },
          1920: { slidesPerView: 1.3, spaceBetween: 50 }
        }}
        onSlideChange={(swiper) => updateSliderStatus(swiper)}
        onSwiper={(swiper) => updateSliderStatus(swiper)}
        modules={[Navigation]}>
        {!!calendarDates &&
          calendarDates?.map((eventsGroup) => (
            <SwiperSlide key={`events-group__slide-${eventsGroup[0].id}`}>
              <EventsGrid events={eventsGroup} key={eventsGroup[0].id} />
            </SwiperSlide>
          ))}

        <SliderNavigation
          allowPrev={allowPrev}
          allowNext={allowNext}
          className="academic-calendar__nav-buttons"
        />
      </Swiper> */}
      {!!academicCalendarData && <EventsGrid events={academicCalendarData} />}
    </section>
  );
}
