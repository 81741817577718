import { useTranslation } from 'react-i18next';
import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';

import BoyStudentJpg from '../../assets/images/admissions/admissions-hero-1.jpeg';
import BoyStudentWebp from '../../assets/images/admissions/admissions-hero-1.webp';
import GirlStudentJpg from '../../assets/images/admissions/admissions-hero-2.jpeg';
import GirlStudentWebp from '../../assets/images/admissions/admissions-hero-2.webp';

export default function HeroSection() {
  const { t } = useTranslation(['AdmissionsPage']);

  return (
    <section className="hero-section container">
      <div className="hero-section__secondary-media">
        <picture>
          <source srcSet={BoyStudentWebp} type="image/webp" />
          <img src={BoyStudentJpg} alt="manara-students" />
        </picture>
      </div>

      <div className="hero-section__content">
        <MainTitle type="dark" className="text--2xl hidden--lg">
          <span>{t('readyToJoin')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('futureLeadership')}
          </span>
        </MainTitle>

        <div className="hero-section__content-container">
          <MainTitle type="dark" className="text--2xl hidden--md">
            <span>{t('readyToJoin')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('futureLeadership')}
            </span>
          </MainTitle>

          <a
            href="https://manaraschool.heiapply.com/application"
            target="_blank"
            className="button app-button app-button--dark"
            rel="noreferrer">
            <span />
            <span>{t('startYourApplication')}</span>
          </a>
        </div>

        <div className="hero-section__main-media">
          <picture>
            <source srcSet={GirlStudentWebp} type="image/webp" />
            <img src={GirlStudentJpg} alt="manara-students" />
          </picture>
        </div>
      </div>
      <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />
    </section>
  );
}
