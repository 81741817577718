import { useTranslation } from 'react-i18next';
import ImageLink from '../../js/utils/ImageLink';

import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';

export default function PursueYourPassion({ data }) {
  const { t } = useTranslation(['AcademicsPage']);

  return (
    <section className="pursue-your-passion">
      <div className="pursue-your-passion__container">
        <div className="pursue-your-passion__header">
          <MainTitle type="dark" className="text--2xl">
            <span>{t('pursueYourPassionTitle1')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('pursueYourPassionTitle2')}
            </span>
            <span>{t('pursueYourPassionTitle3')}</span>
          </MainTitle>
        </div>

        <div className="pursue-your-passion__content">
          <p>{data?.description1}</p>
          <p>{data?.description2}</p>
        </div>

        <div className="pursue-your-passion__media">
          <img src={ImageLink(data?.image.url)} alt={data?.image.alt} />
          <Icon name="light-bulb" className="light-bulb-icon" />
          <Icon name="ribbon" className="ribbon-icon" />
        </div>

        <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />
      </div>
    </section>
  );
}
