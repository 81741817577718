import { useTranslation } from 'react-i18next';
import MainTitle from '../common/MainTitle';
import ContactInfo from '../common/ContactInfo';

import ManaraStudentsJpg from '../../assets/images/contact-us/hear-from-you.jpeg';
import ManaraStudentsWebp from '../../assets/images/contact-us/hear-from-you.webp';

export default function ConnectWithUs() {
  const { t } = useTranslation(['ContactPage']);

  return (
    <section className="connect-with-us">
      <div className="connect-with-us__wrapper">
        <MainTitle type="dark" className="text--2xl hidden--lg">
          <span>{t('weWouldLoveTo')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('hearFromYou')}
          </span>
        </MainTitle>

        <div className="connect-with-us__content">
          <MainTitle type="dark" className="text--2xl hidden--md">
            <span>{t('weWouldLoveTo')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('hearFromYou')}
            </span>
          </MainTitle>

          <ContactInfo theme="light" layout="narrow" />
        </div>
        <div className="connect-with-us__media">
          <picture>
            <source srcSet={ManaraStudentsWebp} type="image/webp" />
            <img src={ManaraStudentsJpg} alt="child-making-robot" />
          </picture>
        </div>
      </div>
    </section>
  );
}
