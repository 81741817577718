/**
 *
 * @param {{
 *  startImage: Image,
 *  startImageWebp: Image,
 *  startImageAlt: string,
 *  endImage: Image,
 *  endImageWebp: Image,
 *  endImageAlt: string,
 *  title1: string,
 *  title2: string,
 *  title3: string,
 *  text: string,
 *  addSpiral: boolean,
 *  className: string}} props
 * @returns
 */

import MainTitle from './MainTitle';
import Icon from './Icon';

export default function PageHeroSection({
  startImage,
  startImageWebp,
  startImageAlt,
  endImage,
  endImageWebp,
  endImageAlt,
  title1,
  title2,
  title3,
  text,
  className = '',
  addSpiral = true
}) {
  return (
    <div className={`page-hero-section ${className}`}>
      <picture className="page-hero-section__start">
        <source srcSet={startImageWebp} type="image/webp" />
        <img src={startImage} alt={startImageAlt} />
      </picture>
      {addSpiral && <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />}

      <div className="page-hero-section__content">
        <div className="page-hero-section__header">
          <MainTitle type="dark" className="text--2xl">
            <span>{title1}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {title2}
            </span>
            {title3 && <span>{title3}</span>}
          </MainTitle>
        </div>
        {text && <p className="hidden--md">{text}</p>}
      </div>

      <picture className="page-hero-section__end">
        <source srcSet={endImageWebp} type="image/webp" />
        <img src={endImage} alt={endImageAlt} />
      </picture>

      {text && <p className="description hidden--lg">{text}</p>}
    </div>
  );
}
