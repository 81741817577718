import { useTranslation } from 'react-i18next';
import ImageLink from '../../js/utils/ImageLink';

import MainTitle from '../common/MainTitle';

export default function StudentCenteredCurriculum({ data }) {
  const { t } = useTranslation(['AcademicsPage']);

  return (
    <section className="student-curriculum">
      <div className="student-curriculum__container">
        <div className="student-curriculum__content">
          <header className="student-curriculum__header">
            <MainTitle type="dark" className="text--2xl">
              <span className="decorated-line decorated-line--dark decorated-line--left">
                {t('studentCentered')}
              </span>
              <span>{t('curriculum')}</span>
            </MainTitle>
          </header>

          <p className="student-curriculum__description hidden--md">{data?.description}</p>
        </div>

        <div className="student-curriculum__media">
          <img src={ImageLink(data?.image.url)} alt={data?.image.alt} />
        </div>
        <p className="student-curriculum__description hidden--lg">{data?.description}</p>
      </div>
    </section>
  );
}
