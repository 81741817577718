import ImageLink from '../../../js/utils/ImageLink';
import Icon from '../../common/Icon';

export default function Slide({ index, image, text, title, linkText, url }) {
  return (
    <div className="key-teams__slide" key={index}>
      <div className="slide__content">
        {title && <p className="slide__title">{title}</p>}
        <p>{text}</p>
        <div className="slide__link">
          <a href={url}>
            <Icon name="open-dark" className="growth-icon" />
            {linkText}
          </a>
        </div>
      </div>

      <div className="slide__media">
        <picture>
          <img src={ImageLink(image.url)} alt={image.alt} />
        </picture>
        <div className="backdrop" />
      </div>
    </div>
  );
}
