class ImageData {
  /**
   * @param {{
   *    id: number,
   *    attributes: {
   *       url: string,
   *       alt: string,
   *       width,
   *       height,
   *       formats: {
   *         large: {url, height, width},
   *         small: {url, height, width},
   *         medium: {url, height, width}
   *     } | undefined
   * }} image
   */
  constructor(image) {
    if (!image?.attributes) {
      return;
    }

    const {
      id,
      attributes: { url, alternativeText, width, height, formats }
    } = image;
    this.id = id;
    this.url = url;
    this.alt = alternativeText;
    this.width = width;
    this.height = height;
    this.formats = formats;
  }
}

export default ImageData;
