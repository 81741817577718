import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import LocaleContext from '../../../contexts/LocaleContext';

import MainTitle from '../../common/MainTitle';
import SliderNavigation from '../../common/SliderNavigation';
import Slide from './Slide';

export default function HousingAndAccommodation({ slides }) {
  const { t } = useTranslation(['CampusPage']);
  const [allowPrev, setAllowPrev] = useState(true);
  const [allowNext, setAllowNext] = useState(true);
  const { locale } = useContext(LocaleContext);

  /**
   * Updates the slider status on init and on change.
   * @param {SwiperProps} swiper
   */
  function updateSliderStatus(swiper) {
    setAllowNext(!swiper.isEnd);
    setAllowPrev(!swiper.isBeginning);
  }

  const SwiperSlidesJSX = slides?.map((slide) => (
    <SwiperSlide key={`housing-and-accommodation__slide-${slide?.id}`}>
      <Slide
        allowPrev={allowPrev}
        allowNext={allowNext}
        title={slide?.title}
        description={slide?.description}
        image={slide?.image}
      />
    </SwiperSlide>
  ));

  return (
    <section className="housing-and-accommodation">
      <div className="housing-and-accommodation__slider-wrapper">
        <MainTitle type="dark" className="text--2xl">
          <span>{t('facilities')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('housing')}
          </span>
          <span>{t('accommodation')}</span>
        </MainTitle>

        <Swiper
          className="housing-and-accommodation__swiper"
          dir={locale === 'en' ? 'ltr' : 'rtl'}
          slidesPerView={1}
          breakpoints={{
            320: { slidesPerView: 1.3, spaceBetween: 26 },
            1280: { slidesPerView: 1.4, spaceBetween: 80 },
            1920: { slidesPerView: 1.6 }
          }}
          centeredSlides
          loop
          onSlideChange={(swiper) => updateSliderStatus(swiper)}
          onSwiper={(swiper) => updateSliderStatus(swiper)}
          modules={[Navigation]}>
          {SwiperSlidesJSX}

          <SliderNavigation
            allowPrev={allowPrev}
            allowNext={allowNext}
            className="housing-and-accommodation__nav-buttons"
          />
        </Swiper>
      </div>
    </section>
  );
}
