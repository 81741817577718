/* eslint-disable camelcase */

class ContactData {
  constructor({ attributes: { SEO_meta_tags, title_line_1, title_line_2, form_description } }) {
    this.connectWithUs = {
      title1: title_line_1,
      title2: title_line_2,
      description: form_description
    };

    this.metaTags = SEO_meta_tags?.map(
      ({ id, meta_tag_name: name, meta_tag_content: content }) => ({
        id,
        name,
        content
      })
    );
  }
}

export default ContactData;
