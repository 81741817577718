import { useTranslation } from 'react-i18next';
import MainTitle from '../common/MainTitle';

import ElGounaJpg from '../../assets/images/campus/El-Gouna.jpeg';
import ElGounaWebp from '../../assets/images/campus/El-Gouna.webp';

export default function AboutElGouna() {
  const { t } = useTranslation(['CampusPage']);

  return (
    <section className="about-elgouna">
      <MainTitle type="dark" className="text--2xl container hidden--desktop">
        <span>{t('elgouna')}</span>
        <span className="decorated-line decorated-line--dark decorated-line--left">
          {t('redSea')}
        </span>
      </MainTitle>

      <div className="about-elgouna__container container">
        <div className="about-elgouna__content">
          <MainTitle type="dark" className="text--2xl container hidden--mobile">
            <span>{t('elgouna')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('redSea')}
            </span>
          </MainTitle>

          <div className="about-elgouna__description">
            <p className="description">{t('elgounaDescription')}</p>
          </div>
        </div>
        <div className="about-elgouna__media">
          <picture>
            <source srcSet={ElGounaWebp} type="image/webp" />
            <img src={ElGounaJpg} alt="El Gouna City" />
          </picture>
        </div>
      </div>
    </section>
  );
}
