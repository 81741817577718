{
  "pageTitle": "Careers",
  "heroTitle1": "Creating Real",
  "heroTitle2": "Impact by Building",
  "heroTitle3": "Future Leaders",
  "heroDescription": "Joining forces with Manara School will allow you to build a legacy behind by fostering the building of future leaders with the support of expert faculty who have extensive experience in realizing the potential of teenagers.",
  "cultureTitle1": "Culture and",
  "cultureTitle2": "Environment",
  "cultureText": "Members of Manara community are professional experts who love adolescents and are dedicated to their growth. They practice integrity, collaboration, empathy, and care. They operate within a framework of diversity, equity, and respect. They lead and learn with resilience and optimism.",
  "cultureButton": "Read our Portrait of an Educator",
  "benefits": "benefits",
  "andPolicies": "and policies",
  "exploreOur": "Explore our",
  "keyTeams": "key teams",
  "manaraCurrent": "CURRENT OPPORTUNITIES",
  "opportunities": "AT MANARA",
  "opportunitiesError": "Failed to get opportunities data",
  "careersCTA1": "Interested in Applying?",
  "careersCTA2": "Join Our Team"
}
