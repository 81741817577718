import ImageData from './Image';

/* eslint-disable camelcase */
class ThankYouPageData {
  constructor({
    attributes: {
      SEO_meta_tags,
      hero_text_line_1,
      hero_text_line_2,
      hero_text_line_3,
      description,
      section_image_left,
      section_image_right
    }
  }) {
    this.heroSection = {
      title1: hero_text_line_1,
      title2: hero_text_line_2,
      title3: hero_text_line_3,
      description,
      imageLeft: new ImageData(section_image_left?.data),
      imageRight: new ImageData(section_image_right?.data)
    };

    this.metaTags = SEO_meta_tags?.map(
      ({ id, meta_tag_name: name, meta_tag_content: content }) => ({
        id,
        name,
        content
      })
    );
  }
}

export default ThankYouPageData;
