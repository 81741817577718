import { useTranslation } from 'react-i18next';
import MainTitle from '../common/MainTitle';
import ImageLink from '../../js/utils/ImageLink';

export default function Story({ text, image }) {
  const { t } = useTranslation(['AboutPage']);

  return (
    <section className="story">
      <div className="story__header mobile">
        <MainTitle type="dark" className="text--2xl">
          <span>{t('theStory')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('manaraSchool')}
          </span>
        </MainTitle>
      </div>
      <div className="story__media">
        <img src={ImageLink(image.url)} alt={image.alt} />
      </div>

      <div className="story__content">
        <div className="story__header desktop">
          <MainTitle type="dark" className="text--2xl">
            <span>{t('theStory')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('manaraSchool')}
            </span>
          </MainTitle>
        </div>
        <p>{text}</p>
      </div>
    </section>
  );
}
