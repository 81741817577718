import ImageData from './Image';

/* eslint-disable camelcase */
class DonateFoundationsData {
  /**
   * @param {{
   *   id: string,
   *   attributes: {
   *    Name: string,
   *    description: string,
   *    image: Image,
   *    foundation_link: string,
   *   }
   * }}
   */
  constructor(data) {
    if (!data?.attributes) {
      return;
    }

    const {
      id,
      attributes: { Name, description, image, foundation_link }
    } = data;

    this.id = id;
    this.name = Name;
    this.description = description;
    this.url = foundation_link;
    this.image = new ImageData(image?.data);
  }
}

export default DonateFoundationsData;
