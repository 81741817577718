/**
 * @author Heba ElGalad
 * @date 2022-11-8
 * @description list of endpoints within the API
 * @filename endpoint.js
 */

export const ENDPOINTS = {
  getHeader: {
    method: 'GET',
    path: '/navigation/render/header'
  },
  getFooter: {
    method: 'GET',
    path: '/navigation/render/footer'
  },
  getHomepage: {
    method: 'GET',
    path: '/homepage'
  },
  getArticles: {
    method: 'GET',
    path: '/articles'
  },
  getApplicationDetails: {
    method: 'GET',
    path: '/application-detail'
  },
  getAboutUsPage: {
    method: 'GET',
    path: '/about-us'
  },
  getAcademicsPage: {
    method: 'GET',
    path: '/academic'
  },
  getAdmissionsPage: {
    method: 'GET',
    path: '/admission'
  },
  getContactUsPage: {
    method: 'GET',
    path: '/contact-us'
  },
  getContactInfo: {
    method: 'GET',
    path: '/contact-information'
  },
  getDonateFoundations: {
    method: 'GET',
    path: '/donate-foundations'
  },
  DonateNow: {
    method: 'POST',
    path: '/orders'
  },
  getCareers: {
    method: 'GET',
    path: '/career'
  },
  getCampusDetails: {
    method: 'GET',
    path: '/campus-and-student-life'
  },
  submitContactRequests: {
    method: 'POST',
    path: '/contact-requests'
  },
  getOpportunities: {
    method: 'GET',
    path: '/current-opportunities'
  },
  getBanks: {
    method: 'GET',
    path: '/banks'
  },
  getCourses: {
    method: 'GET',
    path: '/course-catalogs'
  },
  getAcademicCalendar: {
    method: 'GET',
    path: '/academic-calendars'
  },
  getThankYouPage: {
    method: 'GET',
    path: '/thank-you-page'
  }
};
