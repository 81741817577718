import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LocaleContext from '../../contexts/LocaleContext';

import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';
import { formatDateShort } from '../../js/utils/format-date';

export default function ApplicationTimeline({ data }) {
  const { t } = useTranslation(['AdmissionsPage']);
  const { locale } = useContext(LocaleContext);
  const { description, timeline } = data;

  function formattedDate(date) {
    return formatDateShort(date, locale === 'en' ? 'en-US' : 'ar-EG');
  }

  return (
    <section className="application-timeline">
      <div className="application-timeline__content">
        <MainTitle type="dark" className="text--2xl">
          <span>{t('application')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('timeline')}
          </span>
        </MainTitle>
        <div className="application-timeline__description">
          <p>{description?.application_timeline_description_1}</p>
          <p>{description?.application_timeline_description_2}</p>
        </div>
      </div>

      <div className="timeline__container">
        <ul className="timeline__list">
          <li className="timeline__item">
            <div className="timeline__info">
              <time>{formattedDate(timeline?.application_opens)}</time>
              <p>{t('applicationOpens')}</p>
            </div>
            <Icon name="application-opens" />
          </li>

          <li className="timeline__item">
            <div className="timeline__info">
              <time>{formattedDate(timeline?.application_deadline)}</time>
              <p>{t('applicationDeadline')}</p>
            </div>
            <Icon name="application-deadline" />
          </li>

          <li className="timeline__item">
            <div className="timeline__info">
              <time>{formattedDate(timeline?.registration_deadline)}</time>
              <p>{t('registrationDeadline')}</p>
            </div>
            <Icon name="registration-deadline" />
          </li>

          <li className="timeline__item">
            <div className="timeline__info">
              <time>{formattedDate(timeline?.first_day_of_school)}</time>
              <p>{t('firstDayOfSchool')}</p>
            </div>
            <Icon name="first-day-of-school" />
          </li>
        </ul>
      </div>

      <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />
    </section>
  );
}
