import { useTranslation } from 'react-i18next';
// import AppButton from '../common/AppButton';
import MainTitle from '../common/MainTitle';

import ScienceStudent from '../../assets/images/about/inclusivity.jpeg';
import ScienceStudentWEBP from '../../assets/images/about/inclusivity.webp';

export default function Inclusivity() {
  const { t } = useTranslation(['AboutPage']);

  return (
    <section className="inclusivity">
      <div className="inclusivity__container">
        <div className="inclusivity__header hidden--lg">
          <MainTitle type="light" className="text--2xl">
            <span>{t('inclusivityTitle1')}</span>
            <span className="decorated-line decorated-line--light decorated-line--left">
              {t('inclusivityTitle2')}
            </span>
            <span>{t('inclusivityTitle3')}</span>
          </MainTitle>
        </div>

        <div className="inclusivity__content">
          <div className="inclusivity__header hidden--md">
            <MainTitle type="light" className="text--2xl">
              <span>{t('inclusivityTitle1')}</span>
              <span className="decorated-line decorated-line--light decorated-line--left">
                {t('inclusivityTitle2')}
              </span>
              <span>{t('inclusivityTitle3')}</span>
            </MainTitle>
          </div>
          <p>{t('inclusivityText')}</p>

          {/* TODO: Enable this when the page is created */}
          {/* <AppButton tag="a" theme="light" linkPath={`/${locale}/academics#scholarship`}>
            {t('inclusivityButton')}
          </AppButton> */}
        </div>

        <div className="inclusivity__media">
          <picture>
            <source srcSet={ScienceStudentWEBP} type="image/webp" />
            <img src={ScienceStudent} alt="Student using microscope" />
          </picture>
        </div>
      </div>
    </section>
  );
}
