import { useContext } from 'react';
import { Link } from 'react-router-dom';
import AppLogo from '../../assets/images/logo.png';
import LocaleContext from '../../contexts/LocaleContext';

export default function Logo() {
  const { locale } = useContext(LocaleContext);
  return (
    <h1 className="logo">
      <Link to={`/${locale}`} title="Homepage">
        <img src={AppLogo} alt="Manara School" />
      </Link>
    </h1>
  );
}
