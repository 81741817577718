import AppAccordion from '../../common/AppAccordion';

export default function OpportunitiesItem({
  teamName,
  title,
  description,
  id,
  toggleAccordionItems
}) {
  const headerJSX = (
    <div className="opportunity__header">
      <p className="team">{teamName}</p>
      <p className="title">{title}</p>
    </div>
  );

  const contentJSX = <p className="opportunity__description">{description}</p>;

  return (
    <AppAccordion
      header={headerJSX}
      content={contentJSX}
      key={id}
      handelExpandedView={() => toggleAccordionItems(id)}
    />
  );
}
