import { Suspense, useEffect, useState, useMemo } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import LocaleContext from './contexts/LocaleContext';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import AcademicsPage from './pages/AcademicsPage';
import ContactPage from './pages/ContactPage';
import LandingPage from './pages/LandingPage';
import CareersPage from './pages/CareersPage';
import CampusAndStudentLife from './pages/CampusAndStudentLife';
import AdmissionsPage from './pages/AdmissionsPage';
import NewsPage from './pages/NewsPage';
import DonatePage from './pages/DonatePage';
import ThankYouPage from './pages/ThankYouPage';

import ErrorBoundary from './components/ErrorBoundary';

export default function App() {
  const { i18n } = useTranslation();
  const [initialized, setInitialized] = useState(false);
  const [locale, setLocale] = useState(i18n.language);
  const location = useLocation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    // Set localStorage locale that will be used in the API request headers
    localStorage.setItem('locale', lng);
  };

  function resolveI18nLocale(currentLocale) {
    // this mainly to replace 'en-US' with 'en'
    return currentLocale.includes('en') ? 'en' : 'ar';
  }

  useEffect(() => {
    i18n.on('languageChanged', () => setLocale(resolveI18nLocale(i18n.language)));

    if (!initialized) {
      changeLanguage(resolveI18nLocale(i18n.language) || localStorage.getItem('locale') || 'en');
      setInitialized(true);
    }

    // If the user opens 'ar' url in the browser set it as the used locale
    if (window.location.pathname.includes('ar')) {
      changeLanguage('ar');
      setLocale('ar');
    }

    // If the user opens 'en' url in the browser set it as the used locale
    if (window.location.pathname.includes('en')) {
      changeLanguage('en');
      setLocale('en');
    }
  }, []);

  // Prevent unnecessary rerenders.
  const value = useMemo(
    () => ({
      locale,
      setLocale
    }),
    [locale, setLocale]
  );

  return (
    <LocaleContext.Provider value={value}>
      <Suspense fallback="loading">
        <Helmet
          htmlAttributes={{
            lang: locale,
            dir: locale === 'en' ? 'ltr' : 'rtl'
          }}
        />
        <Header />
        <ErrorBoundary>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Navigate to={`/${locale}`} />} />
            <Route path={`/${locale}`} element={<HomePage />} />
            <Route path={`/${locale}/admission-campaign`} element={<LandingPage />} />
            <Route path={`${locale}/about`} element={<AboutPage />} />
            <Route path={`${locale}/academics`} element={<AcademicsPage />} />
            <Route path={`/${locale}/contact`} element={<ContactPage />} />
            <Route path={`/${locale}/careers`} element={<CareersPage />} />
            <Route path={`/${locale}/campus`} element={<CampusAndStudentLife />} />
            <Route path={`/${locale}/admissions`} element={<AdmissionsPage />} />
            <Route path={`/${locale}/news`} element={<NewsPage />} />
            <Route path={`/${locale}/donate`} element={<DonatePage />} />
            <Route path={`/${locale}/thank-you`} element={<ThankYouPage />} />
            <Route path="/thank-you" element={<Navigate to={`/${locale}/thank-you`} />} />
          </Routes>
        </ErrorBoundary>
        <Footer />
      </Suspense>
    </LocaleContext.Provider>
  );
}
