import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';
import ImageLink from '../../js/utils/ImageLink';

export default function CoCurricularPrograms({ programs }) {
  const { t } = useTranslation(['CampusPage']);
  const [isTabletScreen, setIsTabletScreen] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsTabletScreen(true);
    } else {
      setIsTabletScreen(false);
    }
  }, []);

  function splitTitle(title) {
    const stringsArray = title.split(' ');

    if (stringsArray.length === 1) {
      return {
        title1: stringsArray.join(''),
        title2: ''
      };
    }

    return {
      title1: stringsArray.shift(),
      title2: stringsArray.join(' ')
    };
  }

  return (
    <section className="co-curricular-programs" id="co-curricular-programs">
      <div className="co-curricular-programs__header">
        <MainTitle type="dark" className="text--2xl">
          <span>{t('coCurricular')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('programs')}
          </span>
        </MainTitle>
        <p>{t('coCurricularDescription')}</p>
      </div>

      <div className="co-curricular-programs__content">
        {programs?.map((program) => (
          <div className="program" key={program?.id}>
            <Icon name={program?.icon} className="program__icon" />
            {isTabletScreen ? (
              <h3 className="program__title">{program?.title}</h3>
            ) : (
              <h3 className="program__title">
                <span className="decorated-line decorated-line--dark decorated-line--right">
                  {splitTitle(program?.title).title1}
                </span>
                <span>{splitTitle(program?.title).title2}</span>
              </h3>
            )}
            <p className="program__description">{program?.description}</p>

            <div className="program__media">
              <img src={ImageLink(program.image.url)} alt={program.image.alt} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
