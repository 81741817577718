/* eslint-disable import/prefer-default-export */
import { ENDPOINTS } from './endpoints';
import { fetchData } from './network';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';

export function getAcademicsPage() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getAcademicsPage.path}`);

  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate[portrait_of_graduate][populate]', '*');
  url.searchParams.append('populate[learning_experience][populate]', '*');
  url.searchParams.append('populate[scholarly_inquiry][populate]', '*');

  return fetchData(url, {
    method: ENDPOINTS.getAcademicsPage.method
  }).then((response) => response);
}
