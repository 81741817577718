/* eslint-disable camelcase */
class ContactInfoData {
  /**
   * @param {{
   *   id: string,
   *   attributes: {
   *      address: string,
   *      email_address: string,
   *      facebook_link: string,
   *      instagram_link: string,
   *      linkedin_link: string,
   *      whatsapp_link: string,
   *      phone_number: string,
   *      working_hours: string,
   *   }
   * }}
   */
  constructor(data) {
    if (!data?.attributes) {
      return;
    }

    const {
      id,
      attributes: {
        address,
        email_address,
        facebook_link,
        instagram_link,
        linkedin_link,
        whatsapp_link,
        phone_number,
        working_hours
      }
    } = data;

    this.id = id;
    this.address = address;
    this.email_address = email_address;
    this.facebook_link = facebook_link;
    this.instagram_link = instagram_link;
    this.linkedin_link = linkedin_link;
    this.whatsapp_link = whatsapp_link;
    this.phone_number = phone_number;
    this.working_hours = working_hours;
  }
}

export default ContactInfoData;
