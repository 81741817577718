import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import MetaTags from '../components/common/MetaTags';
import HeroSection from '../components/Academics/HeroSection';
import PortraitOfGraduate from '../components/Academics/PortraitOfGraduate';
import StudentCenteredCurriculum from '../components/Academics/StudentCenteredCurriculum';
import PursueYourPassion from '../components/Academics/PursueYourPassion';
import CoursesCatalog from '../components/Academics/CoursesCatalog/CoursesCatalog';
import AcademicCalendar from '../components/Academics/AcademicCalendar/AcademicCalendar';

import LocaleContext from '../contexts/LocaleContext';
import { getAcademicsPage } from '../js/api/academic-page';
import AcademicsData from '../models/AcademicsPageData';

export default function AcademicsPage() {
  const { t } = useTranslation(['AcademicsPage']);
  const { locale } = useContext(LocaleContext);
  const [academicsPageData, setAcademicsPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Create Modal container
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', 'app-modal');

  useEffect(() => {
    setIsLoading(true);
    getAcademicsPage()
      .then((res) => {
        setAcademicsPageData(new AcademicsData(res.data));

        // Bind Modal container into DOM
        document.getElementById('app').appendChild(wrapperElement);
      })
      .catch((e) => {
        toast.error('An error occurred, try again later');
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [locale]);

  return (
    <main className="academics-page">
      {!isLoading && academicsPageData ? (
        <>
          <MetaTags title={t('pageTitle')} seo={academicsPageData?.metaTags} />
          <HeroSection />
          <PortraitOfGraduate data={academicsPageData?.portraitOfGraduate} />
          <StudentCenteredCurriculum data={academicsPageData?.learningExperience} />
          <PursueYourPassion data={academicsPageData?.scholarlyInquiry} />
          <CoursesCatalog titles={academicsPageData?.courseCatalogTitles} />
          <AcademicCalendar titles={academicsPageData?.academicCalendarTitles} />
        </>
      ) : null}
    </main>
  );
}
