/* eslint-disable camelcase */
import ImageData from './Image';

class AdmissionsData {
  constructor({
    attributes: {
      SEO_meta_tags,
      application_timeline_description_1,
      application_timeline_description_2,
      application_timeline,
      apply_rounds,
      apply_steps,
      annual_student_fees_includes,
      annual_student_fees_excludes
    }
  }) {
    this.applicationTimeline = {
      timeline: application_timeline,
      description: {
        application_timeline_description_1,
        application_timeline_description_2
      }
    };

    this.applyRounds = apply_rounds;

    const applySteps = apply_steps.map((step) => {
      const { id, title, description, icon_name, image, link, link_text } = step;
      return {
        id,
        title,
        description,
        icon: icon_name,
        image: new ImageData(image?.data),
        link,
        linkText: link_text
      };
    });
    this.applySteps = applySteps;

    this.annualFees = {
      includes: annual_student_fees_includes,
      excludes: annual_student_fees_excludes
    };

    this.metaTags = SEO_meta_tags?.map(
      ({ id, meta_tag_name: name, meta_tag_content: content }) => ({
        id,
        name,
        content
      })
    );
  }
}

export default AdmissionsData;
