import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainTitle from '../common/MainTitle';
import Icon from '../common/Icon';
import Donate from '../common/Donate';

export default function HeroSection() {
  const { t } = useTranslation(['DonatePage']);
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <section className="donate-hero-section container">
      <div class="overlay"></div>

      <div className="donate-hero-section__content">
        <MainTitle type="light" className="text--2xl hidden--lg">
          <span>{t('weBelieve')}</span>
          <span className="decorated-line decorated-line--light decorated-line--left">
            {t('everyTalented')}
          </span>
          <span>{t('anOpportunity')}</span>
        </MainTitle>

        <div className="donate-hero-section__content-container">
          <MainTitle type="light" className="text--2xl hidden--md">
            <span>{t('weBelieve')}</span>
            <span className="decorated-line decorated-line--light decorated-line--left">
              {t('everyTalented')}
            </span>
            <span>{t('anOpportunity')}</span>
          </MainTitle>
        </div>
      </div>

      <button
        type="button"
        className="button donate-now"
        title="donateNow"
        onClick={() => setIsModalOpened(true)}>
        <Icon name="open-light" />
        <span>{t('donateNow')}</span>
      </button>
      {isModalOpened && <Donate closeModal={() => setIsModalOpened(false)} />}
    </section>
  );
}
