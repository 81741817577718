import { ENDPOINTS } from './endpoints';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';
import { fetchData } from './network';

export function getArticles() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getArticles.path}`);
  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate', '*');

  return fetchData(url, {
    method: ENDPOINTS.getArticles.method
  }).then((response) => response);
}

export function getPaginatedArticles(index) {
  const url = new URL(
    `${process.env.API_URL}${ENDPOINTS.getArticles.path}?sort[0]=date:desc&pagination[page]=${index}&pagination[pageSize]=9`
  );
  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate', '*');

  return fetchData(url, {
    method: ENDPOINTS.getArticles.method
  }).then((response) => response);
}
