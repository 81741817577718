{
  "pageTitle": "Contact Us",
  "weWouldLoveTo": "We Would Love To",
  "hearFromYou": "Hear From You",
  "followUs": "Follow Us",
  "sendONWhatsapp": "Send on WhatsApp",
  "reachOutToUs": "We are happy to",
  "happyToConverse": "hear from you",
  "firstNameLabel": "Student First Name",
  "firstNamePlaceholder": "Mohamed",
  "firstNameError": "Student First Name is required!",
  "lastNameLabel": "Student last Name",
  "lastNamePlaceholder": "Ahmed",
  "lastNameError": "Student Last Name is required!",
  "emailLabel": "Primary Email",
  "emailPlaceholder": "example@example.com",
  "emailError": "Email should be ex@example.com",
  "emailRequired": "Email is required!",
  "phoneNumberLabel": "Primary Phone Number",
  "phoneNumberPlaceholder": "010 000 000 00",
  "phoneNumberRequired": "Phone Number is required",
  "studentInfo": "Student Information",
  "dateOfBirthLabel": "Student Date of Birth",
  "dateOfBirthPlaceholder": "DD/MM/YY",
  "dateOfBirthRequired": "Student Date of Birth is required",
  "dateOfBirthError": "Date of birth must be earlier than {{date}}",
  "studentGradeLabel": "Student Current Grade",
  "studentGradeRequired": "Student’s Current Grade is required!",
  "schoolCurriculumLabel": "Current School Curriculum",
  "schoolCurriculumRequired": "Current School Curriculum is required!",
  "generalInfo": "General Information",
  "youAreLookingForLabel": "What are you looking For?",
  "hearAboutUsLabel": "How did you hear about us?",
  "subscribeToNewsletter": "I’d like to subscribe to Manara’s newsletter",
  "fieldIsRequired": "This field is required!",
  "submitRequest": "Submit Request",
  "successMessage": "Thank you, your request has been successfully sent, we will get back to you.",
  "genericError": "Something went wrong, please try again later!"
}
