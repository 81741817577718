import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LocaleContext from '../../contexts/LocaleContext';

import MainTitle from '../common/MainTitle';
import AppButton from '../common/AppButton';
import LeadershipSkills from './LeadershipSkills';
import StudentsPng from '../../assets/images/home/students-leadership-potential.jpeg';
import StudentsWebp from '../../assets/images/home/students-leadership-potential.webp';

export default function StudentsLeadershipPotential() {
  const { t } = useTranslation(['HomePage']);
  const { locale } = useContext(LocaleContext);

  return (
    <section className="students-leadership">
      <div className="students-leadership__container container">
        <div className="students-leadership__content">
          <header className="students-leadership__header">
            <MainTitle type="dark" className="text--2xl">
              <span>{t('helpingStudents')}</span>
              <span className="decorated-line decorated-line--dark decorated-line--left">
                {t('reachTheirFull')}
              </span>
              <span>{t('leadershipPotential')}</span>
            </MainTitle>
          </header>

          <p className="hidden--mobile">{t('helpingStudentsContent')}</p>
        </div>

        <div className="students-leadership__media">
          <picture>
            <source srcSet={StudentsWebp} type="image/webp" />
            <img src={StudentsPng} alt="conversation-between-two-students" />
          </picture>
        </div>
        <p className="hidden--desktop">{t('helpingStudentsContent')}</p>
      </div>

      <LeadershipSkills />

      <AppButton tag="a" linkPath={`/${locale}/about`}>
        {t('ourVision')}
      </AppButton>
    </section>
  );
}
