import MainTitle from '../common/MainTitle';
import ImageLink from '../../js/utils/ImageLink';

export default function AccordionItems({
  type = 'dark',
  mainTitle,
  title1,
  title2,
  startText,
  startImage,
  centerImage,
  endText,
  endImage,
  index,
  toggleAccordionItems,
  accordionIndexOpen
}) {
  return (
    <div className={`accordion-item ${type === 'dark' ? 'dark' : 'light'}`}>
      <button
        type="button"
        className="button accordion-item__title-container"
        onClick={() => toggleAccordionItems(index)}>
        <p>{mainTitle}</p>
        <span>{`0${index + 1}.`}</span>
      </button>
      <div
        className="accordion-item__content-container"
        aria-expanded={accordionIndexOpen === index}>
        <MainTitle type={type === 'dark' ? 'light' : 'dark'} tag="h2" className="text--2xl">
          <span>{title1}</span>
          <span
            className={`decorated-line 
              ${type === 'dark' ? 'decorated-line--light ' : 'decorated-line--dark '}
              decorated-line--left`}>
            {title2}
          </span>
        </MainTitle>

        <div className="content-wrapper">
          <div className="content-image content-image--left">
            <p>{startText}</p>
            <div className="media media--start">
              <img src={ImageLink(startImage.url)} alt={startImage.alt} />
            </div>
          </div>

          <div className="media media--main">
            <img className="large-image" src={ImageLink(centerImage.url)} alt={centerImage.alt} />
          </div>

          <div className="content-image content-image--right reverse">
            <p>{endText}</p>
            <div className="media media--end">
              <img src={ImageLink(endImage.url)} alt={endImage.alt} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
