import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import ImageLink from '../../js/utils/ImageLink';
import { formatDate } from '../../js/utils/format-date';
import LocaleContext from '../../contexts/LocaleContext';
/**
 *
 * @param {{image: image, title: string, date: string, author: string, url: string}} props
 * @returns
 */

export default function ArticleCard({ image, title, date, author, url }) {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['HomePage']);

  return (
    <div className="article-card">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className="article-card__media">
          <img src={ImageLink(image.url)} alt={image.alt} />
        </div>

        <p className="article-card__date">
          {formatDate(date, locale === 'en' ? 'en-US' : 'ar-EG')}
        </p>
        <p className="article-card__title">{title}</p>
        <p className="article-card__author">
          {t('by')} {author}
        </p>
      </a>
    </div>
  );
}
