import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import LocaleContext from '../contexts/LocaleContext';
import { getCampusPage } from '../js/api/campus-page';
import CampusData from '../models/CampusPageData';
import { getExploreTheCampus } from '../js/api/explore-the-campus';
import ExploreTheCampusData from '../models/ExploreTheCampusData';

import MetaTags from '../components/common/MetaTags';
import HeroSection from '../components/CampusAndStudentLife/HeroSection';
import AboutElGouna from '../components/CampusAndStudentLife/AboutElGouna';
import CampusAndCommunitySlider from '../components/CampusAndStudentLife/CampusAndCommunity/CampusAndCommunitySlider';
import ResidentialExperience from '../components/CampusAndStudentLife/ResidentialExperience';
// import ExploreCampusSlider from '../components/CampusAndStudentLife/ExploreCampusSlider';
import StudentLifeAtManara from '../components/CampusAndStudentLife/StudentLifeAtManara';
import CoCurricularPrograms from '../components/CampusAndStudentLife/CoCurricularPrograms';
import HousingAndAccommodation from '../components/CampusAndStudentLife/HousingAndAccommodation/HousingAndAccommodation';
import Accordion from '../components/Accordion/Accordion';
import ApplicationDetails from '../components/ApplicationDetails';
import scrollToTarget from '../js/utils/scroll';

// import StudentDormitories from '../components/CampusAndStudentLife/StudentDormitories';

export default function CampusAndStudentLife() {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['CampusPage']);
  const location = useLocation();

  const [campusPageData, setCampusPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [exploreTheCampusData, setExploreTheCampus] = useState(null);

  // Create Modal container
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', 'app-modal');

  useEffect(() => {
    setIsLoading(true);
    getCampusPage()
      .then((res) => {
        setCampusPageData(new CampusData(res.data));

        // Bind Modal container into DOM
        document.getElementById('app').appendChild(wrapperElement);
      })
      .catch((e) => {
        toast.error('An error occurred, try again later');
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [locale]);

  useEffect(() => {
    setIsLoading(true);
    getExploreTheCampus()
      .then((res) => {
        setExploreTheCampus(new ExploreTheCampusData(res.data));
      })
      .catch((e) => {
        toast.error('An error occurred, try again later');
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [locale]);

  useEffect(() => {
    if (location?.hash === '#co-curricular-programs') {
      const element = document?.getElementById('co-curricular-programs');
      scrollToTarget(element);
    }
  }, [campusPageData]);

  return (
    <main className="campus-page">
      {!isLoading && campusPageData && exploreTheCampusData ? (
        <>
          <MetaTags title={t('pageTitle')} seo={campusPageData?.metaTags} />
          <HeroSection />
          <AboutElGouna />
          <CampusAndCommunitySlider slides={campusPageData?.campusAndCommunity} />
          <ResidentialExperience data={campusPageData?.residentialExperience} />

          {/* TODO: Hide it as per client request. Enable it later. */}
          {/* <ExploreCampusSlider imagesData={exploreTheCampusData?.exploreTheCampus} /> */}
          <StudentLifeAtManara />
          <Accordion
            data={campusPageData?.amenitySlide}
            type="light"
            className="student-wellbeing-accordion"
          />
          <CoCurricularPrograms programs={campusPageData?.coCurricularPrograms} />
          <HousingAndAccommodation slides={campusPageData?.housingAndAccommodation} />
          <ApplicationDetails />

          {/* TODO: Hide it as per client request. Enable it later. */}
          {/* <StudentDormitories images={campusPageData?.studentDormitories} /> */}
        </>
      ) : null}
    </main>
  );
}
