import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function MetaTags({ title, seo }) {
  const { t } = useTranslation(['HomePage']);

  const metaTagsJSX = seo?.map(({ id, name, content }) => (
    <meta key={`meta-${id}-${name}`} name={name} content={content} />
  ));

  return (
    <Helmet>
      <title>{title ? `${t('manaraSchool')} | ${title}` : `${t('manaraSchool')}`}</title>
      {metaTagsJSX}
    </Helmet>
  );
}
