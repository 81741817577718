import ReactDOM from 'react-dom';
import MainTitle from './MainTitle';
import ImageLink from '../../js/utils/ImageLink';
import Icon from './Icon';

export default function BioPopUp({ data, closeModal }) {
  const fullName = data.name.split(' ');
  const firstName = fullName.splice(0, 1);
  const lastName = fullName.join(' ');

  return ReactDOM.createPortal(
    <div className="bio-popup">
      <button
        type="button"
        className="button close-button"
        title="close popup"
        onClick={(e) => closeModal(e)}>
        <Icon name="close" />
      </button>
      <Icon name="spiral-arrow" className="spiral-arrow-icon" />
      <div className="bio-popup__container">
        <div className="bio-popup__header mobile">
          <MainTitle type="dark" className="text--2xl">
            <span>{firstName}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {lastName}
            </span>
          </MainTitle>
          <p>{data.jobTitle}</p>
        </div>

        <div className="bio-popup__media">
          <img src={ImageLink(data.image.url)} alt={data.image.alt} />
        </div>

        <div className="bio-popup__content">
          <div className="bio-popup__header desktop">
            <MainTitle type="dark" className="text--2xl">
              <span>{firstName}</span>
              <span className="decorated-line decorated-line--dark decorated-line--left">
                {lastName}
              </span>
            </MainTitle>
            <p>{data.jobTitle}</p>
          </div>
          <p>{data.bio}</p>
        </div>
      </div>
    </div>,
    document.getElementById('bio-modal')
  );
}
