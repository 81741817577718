import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ArticleGrid from '../components/Articles/ArticleGrid';
import PageHeroSection from '../components/common/PageHeroSection';
import MaximizingLeadership from '../components/Home/MaximizingLeadership';

import HeroImageStart from '../assets/images/about/About Us - Leaders.jpg';
import HeroImageStartWebp from '../assets/images/about/About-Us-Leaders.webp';
import HeroImageEnd from '../assets/images/about/shaping-leaders.jpeg';
import HeroImageEndWebp from '../assets/images/about/shaping-leaders.webp';
import Story from '../components/About/Story';
import LeadershipPotential from '../components/About/LeadershipPotential';
import Inclusivity from '../components/About/Inclusivity';
import BoardOfTrustees from '../components/About/BoardOfTrustees/BoardOfTrustees';
import ApplicationDetails from '../components/ApplicationDetails';
import Team from '../components/About/Team';
import Tabs from '../components/common/Tabs';
import MetaTags from '../components/common/MetaTags';

import LocaleContext from '../contexts/LocaleContext';
import { getAboutPage } from '../js/api/about-page';
import AboutData from '../models/AboutPageData';
import { getMaximizingLeadership } from '../js/api/maximizing-leadership';
import MaximizingLeadershipData from '../models/MaximizingLeadershipData';

export default function AboutPage() {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['AboutPage']);
  const [aboutPageData, setAboutPageData] = useState(null);
  const [maximizingLeadershipData, setMaximizingLeadershipData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Create Modal container
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', 'bio-modal');

  useEffect(() => {
    setIsLoading(true);
    getAboutPage()
      .then((res) => {
        setAboutPageData(new AboutData(res.data));

        // Bind Modal container into DOM
        document.getElementById('app').appendChild(wrapperElement);
      })
      .catch((e) => {
        toast.error('An error occurred, try again later');
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [locale]);

  useEffect(() => {
    setIsLoading(true);
    getMaximizingLeadership()
      .then((res) => {
        setMaximizingLeadershipData(new MaximizingLeadershipData(res.data));
      })
      .catch((e) => {
        toast.error('An error occurred, try again later');
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [locale]);

  return (
    <main id="about-page">
      {!isLoading && aboutPageData ? (
        <>
          <MetaTags title={t('pageTitle')} seo={aboutPageData?.metaTags} />
          <PageHeroSection
            startImage={HeroImageStart}
            startImageWebp={HeroImageStartWebp}
            startImageAlt="Student feeding baby goat"
            endImage={HeroImageEnd}
            endImageWebp={HeroImageEndWebp}
            endImageAlt="Student holding a notebook"
            title1={t('heroTitle1')}
            title2={t('heroTitle2')}
            title3={t('heroTitle3')}
          />
          <Story {...aboutPageData.story} />
          <MaximizingLeadership data={maximizingLeadershipData?.maximizingLeadership} />
          <LeadershipPotential />
          <Inclusivity />
          <Tabs data={aboutPageData.campusLife} title1={t('campusAnd')} title2={t('studentLife')} />
          <BoardOfTrustees slides={aboutPageData.boardOfTrustees} />
          <Team data={aboutPageData.team} />
          <ArticleGrid />
          <ApplicationDetails />
        </>
      ) : null}
    </main>
  );
}
