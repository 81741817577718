import ImageLink from '../../js/utils/ImageLink';

export default function Slide({ index, image, text }) {
  return (
    <div className="college-prep__slide" key={index}>
      <div className="slide__content">
        <p>{text}</p>
      </div>

      <div className="slide__media">
        <picture>
          {/* <source srcSet={image} type="image/webp" /> */}
          <img src={ImageLink(image.url)} alt={image.alt} />
        </picture>
        <div className="backdrop" />
      </div>
    </div>
  );
}
