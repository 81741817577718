/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import MaximizingLeadership from '../components/Home/MaximizingLeadership';
import StudentsLeadershipPotential from '../components/Home/StudentsLeadershipPotential';
import Accordion from '../components/Accordion/Accordion';
import CollegePrepSlider from '../components/CollegePrepSlider/CollegePrepSlider';
import ScholarshipProgram from '../components/Home/ScholarshipProgram';
import HeroImage from '../components/Home/HeroImage';
import ApplicationDetails from '../components/ApplicationDetails';
import MetaTags from '../components/common/MetaTags';
import { getHomePage } from '../js/api/homepage';
import HomeData from '../models/HomePageData';
import LocaleContext from '../contexts/LocaleContext';
import ContactUsForm from '../components/ContactUs/ContactUsForm';

export default function LandingPage() {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['HomePage']);
  const [homePageData, setHomePageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Handle fetching homepage data.
  useEffect(() => {
    setIsLoading(true);
    getHomePage()
      .then((res) => {
        setHomePageData(new HomeData(res.data));
      })
      .catch((e) => {
        toast.error('An error occurred, try again later');
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [locale]);

  return (
    <main className="landing">
      {!isLoading && homePageData ? (
        <>
          <MetaTags title={t('pageTitle')} seo={homePageData?.metaTags} />
          <HeroImage {...homePageData?.heroSection} />
          <MaximizingLeadership data={homePageData?.maximizingLeadership} />
          <StudentsLeadershipPotential />
          <CollegePrepSlider slides={homePageData?.prepSliderItems} />
          <div className="manara-education container">
            <p className="manara-education__content">
              {t('manaraEducationPart1')}
              <span>{t('manaraEducationPart2')}</span>
            </p>
          </div>
          <Accordion data={homePageData?.accordionData} />
          <ScholarshipProgram />
          <ApplicationDetails />
          <ContactUsForm />
        </>
      ) : null}
    </main>
  );
}
