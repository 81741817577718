import { useState } from 'react';
import MainTitle from './MainTitle';
import ImageLink from '../../js/utils/ImageLink';
import Icon from './Icon';

/**
 *
 * @param {{
 *  title1: string,
 *  title2: string,
 *  data: array,
 *  addLink: boolean,
 *  className: string}} props
 * @returns
 */

export default function Tabs({ data, title1, title2, addButton = true, className = '' }) {
  const [ToggleStateIndex, setToggleStateIndex] = useState(0);
  const toggleTab = (index) => {
    setToggleStateIndex(index);
  };
  const getActiveClass = (index, activeClassName) =>
    ToggleStateIndex === index ? activeClassName : '';

  const tabList = () =>
    data.map(({ title, id }, index) => (
      <li
        key={`${title}-${id}`}
        className={`tab-item ${getActiveClass(index, 'active-tabs')}`}
        onClick={() => toggleTab(index)}
        role="presentation">
        {title}
      </li>
    ));
  return (
    <section className={`tabs-container ${className}`}>
      {data.map(({ id, text, image, url, linkText }, index) => (
        <div
          key={`tabs-container-${id}`}
          className={`tabs-container__tab-container ${getActiveClass(index, 'active-content')}`}>
          <div className="tabs-container__tab-container-header mobile">
            <MainTitle type="dark" className="text--2xl">
              <span>{title1}</span>
              <span className="decorated-line decorated-line--dark decorated-line--left">
                {title2}
              </span>
            </MainTitle>
          </div>
          <ul className="mobile-tabs-container mobile">{tabList()}</ul>

          <div className="tabs-container__tab-media">
            <img src={ImageLink(image.url)} alt={image.alt} />
          </div>

          <div className="text-content">
            <div className="tabs-container__header desktop">
              <MainTitle type="dark" className="text--2xl">
                <span>{title1}</span>
                <span className="decorated-line decorated-line--dark decorated-line--left">
                  {title2}
                </span>
              </MainTitle>
            </div>
            <ul className="tabs-container__tabs desktop">{tabList()}</ul>

            <div className="tabs-container__tab-content">
              <p>{text}</p>

              {addButton && (
                <a className="button app-button app-button--dark" href={url}>
                  <span />
                  <span>{linkText}</span>
                </a>
              )}
              <Icon name="spiral-arrow-down" className="spiral-arrow-icon" />
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}
