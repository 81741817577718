{
  "pageTitle": "عمليات القبول",
  "readyToJoin": "هل أنت مستعد للانضمام",
  "futureLeadership": "إلى صفوف قيادة المستقبل",
  "startYourApplication": "ابدأ طلبك",
  "application": "الجدول الزمني",
  "timeline": "للتقديم",
  "applicationOpens": "فتح باب القبول",
  "applicationDeadline": "الموعد النهائي للتقديم",
  "assessmentWeek": "أسبوع التقييم",
  "registrationDeadline": "آخر موعد للتسجيل",
  "firstDayOfSchool": "أول يوم دراسي",
  "applyNowIn": "قدم طلبك الآن في",
  "fewSteps": "خطوات قليلة",
  "applicationStepsNote": "يرجى ملاحظة أنه لا يمكننا ضمان الأماكن المتاحة بعد الجولة الأولى. سيتم إخبارك إذا كانت الأماكن المتاحة ممتلئة.",
  "deadline": "الموعد النهائي",
  "decisionDate": "اتخاذ القرار",
  "download": "تنزيل",
  "annual": "الرسوم",
  "studentFees": "السنوية للطلاب",
  "annualFeesDescription": "الرسوم السنوية لكل طالب هي رسوم شاملة تغطي جميع نفقات الطلاب خلال العام الدراسي ، بما في ذلك التكاليف الأكاديمية والسكنية.",
  "feesInclude": "الرسوم السنوية تشمل",
  "feesExclude": "الرسوم السنوية لا تشمل",
  "needSupport": "بحاجة الى دعم",
  "haveQuestion": "أو لديك أي أسئلة",
  "supportDescription": "نحن هنا لمساعدتك!، اتصل بنا إذا كانت لديك أي استفسارات أو للحصول على مزيد من المعلومات.",
  "contactUs": "اتصل بنا",
  "payment": "تعليمات",
  "instructions": "الدفع",
  "benificiaryName": "اسم المستفيد",
  "accountNumber": "رقم حساب المستفيد",
  "cib": "البنك التجاري الدولي",
  "manaraSchool": "مدرسة منارة",
  "payOnline": "كما يمكنك أيضًا الدفع عبر الإنترنت",
  "makeOnline": "أدفع عبر الإنترنت",
  "banksInfoError": "فشل في الحصول على معلومات البنك"
}
