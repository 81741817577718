import { ENDPOINTS } from './endpoints';
import { fetchData } from './network';

export function submitContactRequests(contactData) {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.submitContactRequests.path}`);

  return fetchData(url, {
    method: ENDPOINTS.submitContactRequests.method,
    body: JSON.stringify({ data: contactData })
  }).then((response) => response.data);
}
