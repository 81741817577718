import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import NavItem from './NavItem';
import LocaleContext from '../../contexts/LocaleContext';
import { getHeader } from '../../js/api/navigation';

/**
 *
 * @param {{display: 'row' | 'column'}} props
 * @returns
 */
export default function Navbar({ display = 'row' }) {
  const { locale } = useContext(LocaleContext);
  const { t, i18n } = useTranslation(['HomePage']);
  const [navItems, setNavItems] = useState();
  const location = useLocation();

  /**
   * Resolve route redirection based on the choose locale.
   */
  function resolveRoute(route) {
    const currentRoute = route.pathname.split('/').pop();

    // If the current location is homepage En version
    // then redirect to Ar version and vice versa
    if (currentRoute === 'en') {
      return '/ar';
    }

    if (currentRoute === 'ar') {
      return '/en';
    }

    return locale === 'en' ? `/ar/${currentRoute}` : `/en/${currentRoute}`;
  }

  const changeLanguage = (lng) => {
    if (locale !== lng) {
      i18n.changeLanguage(lng);
      localStorage.setItem('locale', lng);
    }
  };

  /**
   * Handle fetching navigation data.
   */
  useEffect(() => {
    getHeader()
      .then((res) => {
        setNavItems(res.sort((a, b) => a.order - b.order));
      })
      .catch((e) => {
        toast.error(t('getHeaderError'));
        throw e;
      });
  }, [locale]);

  const navbarLinksJSX = navItems?.map((item) => (
    <NavItem
      key={`nav-item-${item.id}`}
      url={item.external ? item.path : `${locale}${item.path}`}
      isExternal={item.external}
      title={item.title}>
      {item.title}
    </NavItem>
  ));

  return (
    <nav className={`navbar navbar--${display === 'row' ? 'desktop' : 'mobile'}`}>
      <ul className={`navbar__list navbar__list--${display}`}>
        {window.location.pathname !== `/${locale}/admission-campaign` && navbarLinksJSX}

        {display === 'row' ? (
          <li className="navbar__item change-lang change-lang--desktop">
            {locale === 'en' ? (
              <Link to={resolveRoute(location)} title="عربي" onClick={() => changeLanguage('ar')}>
                عربي
              </Link>
            ) : (
              <Link
                to={resolveRoute(location)}
                title="english"
                onClick={() => changeLanguage('en')}>
                En
              </Link>
            )}
          </li>
        ) : (
          <li className="navbar__item change-lang change-lang--mobile">
            <span>{t('changeLang')}</span>
            {locale === 'en' ? (
              <Link to={resolveRoute(location)} title="عربي" onClick={() => changeLanguage('ar')}>
                <span>عربي</span>
              </Link>
            ) : (
              <Link
                to={resolveRoute(location)}
                title="english"
                onClick={() => changeLanguage('en')}>
                <span>En</span>
              </Link>
            )}
          </li>
        )}
      </ul>
    </nav>
  );
}
