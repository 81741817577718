import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AppButton from '../common/AppButton';
import MainTitle from '../common/MainTitle';
import LocaleContext from '../../contexts/LocaleContext';

import GirlHoldingGoat from '../../assets/images/about/maximizing-leadership-potential-right.jpeg';
import GirlHoldingGoatWebp from '../../assets/images/about/maximizing-leadership-potential-right.webp';
import BoyPickingDates from '../../assets/images/about/maximizing-leadership-potential-left.jpg';
import BoyPickingDatesWebp from '../../assets/images/about/maximizing-leadership-potential-left.webp';

export default function LeadershipPotential() {
  const { t } = useTranslation(['AboutPage']);
  const { locale } = useContext(LocaleContext);

  return (
    <section className="leadership container">
      <div className="leadership__header mobile">
        <MainTitle type="dark" className="text--2xl">
          <span>{t('leadershipTitle1')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('leadershipTitle2')}
          </span>
          <span>{t('leadershipTitle3')}</span>
        </MainTitle>
      </div>
      <div className="leadership__media">
        <picture>
          <source srcSet={BoyPickingDatesWebp} type="image/webp" />
          <img src={BoyPickingDates} alt="boy-picking-dates" className="left-img" />
        </picture>
        <picture>
          <source srcSet={GirlHoldingGoatWebp} type="image/webp" />
          <img src={GirlHoldingGoat} alt="girl-holding-goat" className="right-img" />
        </picture>
      </div>

      <div className="leadership__content">
        <div className="leadership__header desktop">
          <MainTitle type="dark" className="text--2xl">
            <span>{t('leadershipTitle1')}</span>
            <span className="decorated-line decorated-line--dark decorated-line--left">
              {t('leadershipTitle2')}
            </span>
            <span>{t('leadershipTitle3')}</span>
          </MainTitle>
        </div>
        <p>{t('leadershipText1')}</p>
        <p>{t('leadershipText2')}</p>

        <AppButton className="academic-programs" tag="a" linkPath={`/${locale}/academics`}>
          {t('leadershipButton')}
        </AppButton>
      </div>
    </section>
  );
}
