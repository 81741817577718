import ReactDOM from 'react-dom';
import Icon from './Icon';

export default function AppModal({ children, close, className }) {
  return ReactDOM.createPortal(
    <div className={`app-modal ${className}`}>
      <button
        type="button"
        className="button close-button"
        title="close popup"
        onClick={(e) => close(e)}>
        <Icon name="close" />
      </button>

      <div className="app-modal__container">{children}</div>

      <Icon name="spiral-arrow" className="spiral-arrow-icon" />
    </div>,
    document.getElementById('app-modal')
  );
}
