import ImageData from '../../models/Image';

/**
 * Extracts the images needed data.
 *
 * @param {{data: [{id: string, attributes: {}}]}} images
 * @returns [ImageData] Representing the URLs of the passed images.
 */
export function getImagesData(images) {
  return images?.data?.map((image) => new ImageData(image));
}
